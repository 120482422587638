import { FC, useRef } from 'react';
import Button from '@/components/button';
import Menu from '@/components/menu';
import { DtFilter } from '../helpers';
import ListEditor, { ListItem } from '@/components/form/list-editor';
import { Badge, Box } from '@mui/material';
import AddItem, { AddItemInstance } from './add-item';
import ViewItem from './view-item';

interface Props {
  filter: DtFilter;
  onChange: (item: ListItem, editIndex: number) => void;
  onRemove: (item: ListItem, index: number) => void;
}

const FilterMenu: FC<Props> = ({ filter, onChange, onRemove }) => {
  const { fields, values } = filter;

  let TriggerEl = <Button size="small" label="Filters" />;
  if (values.length) {
    TriggerEl = (
      <Badge
        badgeContent={values.length}
        color="warning"
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        {TriggerEl}
      </Badge>
    );
  }

  const addItemRef = useRef<AddItemInstance>(null);

  const items: ListItem[] = values.map(({ columnName, value }) => {
    const field = fields.find(({ columnName: fColumnName }) => fColumnName === columnName);
    return {
      label: field?.label || columnName,
      value: value as string,
      meta: field,
    };
  });

  const handleChange = (item: ListItem, editIndex: number) => {
    onChange(item, editIndex);
    addItemRef.current?.reset();
  };

  const handleInput = (onChange: (item: ListItem) => void, item: ListItem, editIndex: number) => (
    <AddItem
      filter={filter}
      onChange={onChange}
      item={item}
      editIndex={editIndex}
      ref={addItemRef}
    />
  );
  const handleItem = (item: ListItem) => <ViewItem item={item} />;

  return (
    <>
      <Menu title="Apply Filters" menuId="filters" trigger={TriggerEl}>
        <Box width={600}>
          <ListEditor
            name="filters"
            onChange={handleChange}
            onRemove={onRemove}
            items={items}
            onInput={handleInput}
            onItemView={handleItem}
          />
        </Box>
      </Menu>
    </>
  );
};

export default FilterMenu;
