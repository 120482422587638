import Card from '@/components/card';
import CardHeader from '@/components/card/card-header';
import Icon from '@/components/icon';
import Tooltip from '@/components/tooltip';
import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  title: string;
  completed?: boolean;
  children: ReactNode;
}

const ConnectorStepCard: FC<Props> = ({ title, completed = false, children }) => {
  return (
    <Card>
      <CardHeader title={title}>
        {completed && (
          <Tooltip title="This step is complete">
            <Icon name="check-circle" color="success" />
          </Tooltip>
        )}
      </CardHeader>
      <Box mt={2}>{children}</Box>
    </Card>
  );
};

export default ConnectorStepCard;
