import { FC } from 'react';
import Chip, { ChipColor } from './';
import { RiskLevel } from '@/lib/models/user-event';

interface Props {
  value: RiskLevel;
  size?: 'x-small' | 'small' | 'medium';
}

const ColorMap: Map<RiskLevel, ChipColor> = new Map();
ColorMap.set('low', 'success');
ColorMap.set('medium', 'warning');
ColorMap.set('high', 'error');
ColorMap.set('critical', 'critical');
ColorMap.set('public', 'success');
ColorMap.set('internal', 'success');
ColorMap.set('unknown', 'grey');
ColorMap.set('confidential', 'error');

const RiskChip: FC<Props> = ({ value, size = 'medium' }) => {
  const color = ColorMap.get(value) || 'grey';
  let label = String(value).toLowerCase();

  if (['public', 'internal'].includes(value)) {
    label = 'not sensitive';
  }

  return <Chip label={String(label).toUpperCase()} color={color} size={size} />;
};

export default RiskChip;
