import { FC } from 'react';
import InputControl from '@/components/form/input-control';

import { Box, Chip, Grid, Stack } from '@mui/material';
import FormSection from '@/components/form/form-section';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { useQuery } from 'react-query';
import useQueryHelper from '@/hooks/use-query-helper';
import { OrgModel } from '@/lib/models/org.model';
import { getOrg } from '@/lib/services/org.service';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import StatusChip from '@/components/chip/status-chip';
import Text from '@/components/text';

const fields = ['name', 'domains'];
const formModel = new OrgModel();

const ProfileForm: FC = () => {
  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: () => {
      console.error('no writeable fields in this form');
    },
  });

  const query = useQuery([QueryKey.OrgView], async () => getOrg());
  const { showLoader } = useQueryHelper(query);
  const { data: org } = query;

  if (showLoader) {
    return null;
  }

  const disablePromptRetention = !!org?.disablePromptRetention;

  return (
    <Box>
      <Box mb={4}>
        <Form formHandle={formHandle} query={query}>
          <FormSection title="Profile Settings">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputControl
                  name="name"
                  label="Organization Name"
                  formHandle={formHandle}
                  readonly
                />
              </Grid>
              <Grid item xs={6}>
                <InputControl
                  name="modeLabel"
                  label="Organization Mode"
                  formHandle={formHandle}
                  readonly
                />
              </Grid>

              <Grid item xs={6}>
                <Box mb={1}>
                  <Text bold>Domains:</Text>
                </Box>
                <Stack direction="row" gap={0.5}>
                  {org?.domains.map((domain) => <Chip key={domain} label={domain} />)}
                </Stack>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </FormSection>

          {disablePromptRetention && (
            <FormSection title="Prompt Retention">
              <Box>
                Prompt Retention determines whether user-generated GenAI prompts and model-generated
                responses are stored for review. When set to DISABLED, only request and response
                metadata will be stored.
              </Box>
              <Stack direction="row" gap={2} mt={3}>
                <Box>Prompt Retention:</Box>
                <Box>
                  <StatusChip value="disabled" size="small" />
                </Box>
              </Stack>
            </FormSection>
          )}
        </Form>
      </Box>
    </Box>
  );
};

export default ProfileForm;
