import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC, ReactNode } from 'react';
import Button from '../../button';
import useModal from '../../../hooks/use-modal.hook';
import Modal from '..';

interface Props {
  title?: string;
  content: ReactNode;
  onClose: (confirm?: boolean) => void;
}

export const ConfirmModal: FC<Props> = ({ title = 'Confirm', content, onClose }) => {
  const { closeModal } = useModal();

  const handleClose = (confirm?: boolean) => {
    onClose(confirm);
    closeModal();
  };

  return (
    <Modal>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)} type="cancel" label="Yes" />
        <Button label="No, go back" onClick={() => handleClose(false)} />
      </DialogActions>
    </Modal>
  );
};

export default ConfirmModal;
