import AgentUsageCard from '@/components/agent-usage-card';
import NoneChip from '@/components/chip/none-chip';
import StatusChip, { ChipStatus } from '@/components/chip/status-chip';
import ReadonlyView from '@/components/form/readonly-view';
import { ReadonlyField } from '@/components/form/readonly-view/helpers';
import PolicyUsageCard from '@/components/policy-usage-card';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { PrivateModelModel } from '@/lib/models/private-model.model';
import { ModelUsage } from '@/lib/services/private-model.service';
import { FC, ReactNode, useMemo } from 'react';

interface Props {
  model: PrivateModelModel | null;
  connector?: ConnectorModel;
  usage?: ModelUsage;
}

const PrivateModelReadonlyView: FC<Props> = ({ model, connector, usage }) => {
  const [data, fields] = useMemo(() => {
    if (!model) {
      return [{}, []];
    }

    const { name, modelId } = model;

    const hasConnector = !!connector;
    const hasUsage = !!usage?.inUse;

    let modelStatus: ChipStatus = 'inactive';
    if (!hasConnector) {
      modelStatus = 'error';
    } else if (hasUsage) {
      modelStatus = 'active';
    }

    const fields: ReadonlyField[] = [
      { label: 'Name', prop: 'name' },
      { label: 'Model ID', prop: 'modelId' },
      { label: 'Connector', prop: 'connectorLabel' },
      { label: 'Status', prop: 'status' },
    ];

    const data: Record<string, ReactNode> = {
      name,
      modelId,
      connectorLabel: connector?.name || <NoneChip />,
      status: <StatusChip value={modelStatus} size="small" />,
    };

    if (hasUsage) {
      fields.push({ label: 'Usage', prop: 'usage-section', propType: 'section' });

      const { policies, agents } = usage;

      if (policies.length) {
        fields.push({ label: '', prop: 'policies' });
        data.policies = <PolicyUsageCard policies={policies} />;
      }

      if (agents.length) {
        fields.push({ label: '', prop: 'agents' });
        data.agents = <AgentUsageCard agents={agents} />;
      }
    }

    return [data, fields];
  }, [model, connector, usage]);

  if (!model) {
    return null;
  }

  return <ReadonlyView fields={fields} data={data} />;
};

export default PrivateModelReadonlyView;
