import { FC } from 'react';
import Icon from '../icon';
import { ConnectorType, ConnectorMap } from '@/lib/models/connector';
import { Box } from '@mui/material';

interface Props {
  connectorType: ConnectorType;
}

const ConnectorIcon: FC<Props> = ({ connectorType }) => {
  const { iconSVG: IconEl, icon } = ConnectorMap.get(connectorType) || {};

  if (IconEl) {
    return <img src={IconEl} alt={connectorType} width={30} height={25} />;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width={30} height={25}>
      <Icon name={icon || 'plug'} />
    </Box>
  );
};

export default ConnectorIcon;
