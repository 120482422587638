import { Box } from '@mui/material';
import { FC } from 'react';
import InlineToast from '../toasts/inline';
import useFeature, { FeatureId } from '@/hooks/use-feature';

interface Props {
  featureId: FeatureId;
  can: boolean;
}

const PageFeatureToast: FC<Props> = ({ featureId, can }) => {
  const { getTooltip } = useFeature();

  if (can) {
    return null;
  }

  return (
    <Box mb={4}>
      <InlineToast level="warning" message={getTooltip(featureId)} />
    </Box>
  );
};

export default PageFeatureToast;
