import Card from '@/components/card';
import Icon from '@/components/icon';
import Text from '@/components/text';
import { pluralize } from '@/lib/helpers';
import { Box, Grid, Stack } from '@mui/material';
import { FC } from 'react';
import { ImageExtension } from './helpers';
import FlatButton from '../flat-button';
import useModal from '@/hooks/use-modal.hook';
import { createUseStyles } from 'react-jss';
import NoneChip from '../chip/none-chip';

interface Props {
  image: string;
  imageWidth: number;
  imageHeight: number;
  maxFileSize: number;
  allowedImageTypes?: ImageExtension[];
  title: string;
  readonly?: boolean;
  onRemove?: () => void;
}

const useStyles = createUseStyles<string, { imageWidth: number; imageHeight: number }>({
  imagePreviewCard: {
    position: 'relative',
  },
  deleteButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  imagePreviewContainer: ({ imageWidth, imageHeight }) => ({
    width: imageWidth,
    height: imageHeight,
  }),
  imagePreview: ({ imageWidth, imageHeight }) => ({
    display: 'block',
    maxWidth: imageWidth,
    maxHeight: imageHeight,
    width: 'auto',
    height: 'auto',
  }),
});

const ImageUploadPreviewCard: FC<Props> = ({
  image,
  imageHeight,
  imageWidth,
  maxFileSize,
  allowedImageTypes = ['JPG', 'PNG'],
  title,
  readonly = false,
  onRemove,
}) => {
  const styles = useStyles({ imageWidth, imageHeight });
  const { openModal } = useModal();

  const handleRemove = () => {
    openModal('confirm', {
      title: 'Confirm Delete',
      content: 'Are you sure you want to remove the current image?',
      onClose: (confirm: boolean) => {
        if (confirm) {
          onRemove?.();
        }
      },
    });
  };

  const hasImage = !!image;

  return (
    <Stack gap={6}>
      <Card
        title={
          <Stack direction="row" gap={1}>
            <Icon name="image" />
            <Text>{title}</Text>
          </Stack>
        }
        className={styles.imagePreviewCard}
      >
        <Box>
          <Box mb={2}>
            <Text bold>Image Preview</Text>
          </Box>
          <Box className={styles.imagePreviewContainer}>
            {hasImage && (
              <Box width="100%">
                <img src={image} className={styles.imagePreview} />
              </Box>
            )}
            {!hasImage && <NoneChip notAvailable />}
          </Box>
        </Box>

        <Box mt={4}>
          <Box mb={1}>
            <Text bold>Sizing</Text>
          </Box>
          <Box>
            <Text size="small">
              The image will be constrained to maximum height of {imageHeight}
              px, and a maximum width of {imageWidth}px.
            </Text>
          </Box>
        </Box>

        <Box mt={4}>
          <Box mb={1}>
            <Text bold>Format</Text>
          </Box>
          <Box>
            <Grid container spacing={1} width="70%">
              <Grid item xs={6}>
                <Text>Allowed file {pluralize(allowedImageTypes.length, 'type')}</Text>
              </Grid>
              <Grid item xs={6}>
                {allowedImageTypes.map((s) => String(s).toUpperCase()).join(', ')}
              </Grid>
              <Grid item xs={6}>
                <Text>Maximum file size</Text>
              </Grid>
              <Grid item xs={6}>
                {maxFileSize}kb
              </Grid>
              <Grid item xs={6}>
                <Text>Image width</Text>
              </Grid>
              <Grid item xs={6}>
                {imageWidth}px
              </Grid>

              <Grid item xs={6}>
                <Text>Image height</Text>
              </Grid>
              <Grid item xs={6}>
                {imageHeight}px
              </Grid>
            </Grid>
          </Box>
        </Box>

        {!readonly && (
          <Box className={styles.deleteButton}>
            <FlatButton
              label="Delete"
              icon="delete"
              disabled={!hasImage}
              onClick={handleRemove}
              color="error"
            />
          </Box>
        )}
      </Card>
    </Stack>
  );
};

export default ImageUploadPreviewCard;
