import { ChangeEvent, FC, MouseEvent } from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import { createUseStyles } from 'react-jss';
import { FormControlLabel } from '@mui/material';
import FormControl from '../form-control';
import { FormProps } from '../helpers';

const useStyles = createUseStyles({
  checkbox: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& svg.MuiSvgIcon-root': {
      opacity: 0.8,
    },
  },
});

interface Props extends FormProps {
  checked: boolean;
  onChange: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  labelChecks?: boolean;
}

const Checkbox: FC<Props> = ({
  name,
  checked,
  label = '',
  disabled = false,
  autoFocus = false,
  onChange,
  error = '',
  fullWidth = true,
  labelChecks = true,
}) => {
  const styles = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.checked, event);

  const InputEl = (
    <MuiCheckbox
      name={name}
      checked={checked}
      onChange={handleChange}
      color="primary"
      className={styles.checkbox}
      disabled={disabled}
      autoFocus={autoFocus}
      disableRipple
    />
  );

  const handleClickLabel = (event: MouseEvent<HTMLElement>) => {
    // @todo meh
    if (!labelChecks && (event.target as HTMLElement).tagName !== 'INPUT') {
      event.preventDefault();
    }
  };

  return (
    <FormControl label="" error={error} name={name} fullWidth={fullWidth}>
      <FormControlLabel control={InputEl} label={label} onClick={handleClickLabel} />
    </FormControl>
  );
};

export default Checkbox;
