import { FC, ReactNode } from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tooltip: {
    padding: 10,
  },
});

interface Props {
  children: ReactNode;
  title: ReactNode;
  disabled?: boolean;
  className?: string;
  placement?:
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | undefined;
}

const Tooltip: FC<Props> = ({
  children,
  disabled = false,
  title,
  placement = 'top',
  className,
}) => {
  const styles = useStyles();

  if (disabled) {
    return children;
  }

  return (
    <MuiTooltip
      title={title}
      className={className}
      placement={placement}
      slotProps={{ tooltip: { className: styles.tooltip } }}
    >
      <span>{children}</span>
    </MuiTooltip>
  );
};

export default Tooltip;
