import { Paper } from '@mui/material';
import { ForwardedRef, ReactNode, forwardRef } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
  ref?: ForwardedRef<HTMLDivElement>;
}

const Panel = forwardRef<HTMLDivElement, Props>(({ children, className = '' }, ref) => {
  return (
    <Paper elevation={1} className={className} ref={ref}>
      {children}
    </Paper>
  );
});

export default Panel;
