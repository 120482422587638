export type UserEventDataCategory =
  | 'Unknown'
  | 'FinancialData'
  | 'TransactionalData'
  | 'CustomerData'
  | 'EmployeeData'
  | 'IntellectualProperty'
  | 'ProductDevelopmentData'
  | 'ResearchData'
  | 'OperationalData'
  | 'ITInfrastructureData'
  | 'SecurityData'
  | 'LegalAndComplianceData'
  | 'PublicRecords'
  | 'UserGeneratedContent'
  | 'HealthData'
  | 'ProgrammingCodeData'
  | 'EngineeringDesignData'
  | 'ManufacturingData'
  | 'VendorData'
  | 'MarketingData'
  | 'SalesData'
  | 'ProcurementData'
  | 'SupplyChainData'
  | 'FacilitiesData'
  | 'EnvironmentalData'
  | 'SocialMediaData'
  | 'EventData'
  | 'CorporateCommunicationsData'
  | 'StrategicPlanningData'
  | 'RiskAssessmentData'
  | 'AuditData'
  | 'LegalContractsData'
  | 'ComplianceData'
  | 'HealthAndSafetyData'
  | 'CustomerFeedbackData'
  | 'TrainingData'
  | 'MarketData';

const DataCategoryMap: Record<UserEventDataCategory, string> = {
  Unknown: 'Unknown',
  FinancialData: 'Financial Data',
  TransactionalData: 'Transactional Data',
  CustomerData: 'Customer Data',
  EmployeeData: 'Employee Data',
  IntellectualProperty: 'Intellectual Property',
  ProductDevelopmentData: 'Product Development Data',
  ResearchData: 'Research Data',
  OperationalData: 'Operational Data',
  ITInfrastructureData: 'IT Infrastructure Data',
  SecurityData: 'Security Data',
  LegalAndComplianceData: 'Legal And Compliance Data',
  PublicRecords: 'Public Records',
  UserGeneratedContent: 'User Generated Content',
  HealthData: 'Health Data',
  ProgrammingCodeData: 'Programming Code Data',
  EngineeringDesignData: 'Engineering Design Data',
  ManufacturingData: 'Manufacturing Data',
  VendorData: 'Vendor Data',
  MarketingData: 'Marketing Data',
  SalesData: 'Sales Data',
  ProcurementData: 'Procurement Data',
  SupplyChainData: 'Supply Chain Data',
  FacilitiesData: 'Facilities Data',
  EnvironmentalData: 'Environmental Data',
  SocialMediaData: 'Social Media Data',
  EventData: 'Event Data',
  CorporateCommunicationsData: 'Corporate Communications Data',
  StrategicPlanningData: 'Strategic Planning Data',
  RiskAssessmentData: 'Risk Assessment Data',
  AuditData: 'Audit Data',
  LegalContractsData: 'Legal Contracts Data',
  ComplianceData: 'Compliance Data',
  HealthAndSafetyData: 'Health And Safety Data',
  CustomerFeedbackData: 'Customer Feedback Data',
  TrainingData: 'Training Data',
  MarketData: 'Market Data',
};

export const getDataCategoryLabel = (category: UserEventDataCategory): string =>
  DataCategoryMap[category];
