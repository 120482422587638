import { AgentModel } from '@/lib/models/agent.model';
import { ContextProviderMeta } from '@/lib/models/context-provider';
import { PolicyModel } from '@/lib/models/policy.model';
import { PrivateModelModel } from '@/lib/models/private-model.model';
import { Stack } from '@mui/material';
import { FC } from 'react';
import PolicyCard from '../policy-card';
import { uniqBy } from 'lodash';
import { useQuery } from 'react-query';
import { QueryKey } from '@/lib/query-client';
import { getPolicyMeta, PolicyMetaResult } from '@/lib/services/policy.service';
import { BaseModel } from '@/lib/models/base.model';

interface Props {
  policies: PolicyModel[];
  loadMeta?: boolean;
}

const getFromMap = <T extends Record<string, string> | BaseModel>(
  keys: string[],
  valMap: Map<string, T>
) => {
  const models: T[] = [];
  keys.forEach((key) => {
    if (valMap.has(key)) {
      models.push(valMap.get(key)!);
    }
  });

  return uniqBy(models, 'id');
};

const PolicyCardList: FC<Props> = ({ policies, loadMeta = true }) => {
  const policyIds = policies.map(({ id }) => id);
  const hasPolicies = !!policyIds.length;
  const query = useQuery<PolicyMetaResult>(
    [QueryKey.PolicyMetaList, policyIds],
    async () => {
      return getPolicyMeta(policies);
    },
    { enabled: loadMeta && hasPolicies }
  );

  const { data } = query;

  const [contextProviders, privateModels, agents] = data || [new Map(), new Map(), new Map()];

  return (
    <Stack gap={2}>
      {policies.map((policy) => {
        const { id, contextDataSources, portal, assistants } = policy;
        const contextIds = contextDataSources.enabled || [];
        const policyModelIds = portal?.availablePrivateModelIds || [];
        const policyAgentIds = assistants?.enabled || [];

        const policyContexts = contextProviders
          ? getFromMap<ContextProviderMeta>(
              contextIds,
              contextProviders as Map<string, ContextProviderMeta>
            )
          : [];

        const policyPrivateModels = contextProviders
          ? getFromMap<PrivateModelModel>(
              policyModelIds,
              privateModels as Map<string, PrivateModelModel>
            )
          : [];

        const policyAgents = agents
          ? getFromMap<AgentModel>(policyAgentIds, agents as Map<string, AgentModel>)
          : [];

        return (
          <PolicyCard
            key={id}
            policy={policy}
            contextProviders={policyContexts}
            privateModels={policyPrivateModels}
            agents={policyAgents}
          />
        );
      })}
    </Stack>
  );
};

export default PolicyCardList;
