import { JsonObject } from '../helpers';
import { BaseModel } from './base.model';
import { boolean, object, string, array } from 'yup';

export type IdentProviderType = 'OIDC' | 'SAML' | 'magicLink';

export class IdentConfigOIDCModel extends BaseModel {
  flowType: 'codeFlow';
  clientId: string;
  issuerURL: string;
  authCallbackURIs: string[];
  schema = object({
    name: string().required(),
    clientId: string().required(),
    issuerURL: string().required(),
    authCallbackURIs: array().of(string().required()),
  });
  constructor(props: JsonObject = {}) {
    super(props);
    const data = props as Record<keyof IdentConfigOIDCModel, unknown>;
    this.flowType = 'codeFlow';
    this.clientId = (data.clientId as string) || '';
    this.issuerURL = (data.issuerURL as string) || '';
    this.authCallbackURIs = (data.authCallbackURIs as string[]) || [];
  }
}

export class IdentProviderModel extends BaseModel {
  id: string;
  type: IdentProviderType;
  name: string;
  enabled: boolean;
  lastUpdated: Date | null;
  identityProviderConfig?: IdentConfigOIDCModel;
  schema = object({
    name: string().required('Name is required'),
    type: string().required('Type is required'),
    enabled: boolean().required('Enabled is required'),
  });
  constructor(props: JsonObject = {}) {
    super(props);
    const data = props as Record<keyof IdentProviderModel, unknown>;
    this.id = (data.id as string) || '';
    this.type = (data.type as IdentProviderType) || 'OIDC';
    this.name = (data.name as string) || '';
    this.enabled = Boolean(data.enabled);
    this.lastUpdated = (data.lastUpdated as Date) || null;
    if (data.identityProviderConfig) {
      this.identityProviderConfig = new IdentConfigOIDCModel(
        data.identityProviderConfig as JsonObject
      );
    }
  }
}
