import { Stack, Theme } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import Icon from '../icon';
import Text from '../text';
import Tooltip from '../tooltip';
import Card from '../card';
import { CARD_SIZE } from './helpers';

interface Props {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  disabledTooltip?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  addCard: {
    width: CARD_SIZE.width,
    height: CARD_SIZE.height,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    border: `1px dashed ${theme.palette.secondary.main}`,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    '&:hover:not(.disabled)': {
      opacity: 0.9,
    },
    '&.disabled': {
      cursor: 'default',
      opacity: 0.5,
    },
    '& .tooltip': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  },
}));

const AddCard: FC<Props> = ({ title, disabled, onClick, disabledTooltip }) => {
  const styles = useStyles();
  const compClass = classNames(styles.addCard, { disabled });
  const hasTooltip = disabled && !!disabledTooltip;
  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <Card className={compClass} onClick={handleClick} hover={!disabled}>
      <Tooltip disabled={!hasTooltip} title={disabledTooltip} className="tooltip">
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Icon name="plus-circle" color="link" size="large" />
          <Text color="link">{title}</Text>
        </Stack>
      </Tooltip>
    </Card>
  );
};

export default AddCard;
