import { ReactNode } from 'react';
import { IconType } from '../icon';

export type GridCard = {
  id: string;
  IconEl?: ReactNode;
  children?: ReactNode;
  iconId?: IconType;
  title?: string;
  detail?: string;
  onClick?: () => void;
  categoryId?: string;
};

export type GridCardCategory = {
  title: string;
  id: string;
};

export const CARD_SIZE = { height: 250, width: 200 };
