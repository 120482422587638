import { JsonObject } from '@/lib/helpers';
import { createContext } from 'react';

export type EmitterEventType = 'tab-block' | 'form-change';

export type EmitterEvent = { type: EmitterEventType; data?: JsonObject };

export type EmitterListener = (data?: JsonObject) => void;

export interface EmitterSettings {
  subscribe: (type: EmitterEventType, listenerFn: EmitterListener) => void;
  unsubscribe: (type: EmitterEventType, listenerFn: EmitterListener) => void;
  emit: (type: EmitterEventType, data?: JsonObject) => void;
}

export const EmitterContext = createContext<EmitterSettings>({
  subscribe: () => null,
  unsubscribe: () => null,
  emit: () => null,
});
