import { FC } from 'react';
import Select from '../select';
import { PublicServiceHandlingOptions } from '@/lib/models/policy.model';
import { FormProps } from '../helpers';

interface Props extends FormProps {
  value: boolean;
  onChange: (enabled: boolean) => void;
}

const PolicyPublicActionSelect: FC<Props> = ({ value, onChange, ...rest }) => {
  const handleChange = (value: string) => {
    onChange(value === 'allow');
  };

  const props = {
    ...rest,
    value: value ? 'allow' : 'block',
    onChange: handleChange,
    options: PublicServiceHandlingOptions,
    label: 'Default Public Service Action',
  };

  return <Select {...props} />;
};

export default PolicyPublicActionSelect;
