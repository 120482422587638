import { Box, Theme } from '@mui/material';
import MuiCard from '@mui/material/Card';
import classNames from 'classnames';
import { FC, ReactNode, useState } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  title?: ReactNode;
  className?: string;
  onClick?: () => void;
  hover?: boolean;
  size?: 'small' | 'medium';
}

const useStyles = createUseStyles<string, { size: 'medium' | 'small' }>((theme: Theme) => ({
  card: {
    border: `1px solid ${theme.palette.grey[400]}`,
    '&.clickable': {
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  inner: ({ size }) => ({
    padding: size === 'small' ? 8 : 14,
    flexGrow: 1,
    width: '100%',
    boxSizing: 'border-box',
  }),
  header: ({ size }) => ({
    padding: size === 'small' ? 8 : 14,
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white,
  }),
}));

const Card: FC<Props> = ({
  children,
  className = '',
  onClick,
  hover = false,
  size = 'medium',
  title = '',
}) => {
  const styles = useStyles({ size });
  const compClass = classNames(styles.card, className, { clickable: !!onClick });
  const [elevation, setElevation] = useState(0);

  const handleClick = () => {
    onClick && onClick();
  };

  const handleHover = (enabled: boolean) => {
    if (!hover) {
      return;
    }
    setElevation(enabled ? 1 : 0);
  };

  const hasTitle = !!title;

  return (
    <MuiCard
      elevation={elevation}
      className={compClass}
      onClick={handleClick}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      {hasTitle && <Box className={styles.header}>{title}</Box>}
      <Box className={styles.inner}>{children}</Box>
    </MuiCard>
  );
};

export default Card;
