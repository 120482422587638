import Icon from '@/components/icon';
import Text from '@/components/text';
import useApp from '@/hooks/use-app.hook';
import { Box, Fade, Stack, Theme } from '@mui/material';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { AuthRoleLabels } from '@/lib/services/auth.service';
import useAppData from '@/hooks/use-app-data.hook';
import { OrgBasicInfo } from '@/lib/models/org.model';
import { AppDataQueryKey } from '@/lib/query-client';

const useStyles = createUseStyles((theme: Theme) => ({
  toastContainer: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    zIndex: theme.zIndex.snackbar,
    transition: 'width 150ms ease',
    width: 200,
    overflow: 'hidden',
    '&.open': {
      width: 'calc(100% - 40px)',
    },
  },
  assumeRoleToast: {
    backgroundColor: '#ffa32fe0',
    padding: 10,
    height: 60,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[600],
    borderRadius: 20,
    cursor: 'pointer',
    padding: '8px 12px 8px 6px',
  },
  hideButton: {
    '& svg': {
      transition: 'transform 200ms ease',
    },
    '&:hover': {
      '& svg': {
        transform: 'translateX(-4px)',
      },
    },
  },
  logoutButton: {
    paddingLeft: 12,
  },
  iconButton: {
    height: 24,
    '& svg': {
      transition: 'transform 200ms ease',
    },
    '&:hover': {
      '& svg': {
        transform: 'translateX(4px)',
      },
    },
  },
}));

const AssumeRoleToast: FC = () => {
  const { user } = useApp();
  const [open, setOpen] = useState(true);
  const styles = useStyles();
  const { data } = useAppData<OrgBasicInfo>(AppDataQueryKey.OrgBasicInfo);

  if (!user?.assumed) {
    return null;
  }

  const handleToggleOpen = () => {
    setOpen((old) => !old);
  };

  const handleExit = () => {
    console.log('Exit');
  };

  const compClass = classNames(styles.toastContainer, { open });

  const { role } = user;
  const roleLabel = AuthRoleLabels[role!];

  return (
    <Box className={compClass}>
      <Fade in>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={styles.assumeRoleToast}
        >
          <Stack alignItems="center" gap={1} direction="row">
            <Box mt={0.5}>
              <Icon name="assume-role" />
            </Box>
            {open && (
              <Box>
                <Text bold dotdot>
                  Role Assumption: [{roleLabel}] {data?.name}
                </Text>
              </Box>
            )}
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
            {open && (
              <>
                <Box
                  onClick={handleToggleOpen}
                  role="button"
                  className={classNames(styles.button, styles.hideButton)}
                >
                  <Icon name="chevron-left" />
                  <Text>Hide</Text>
                </Box>
                <Box
                  role="button"
                  className={classNames(styles.button, styles.logoutButton)}
                  onClick={handleExit}
                >
                  <Icon name="log-out" />
                  <Text>Deactivate</Text>
                </Box>
              </>
            )}
            {!open && (
              <>
                <Box className={styles.iconButton}>
                  <Icon name="chevron-right" onClick={handleToggleOpen} />
                </Box>
                <Icon name="log-out" onClick={handleExit} />
              </>
            )}
          </Stack>
        </Stack>
      </Fade>
    </Box>
  );
};

export default AssumeRoleToast;
