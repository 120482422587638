import { FC, ReactNode } from 'react';
import Text from '../text';
import { Box, Stack } from '@mui/material';

interface Props {
  children: ReactNode;
}

const PageHeader: FC<Props> = ({ children }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" mb={4}>
      {children}
    </Stack>
  );
};

interface TitleProps {
  children: ReactNode;
}

export const PageBlurb: FC<TitleProps> = ({ children }) => {
  return (
    <Box minHeight={40}>
      <Text color="black">{children}</Text>
    </Box>
  );
};

export default PageHeader;
