import { Box, Stack, Theme } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import Text from '../text';
import Icon from '../icon';
import Button from '../button';
import InlineToast from '../toasts/inline';
import { createUseStyles } from 'react-jss';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { FileWithPreview, ImageExtension, ImageFileTypes } from './helpers';

interface Props {
  title: string;
  onUpload?: (dataUri: string) => void;
  maxFileSizeKb?: number;
  allowedImageTypes?: ImageExtension[];
  imageWidth?: number;
  imageHeight?: number;
}

const useStyles = createUseStyles<string, { imageWidth: number; imageHeight: number }>(
  (theme: Theme) => ({
    imageUploader: {
      border: `1px solid ${theme.palette.grey[200]}`,
      padding: 16,
      borderRadius: theme.shape.borderRadius,
      '& .dropzone': {
        cursor: 'pointer',
        padding: 15,
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
        border: `1px dashed ${theme.palette.grey[200]}`,
        color: theme.palette.grey[700],
      },
    },
    uploadPreview: ({ imageWidth, imageHeight }) => ({
      '& img': {
        width: imageWidth,
        height: imageHeight,
        display: 'block',
      },
    }),
    uploadPreviewPlaceholder: ({ imageWidth, imageHeight }) => ({
      backgroundColor: theme.palette.grey[200],
      width: imageWidth,
      height: imageHeight,
      borderRadius: theme.shape.borderRadius,
    }),
    footer: {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      marginTop: 16,
      paddingTop: 16,
    },
  })
);

const ImageUploader: FC<Props> = ({
  title,
  onUpload,
  maxFileSizeKb = 300,
  allowedImageTypes = ['png', 'jpg'],
  imageWidth = 40,
  imageHeight = 40,
}) => {
  const styles = useStyles({ imageWidth, imageHeight });

  const [uploadFile, setUploadFile] = useState<FileWithPreview | null>(null);
  const [uploadError, setUploadError] = useState('');

  const allowedMimetypes = useMemo(() => {
    const mimetypes: Record<string, string[]> = {};

    ImageFileTypes.filter(({ ext }) => allowedImageTypes.includes(ext)).forEach(({ mimetype }) => {
      mimetypes[mimetype] = [];
    });

    return mimetypes;
  }, [allowedImageTypes]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: allowedMimetypes,
    onDrop: (acceptedFiles: FileWithPath[]) => {
      setUploadError('');
      if (!acceptedFiles.length) {
        return;
      }

      const file = acceptedFiles[0];

      if (file.size > maxFileSizeKb * 1000) {
        console.error('image invalid via size', file);
        setUploadError(`Please limit the image to a maximum size of ${maxFileSizeKb}kb`);
        setUploadFile(null);
        return;
      }

      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        setUploadFile(Object.assign(file, { preview: URL.createObjectURL(file) }));
      };
    },
  });

  const handleAccept = () => {
    if (!uploadFile) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      const dataURL = loadEvent?.target?.result;
      setUploadFile(null);

      if (!dataURL) {
        setUploadError('There was an error uploading your file. Please try again.');
        return;
      }

      setUploadError('');
      onUpload?.(String(dataURL));
    };

    reader.readAsDataURL(uploadFile);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      if (uploadFile?.preview) {
        URL.revokeObjectURL(uploadFile.preview);
      }
    };
  }, [uploadFile]);

  const hasError = !!uploadError;
  const hasFile = !!uploadFile;

  return (
    <Box className={styles.imageUploader}>
      <Box mb={2}>
        <Text bold>{title}</Text>
      </Box>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        height={200}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <input {...getInputProps()} />
        <Box mr={1}>
          <Icon name="upload" size="large" />
        </Box>
        <Box>Drag and drop an image file here, or click to choose a file</Box>
      </Box>

      <Stack mt={3} gap={1}>
        <Text bold size="small">
          Upload Preview
        </Text>
        <Box>
          {hasFile && (
            <Box className={styles.uploadPreview}>
              <img
                src={uploadFile.preview}
                // Revoke data uri after image is loaded
                onLoad={() => {
                  URL.revokeObjectURL(uploadFile.preview);
                }}
              />
            </Box>
          )}
          {!hasFile && (
            <Box className={styles.uploadPreviewPlaceholder}>
              <Box className="inner"></Box>
            </Box>
          )}
        </Box>
      </Stack>

      <Stack justifyContent="space-between" width="100%" direction="row" className={styles.footer}>
        <Box>{hasError && <InlineToast message={uploadError} level="error" />}</Box>

        <Stack direction="row" gap={2}>
          <Button label="upload" disabled={!hasFile} onClick={handleAccept} type="submit" />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ImageUploader;
