import { BaseModel } from './base.model';
import { JsonObject } from '../helpers';

type AgentScope = 'org' | 'global';

type AgentPortalConfig = {
  description: string;
  greeting: string;
  badge: {
    title: string;
    image: string;
  };
};

export type AgentMeta = {
  id: string;
  name: string;
};

export class AgentModel extends BaseModel {
  orgId: string;
  name: string;
  description: string;
  scope: AgentScope;
  portal: AgentPortalConfig;
  systemPrompt: string;
  dataSourceIds: string[];
  privateModelId: string;
  status: string;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof AgentModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.description = (data.description as string) || '';
    this.scope = (data.scope as AgentScope) || 'org';
    this.portal = (data.portal as AgentPortalConfig) || {
      description: '',
      greeting: 'What would you like to get done today?',
      badge: {
        title: 'Assistant',
        image: '',
      },
    };
    this.systemPrompt = (data.systemPrompt as string) || '';
    this.dataSourceIds = (data.dataSourceIds as string[]) || [];
    this.privateModelId = (data.privateModelId as string) || '';
    this.status = (data.status as string) || 'inactive';
  }

  get meta(): AgentMeta {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
