import { AppDataQueries, QueryOptions } from '@/lib/app-data';
import { AppDataQueryKey } from '@/lib/query-client';
import { UseQueryResult, useQuery } from 'react-query';

const useAppData = <T>(
  queryKey: AppDataQueryKey,
  options: QueryOptions = {}
): UseQueryResult<T> => {
  const { queryFn, options: defaultOptions } = AppDataQueries.get(queryKey) || {};
  return useQuery([queryKey], queryFn!, { ...defaultOptions, ...options });
};

export default useAppData;
