import { FC } from 'react';
import InputControl from '@/components/form/input-control';

import { Box, Grid } from '@mui/material';
import FormSection from '@/components/form/form-section';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { useQuery } from 'react-query';
import useQueryHelper from '@/hooks/use-query-helper';
import useToast from '@/hooks/use-toast.hook';
import { getOrg, patchOrg } from '@/lib/services/org.service';
import FormButtons from '@/components/form/form-buttons';
import FormLabel from '@/components/form-label';
import { OrgModel } from '@/lib/models/org.model';
import useApp from '@/hooks/use-app.hook';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const fields = ['branding'];
const formModel = new OrgModel();

const CustomMessageForm: FC = () => {
  const { toast, errorToast } = useToast();
  const { isDemo } = useApp();
  const { canChangeCustomMessage } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setFieldError, setSubmitting, resetForm }) => {
      const { patched, error, fieldErrors } = await patchOrg(values);

      setSubmitting(false);

      if (fieldErrors) {
        fieldErrors.forEach(({ msg, path }) => setFieldError(path, msg));
        return;
      }

      if (!patched || error) {
        errorToast(error || 'The organization was not updated');
        return;
      }

      toast('The organization was updated');
      resetForm({ values });
    },
  });

  const query = useQuery([QueryKey.OrgView], async () => getOrg());
  const { showLoader } = useQueryHelper(query);

  if (showLoader) {
    return null;
  }

  return (
    <Box>
      <PageFeatureToast featureId="change-custom-message" can={canChangeCustomMessage} />
      <Form formHandle={formHandle} query={query}>
        <FormSection title="Portal Welcome Message">
          <InputControl
            name="branding.portalWelcome.title"
            label="Welcome Message Title"
            formHandle={formHandle}
            readonly={!canChangeCustomMessage}
          />

          <InputControl
            name="branding.portalWelcome.message"
            label="Welcome Message Body"
            formHandle={formHandle}
            type="textarea"
            readonly={!canChangeCustomMessage}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputControl
                name="branding.portalWelcome.learnMoreLink"
                label="Learn More Link"
                formHandle={formHandle}
                readonly={!canChangeCustomMessage}
              />
            </Grid>

            <Grid item xs={6}>
              <InputControl
                name="branding.supportEmail"
                label="IT Support Email"
                formHandle={formHandle}
                readonly={!canChangeCustomMessage}
              />
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title="Public Service Warning Messages">
          <Box marginBottom={4}>
            Public service warning messages are displayed inline after a user sends a message to a
            known public service. The warning message should let the user know that the request
            and/or the response were either blocked or modified, based on organizational policy.
          </Box>

          <FormLabel>Request Blocked</FormLabel>
          <Box>
            If an end user is not allowed to access a public service, this message will be displayed
            inline, after the request is sent, and there will be no further response.
          </Box>
          <InputControl
            name="branding.warnings.publicServiceBlocked"
            label="Request Blocked"
            formHandle={formHandle}
            type="textarea"
            readonly={!canChangeCustomMessage}
          />
          <FormLabel>Sensitive Data Detected</FormLabel>
          <Box>
            If sensitive data is detected in an end user's request to a public service, this message
            will be displayed inline after the request.
          </Box>
          <InputControl
            name="branding.warnings.publicServiceSensitiveData"
            label="Sensitive data detected"
            formHandle={formHandle}
            type="textarea"
            readonly={!canChangeCustomMessage}
          />
        </FormSection>

        {isDemo && (
          <FormSection title="Intent Blocking Messages">
            <Box marginBottom={4}>
              Intent blocking messages are displayed inline after a user sends a message in the
              Portal. The warning message should let the user know that their request was blocked
              because its intent is not allowed, based on organizational and/or group policy.
            </Box>

            <FormLabel>Intent Blocked</FormLabel>
            <Box>
              If an end user's message is blocked, this message will be displayed inline, after the
              request is sent, and there will be no further response.
            </Box>
            <InputControl
              name="branding.warnings.privateServiceIntentBlocked"
              label="Intent Blocked"
              formHandle={formHandle}
              type="textarea"
              readonly={!canChangeCustomMessage}
            />
          </FormSection>
        )}

        <FormButtons formHandle={formHandle} readonly={!canChangeCustomMessage} />
      </Form>
    </Box>
  );
};

export default CustomMessageForm;
