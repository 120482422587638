import { DateVal, getNiceDate, getUserTimezone } from '@/lib/helpers';
import { FC, ReactNode, useMemo } from 'react';
import Text from '../text';

interface Props {
  val: DateVal;
  size?: 'x-large' | 'large' | 'medium' | 'small' | 'x-small';
  withTime?: boolean;
  withOffset?: boolean;
}

const NiceDate: FC<Props> = ({ val, size = 'medium', withTime = false, withOffset = false }) => {
  const niceDate = useMemo(() => {
    const { timezone } = getUserTimezone();
    return getNiceDate(val, undefined, timezone);
  }, [val]);

  const { formatted, date, timeFull, offset } = niceDate;

  let DateEl: ReactNode[] = [<Text size={size}>{formatted}</Text>];

  if (withTime) {
    DateEl = [
      <Text key="date" size={size}>
        {date}
      </Text>,
      <Text key="time-full" size={size}>
        {timeFull}
      </Text>,
    ];
  }

  if (withOffset) {
    DateEl.push(
      <Text key="offset" size={size}>
        UTC {offset}
      </Text>
    );
  }

  return DateEl;
};

export default NiceDate;
