import {
  useState,
  useEffect,
  ChangeEvent,
  FC,
  useCallback,
  ReactNode,
  HTMLAttributes,
} from 'react';
import debounce from 'lodash/debounce';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormProps } from '../form/helpers';
import { UiOption } from '@/lib/helpers';
import FormControl from '../form/form-control';
import { Box } from '@mui/material';
import usePrior from '@/hooks/use-prior.hook';

interface Props extends FormProps {
  value: UiOption | null;
  onChange: (value: UiOption | null, event: ChangeEvent<HTMLInputElement>) => void;
  onSearch: (value: string) => Promise<UiOption[]>;
  onOptionRender?: (props: HTMLAttributes<HTMLLIElement>, option: UiOption) => ReactNode;
  size?: 'medium' | 'small';
  width?: string | number;
}

const SelectSearch: FC<Props> = ({
  onSearch,
  onChange,
  label,
  error = '',
  name,
  value,
  size = 'medium',
  onOptionRender,
  width = 300,
}) => {
  const [options, setOptions] = useState<UiOption[]>([]);
  const [keyword, setKeyword] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>, newValue: UiOption) => {
    onChange(newValue, event);
  };

  const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnSearch = useCallback(
    debounce((keyword: string) => {
      onSearch(keyword).then(setOptions);
    }, 600),
    [onSearch]
  );

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: UiOption) => {
    if (!onOptionRender) {
      return (
        <Box component="li" {...props}>
          {option.label}
        </Box>
      );
    }

    return onOptionRender(props, option);
  };

  const handleOptionEqual = (option: UiOption, selected: UiOption) =>
    option?.value === selected.value;

  useEffect(() => {
    const safeKeyword = String(keyword).trim();

    if (!safeKeyword) {
      setOptions([]);
      return;
    }

    debounceOnSearch(safeKeyword);
  }, [keyword, debounceOnSearch]);

  // @todo this doesn't work for some reason
  // if some other action clears the search (like clearing a form), reset the options
  const priorValue = usePrior(value);
  useEffect(() => {
    if (value === null && priorValue !== null) {
      setKeyword('');
    }
  }, [value, priorValue]);

  return (
    <FormControl label="" error={error} name={name} size={size}>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label as string}
        style={{ width }}
        onChange={handleChange}
        value={value}
        size={size}
        noOptionsText="&nbsp;"
        renderOption={handleRenderOption}
        isOptionEqualToValue={handleOptionEqual}
        renderInput={(params) => (
          <TextField
            {...params}
            hiddenLabel
            placeholder={label as string}
            variant="outlined"
            onChange={handleKeywordChange}
          />
        )}
      />
    </FormControl>
  );
};

export default SelectSearch;
