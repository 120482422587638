import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import useToast from '@/hooks/use-toast.hook';
import useFormHandle from '@/hooks/use-form-handle.hook';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import FormButtons from '@/components/form/form-buttons';
import useQueryHelper from '@/hooks/use-query-helper';
import { getAllProviders } from '@/lib/services/context-provider.service';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import { AgentModel } from '@/lib/models/agent.model';
import { getAgentById, updateAgent } from '@/lib/services/agent.service';

const fields = ['dataSourceIds'];
const formModel = new AgentModel();

interface Props {
  agentId: string;
}

const AgentDatasourcesForm: FC<Props> = ({ agentId }) => {
  const { errorToast, toast } = useToast();
  const { canChangeAgents } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await updateAgent(values);
      setSubmitting(false);
      resetForm({ values });

      if (updated) {
        toast('The assistant was updated');
        return;
      }

      errorToast('The assistant was not updated');
    },
  });

  const agentQuery = useQuery([QueryKey.AgentView], async () => getAgentById(agentId));

  const contextProviderQuery = useQuery([QueryKey.ContextProvidersList], async () =>
    getAllProviders()
  );
  const { data: providers } = contextProviderQuery;
  const { showLoader: agentLoader } = useQueryHelper(agentQuery);
  const { showLoader: providerLoader } = useQueryHelper(contextProviderQuery);

  const providerOptions = useMemo(() => {
    return (providers || []).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [providers]);

  if (agentLoader || providerLoader) {
    return;
  }

  return (
    <Form formHandle={formHandle} query={agentQuery}>
      <PageFeatureToast featureId="change-agent" can={canChangeAgents} />
      <FormSection title="Data Source Assignment">
        <InputControl
          name="dataSourceIds"
          label="Context"
          formHandle={formHandle}
          readonly={!canChangeAgents}
          type="list-toggle"
          leftTitle="Available Data Sources"
          rightTitle="Assigned Data Sources"
          options={providerOptions}
        />
      </FormSection>

      <FormButtons formHandle={formHandle} readonly={!canChangeAgents} />
    </Form>
  );
};

export default AgentDatasourcesForm;
