import { createContext } from 'react';

export interface DomSizeSettings {
  height: number;
  width: number;
  getSize: () => { height: number; width: number };
}

export const DomSizeContext = createContext<DomSizeSettings>({
  height: 0,
  width: 0,
  getSize: () => ({ height: 0, width: 0 }),
});
