import NoneChip from '@/components/chip/none-chip';
import TextInput from '@/components/form/text-input';
import { Stack } from '@mui/material';
import { FC } from 'react';

interface Props {
  urls: string[];
}

const KendraCrawlList: FC<Props> = ({ urls }) => {
  if (!Array.isArray(urls) || !urls.length) {
    return <NoneChip />;
  }

  return (
    <Stack gap={1} width="100%">
      {urls.map((url) => (
        <TextInput
          name="kendra-crawl-list"
          key={url}
          value={url}
          readonly
          copyClip
          openUrl
          size="small"
        />
      ))}
    </Stack>
  );
};

export default KendraCrawlList;
