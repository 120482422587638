import { FC } from 'react';
import { AuthUser } from '../../lib/services/auth.service';
import { Avatar, Box } from '@mui/material';
import { getFirstLastName } from '../../lib/helpers';
import Icon from '../icon';
import { createUseStyles } from 'react-jss';

interface Props {
  user: AuthUser;
}

const useStyles = createUseStyles({
  userBadge: {
    backgroundColor: 'none',
  },
});

const UserBadge: FC<Props> = ({ user }) => {
  const styles = useStyles();
  const { displayName, photoURL } = user;
  const username = displayName || '';
  const { first, last } = getFirstLastName(username);
  const hasPhoto = Boolean(photoURL);
  const hasName = Boolean(first) || Boolean(last);
  const initials = `${first[0] || ''}${last[0] || ''}`.toUpperCase();

  return (
    <Box>
      {hasPhoto && (
        <Avatar alt={username} src={photoURL || ''} imgProps={{ referrerPolicy: 'no-referrer' }} />
      )}
      {!hasPhoto && hasName && <Avatar className={styles.userBadge}>{initials}</Avatar>}
      {!hasPhoto && !hasName && (
        <Avatar className={styles.userBadge}>
          <Icon name="person" color="white" />
        </Avatar>
      )}
    </Box>
  );
};

export default UserBadge;
