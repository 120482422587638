import { object, string } from 'yup';
import { JsonObject } from '@/lib/helpers';
import { BaseModel } from '@/lib/models/base.model';

type PrivateModelConfig = {
  service: string;
  model: string;
  modelVersion: string;
  region: string;
  modelId: string;
};

export class PrivateModelModel extends BaseModel {
  orgId: string;
  name: string;
  connectorId: string;
  provider: string;
  service: string;
  modelId: string; // <connector type>.<connector service>.<model identifier>
  modelProviderName: string;
  providerModelProperties: PrivateModelConfig;

  schema = object({
    name: string().required('Name is required'),
    connectorId: string().required('Please choose a connector'),
    modelId: string().required('Please choose a model'),
  });

  constructor(props: JsonObject = {}) {
    super(props);
    const data = props as Record<keyof PrivateModelModel, unknown>;
    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.connectorId = (data.connectorId as string) || '';
    this.provider = (data.provider as string) || '';
    this.service = (data.service as string) || '';
    this.modelId = (data.modelId as string) || '';
    this.modelProviderName = (data.modelProviderName as string) || '';

    // support legacy config prop
    const modelProps = (data.providerModelProperties ||
      (data as JsonObject).config) as PrivateModelConfig;

    this.providerModelProperties = modelProps || {
      service: '',
      model: '',
      modelVersion: '',
      region: '',
      modelId: '',
    };
  }

  get iconId(): string {
    const modelToken = String(this.modelId).split('.').pop();
    return String(modelToken).split('-').shift() || '';
  }
}
