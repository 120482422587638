import { cloneElement, FC } from 'react';
import useModal from '../../hooks/use-modal.hook';
import { Box } from '@mui/material';
import { isModalConfig } from './helpers';
import { Modal } from '.';

const ModalRoot: FC = () => {
  const { modalStack } = useModal();

  return (
    <Box>
      {modalStack.map((modal: Modal) => {
        if (isModalConfig(modal)) {
          const { Component, key, props = {} } = modal;
          return Component ? <Component key={key} {...props} /> : null;
        }

        const { element, key } = modal;

        return cloneElement(element, { key });
      })}
    </Box>
  );
};

export default ModalRoot;
