import { FC, useCallback, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { FormProps } from '../helpers';
import ListToggle from '../list-toggle';
import { UiOption } from '@/lib/helpers';
import { Box, Stack, Theme } from '@mui/material';
import useDrawer from '@/hooks/use-drawer';
import Icon from '@/components/icon';
import Text from '@/components/text';

interface Props extends FormProps {
  options: UiOption[];
  value: string[];
  onChange: (options: UiOption[]) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  groupOption: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    padding: 8,
    '& .label': {
      maxWidth: '90%',
    },
  },
  groupListToggle: {
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      flexGrow: 1,
    },
  },
}));

const GroupsListToggle: FC<Props> = (props) => {
  const styles = useStyles();

  const { openDrawer, DrawerEl } = useDrawer('group');

  const handleView = useCallback(
    (groupId: string) => {
      openDrawer({ id: groupId, hideToolbar: true });
    },
    [openDrawer]
  );

  const { options } = props;
  const groupOptions: UiOption[] = useMemo(() => {
    if (!Array.isArray(options)) {
      return [];
    }

    return options.map(({ label, value }) => {
      return {
        label: (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={styles.groupOption}
          >
            <Box className="label">
              <Text dotdot>{label}</Text>
            </Box>
            <Icon name="info-circle" onClick={() => handleView(value)} size="small" color="grey" />
          </Stack>
        ),
        value,
        meta: { keyword: label },
      };
    });
  }, [options, handleView, styles.groupOption]);

  const listToggleProps = { ...props, options: groupOptions };

  return (
    <>
      <Box className={styles.groupListToggle}>
        <ListToggle
          {...listToggleProps}
          label="Groups"
          leftTitle="Available Groups"
          rightTitle="Assigned Groups"
        />
      </Box>
      {DrawerEl}
    </>
  );
};

export default GroupsListToggle;
