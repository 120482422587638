import { FC } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getPolicyById, patchPolicy } from '@/lib/services/policy.service';
import useToast from '@/hooks/use-toast.hook';
import { PolicyModel } from '@/lib/models/policy.model';
import FormSection from '@/components/form/form-section';
import useQueryHelper from '@/hooks/use-query-helper';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import FormButtons from '@/components/form/form-buttons';
import { getAllProviders } from '@/lib/services/context-provider.service';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const fields = ['contextDataSources'];
const formModel = new PolicyModel();

interface Props {
  policyId: string;
}

const ContextProvidersForm: FC<Props> = ({ policyId }) => {
  const { errorToast, toast } = useToast();
  const { canChangePolicy } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await patchPolicy(policyId, values.propSlice(fields));

      setSubmitting(false);
      resetForm({ values });

      if (updated) {
        toast('The policy was updated');
        return;
      }

      errorToast('The policy was not updated');
    },
  });

  const groupPolicyQuery = useQuery([QueryKey.PolicyView, policyId], async () =>
    getPolicyById(policyId)
  );

  const contextProviderQuery = useQuery([QueryKey.ContextProvidersList], async () =>
    getAllProviders()
  );

  const { data: providers } = contextProviderQuery;
  const { showLoader: policyLoading } = useQueryHelper(groupPolicyQuery);
  const { showLoader: providerLoading } = useQueryHelper(contextProviderQuery);

  const providerOptions = useMemo(() => {
    return (providers || []).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [providers]);

  if (policyLoading || providerLoading) {
    return;
  }

  return (
    <Form formHandle={formHandle} query={groupPolicyQuery}>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />
      <FormSection title="Data Source Assignment">
        <InputControl
          name="contextDataSources.enabled"
          label="Context"
          formHandle={formHandle}
          readonly={!canChangePolicy}
          type="list-toggle"
          leftTitle="Available Data Sources"
          rightTitle="Assigned Data Sources"
          options={providerOptions}
        />
      </FormSection>

      <FormButtons formHandle={formHandle} readonly={!canChangePolicy} />
    </Form>
  );
};

export default ContextProvidersForm;
