import { Box } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { useCollapse } from 'react-collapsed';

interface Props {
  open: boolean;
  children: ReactNode;
  duration?: number;
  className?: string;
}

const CollapseBox: FC<Props> = ({ children, open, duration = 200, className = '' }) => {
  const [defaultOpen] = useState(open);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getCollapseProps } = useCollapse({
    isExpanded: open,
    defaultExpanded: defaultOpen,
    duration,
  });

  return (
    <Box className={className} {...getCollapseProps()}>
      {children}
    </Box>
  );
};

export default CollapseBox;
