import { Box, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  variant?: 'outlined' | 'filled';
}

const useStyles = createUseStyles<string, { filled: boolean }>((theme: Theme) => ({
  title: {
    backgroundColor: ({ filled }) =>
      filled ? theme.palette.surface.secondary.main : theme.palette.common.white,
    color: ({ filled }) => (filled ? theme.palette.common.white : theme.palette.common.black),
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
}));

const PanelTitle: FC<Props> = ({ children, variant = 'outlined' }) => {
  const styles = useStyles({ filled: variant === 'filled' });
  return (
    <Box className={styles.title} pt={2} pb={2} pl={2} pr={2}>
      {children}
    </Box>
  );
};

export default PanelTitle;
