import { FC } from 'react';
import InputControl from '@/components/form/input-control';
import { Box, Grid, Stack } from '@mui/material';
import FormSection from '@/components/form/form-section';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { useQuery } from 'react-query';
import useQueryHelper from '@/hooks/use-query-helper';
import { getOrgPolicy, patchPolicy } from '@/lib/services/policy.service';
import { SensitiveDataActionOptions, SensitiveDataTypeOptions } from '@/lib/models/policy.model';
import { PolicyModel } from '@/lib/models/policy.model';
import useToast from '@/hooks/use-toast.hook';
import FormButtons from '@/components/form/form-buttons';
import FormLabel from '@/components/form-label';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const formModel = new PolicyModel();
const fields = ['sensitiveData'];

const OrgPolicySensitiveDataForm: FC = () => {
  const { toast, errorToast } = useToast();
  const { canChangePolicy } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await patchPolicy(values.id, values.propSlice(fields));
      setSubmitting(false);
      resetForm({ values });

      if (updated) {
        toast('The organization was updated');
        return;
      }

      errorToast('The organization was not updated');
    },
  });

  const { values } = formHandle;

  const query = useQuery([QueryKey.OrgPolicyView], async () => getOrgPolicy());
  const { showLoader } = useQueryHelper(query);

  if (showLoader) {
    return null;
  }

  return (
    <Box>
      <Form formHandle={formHandle} query={query}>
        <PageFeatureToast featureId="change-policy" can={canChangePolicy} />
        <FormSection title="Sensitive Data Settings">
          <Grid container alignItems="top">
            <Grid item xs={12} mb={2}>
              When sensitive data is detected in traffic to a public model, choose how to handle the
              identified sensitive portions of the request or the response.
            </Grid>
            <Grid item xs={5}>
              <Stack>
                <Box mb={1}>
                  <FormLabel>Enabled</FormLabel>
                  <InputControl
                    name="sensitiveData.detect"
                    type="switch"
                    formHandle={formHandle}
                    readonly={!canChangePolicy}
                  />
                </Box>
                <FormLabel>Action</FormLabel>
                <InputControl
                  name="sensitiveData.action"
                  type="select"
                  options={SensitiveDataActionOptions}
                  formHandle={formHandle}
                  disabled={!values.sensitiveData.detect}
                  readonly={!canChangePolicy}
                />
              </Stack>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Stack>
                <FormLabel>Detected Entity Types ({SensitiveDataTypeOptions.length})</FormLabel>
                <InputControl
                  name="sensitiveData.entities"
                  type="switch-list"
                  options={SensitiveDataTypeOptions}
                  formHandle={formHandle}
                  disabled={!values.sensitiveData.detect}
                  readonly={!canChangePolicy}
                  height={400}
                />
              </Stack>
            </Grid>
          </Grid>
        </FormSection>
        <FormButtons formHandle={formHandle} readonly={!canChangePolicy} />
      </Form>
    </Box>
  );
};

export default OrgPolicySensitiveDataForm;
