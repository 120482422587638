import { FC, useEffect } from 'react';
import Page, { PageProps } from '@/components/page';
import Tabs from '@/components/tabs';
import { useQuery } from 'react-query';
import { useParams } from '@tanstack/react-router';
import useApp from '@/hooks/use-app.hook';
import { QueryKey } from '@/lib/query-client';
import { getAgentById } from '@/lib/services/agent.service';
import AgentAdminProfileForm from './admin-profile-form';
import SystemPromptForm from './system-prompt';
import DatasourcesForm from './datasources-form';
import AgentPortalForm from './portal-form';

const TabLabels = ['Profile', 'Branding', 'Model and Prompt', 'Data Sources'];

const AgentViewPage: FC<PageProps> = () => {
  const { agentId } = useParams({ from: '/config/assistants/$agentId' });
  const { setPageTitle, isDemo } = useApp();

  const { data: agent } = useQuery([QueryKey.AgentView, agentId], async () =>
    getAgentById(agentId)
  );

  useEffect(() => {
    if (agent) {
      setPageTitle(agent.name, 'agent');
    }
  }, [agent, setPageTitle]);

  const compTabLabels = [...TabLabels];
  if (isDemo) {
    compTabLabels.push('Intents');
  }

  return (
    <Page>
      <Tabs
        labels={TabLabels}
        panels={[
          <AgentAdminProfileForm agentId={agentId} />,
          <AgentPortalForm agentId={agentId} />,
          <SystemPromptForm agentId={agentId} />,
          <DatasourcesForm agentId={agentId} />,
        ]}
      />
    </Page>
  );
};

export default AgentViewPage;
