import { FC } from 'react';
import { ShortcutMessage } from '@/lib/models/user-event';
import { Box, Stack, Theme } from '@mui/material';
import Text from '@/components/text';
import { createUseStyles } from 'react-jss';

interface Props {
  shortcut: ShortcutMessage;
}

const useStyles = createUseStyles((theme: Theme) => ({
  historyInput: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius,
    padding: '4px 8px',
  },
}));
const ShortcutHistory: FC<Props> = ({ shortcut: { values, instructions, name, steps } }) => {
  const styles = useStyles();
  return (
    <Stack gap={2}>
      <Text size="small" bold>
        Shortcut: {name}
      </Text>
      <Text size="small">{instructions}</Text>
      <Stack gap={1}>
        {steps.map(({ name, title, description }, index) => {
          const stepTitle =
            title ||
            description ||
            name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()); // Convert snake_case to Title Case
          const value = Array.isArray(values[index])
            ? (values[index] as string[]).join(', ')
            : values[index];
          return (
            <Stack gap={1}>
              <Text key={index} size="small">
                {stepTitle}{' '}
              </Text>
              <Box className={styles.historyInput}>
                <Text color="grey">{value}</Text>
              </Box>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default ShortcutHistory;
