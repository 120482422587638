import { createTheme } from '@mui/material';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

/*
 * // https://mui.com/material-ui/customization/palette
 * primary
 * - main color of the app, for the header background, etc.
 * - brand color
 * - @todo then why does mui used primary.main for input border color and checkbox background?
 *
 * secondary
 * - accent and distinguish
 * - action buttons, selection controls
 * - links, progress bars
 */

declare module '@mui/material/styles' {
  interface Palette {
    surface: {
      primary: {
        main: string;
      };
      secondary: {
        main: string;
      };
      info: {
        main: string;
      };
      error: {
        main: string;
      };
      warning: {
        main: string;
      };
      success: {
        main: string;
      };
    };
    link: {
      main: string;
    };
  }

  interface PaletteOptions {
    surface: {
      primary: {
        main: string;
      };
      secondary: {
        main: string;
      };
      info: {
        main: string;
      };
      error: {
        main: string;
      };
      warning: {
        main: string;
      };
      success: {
        main: string;
      };
    };
    link: {
      main: string;
    };
  }
}

const Theme = createTheme({
  palette: {
    common: {
      black: '#1b1d20',
      white: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
    surface: {
      primary: {
        main: '#00263e',
      },
      secondary: {
        main: '#6085c4',
      },
      info: {
        main: '#06aed41f',
      },
      error: {
        main: '#f044381f',
      },
      warning: {
        main: '#f5cb9654',
      },
      success: {
        main: '#12d4061f',
      },
    },
    primary: {
      main: '#4b66da',
    },
    secondary: {
      main: '#00436d',
    },
    link: {
      main: '#00436d',
    },
    success: {
      main: '#659d68',
    },
    error: {
      main: '#ea554e',
    },
  },
  typography: {
    fontFamily: 'Inter, Arial',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 450,
    fontWeightBold: 500,
  },
  shape: {
    borderRadius: 5,
  },
});

export default Theme;
