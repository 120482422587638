import {
  ForwardedRef,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { DtFilter, DtFilterField } from '../helpers';
import DateRangeInput from '@/components/form/date-range';
import { Box, Stack } from '@mui/material';
import Select from '@/components/form/select';
import { ListItem } from '@/components/form/list-editor';
import usePrior from '@/hooks/use-prior.hook';
import { DateRangeValue, UiOption } from '@/lib/helpers';
import SelectMulti from '@/components/form/select-multi';
import SelectSearch from '@/components/select-search';

interface Props {
  filter: DtFilter;
  onChange: (item: ListItem) => void;
  item: ListItem;
  editIndex: number;
  ref?: ForwardedRef<HTMLDivElement>;
}

export interface AddItemInstance {
  reset: () => void;
}

const AddItem = forwardRef<AddItemInstance, Props>(({ filter, onChange, item, editIndex }, ref) => {
  const { fields, values = [] } = filter;

  const fieldOptions = [{ label: 'Choose One', value: '-' }].concat(
    fields.map(({ columnName, label, highlander }) => ({
      label: label || columnName,
      value: columnName,
      disabled:
        highlander && values.some(({ columnName: valColName }) => valColName === columnName),
    }))
  );
  const [columnName, setColumnName] = useState('-');
  const field = fields.find(({ columnName: fColumnName }) => fColumnName === columnName);

  const { control, input, options, onSearch, searchLabel } = field || {};

  let InputEl: ReactNode = input || null;
  const handleChange = (value: unknown) => {
    onChange({
      label: field?.label || field?.columnName || '',
      value,
      meta: field,
    });
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        reset: () => {
          setColumnName('-');
        },
      };
    },
    []
  );

  const priorEditIndex = usePrior(editIndex);
  useEffect(() => {
    const hasPriorIndex = priorEditIndex !== -1;
    const hasIndex = editIndex !== -1;

    if (!hasPriorIndex && hasIndex) {
      const meta = item.meta as DtFilterField;
      setColumnName(meta?.columnName || '-');
    }
  }, [priorEditIndex, editIndex, item]);

  if (InputEl === null) {
    switch (control) {
      case 'date-range':
        InputEl = (
          <DateRangeInput
            size="small"
            name="date-range-filter"
            value={(item.value as DateRangeValue) || [null, null]}
            onChange={handleChange}
          />
        );
        break;
      case 'select-multi':
        {
          const handleSelectChange = (value: UiOption[]) => {
            handleChange(value.map(({ value }) => value));
          };
          InputEl = (
            <SelectMulti
              name="select-multi-filter"
              options={options || []}
              value={(item.value as string[]) || []}
              onChange={handleSelectChange}
              size="small"
            />
          );
        }
        break;
      case 'select-search':
        {
          InputEl = (
            <SelectSearch
              name="select-search-filter"
              label={searchLabel}
              value={item.value ? (item.value as UiOption) : null}
              size="small"
              onSearch={onSearch || (() => Promise.resolve([]))}
              onChange={handleChange}
            />
          );
        }
        break;
    }
  }

  return (
    <Stack direction="row" alignItems="center" gap={1} maxWidth="100%">
      <Box width={150} ref={ref}>
        <Select
          name="filter-field"
          options={fieldOptions}
          value={columnName}
          onChange={setColumnName}
          size="small"
        />
      </Box>
      <Box width={350}>{InputEl}</Box>
    </Stack>
  );
});

export default AddItem;
