export type IEnvironment = {
  SP_API_URL: string;
  ATLAS_DATASOURCE: string;
  ATLAS_DATABASE: string;
  AWS_CONNECTOR_ID: string;
  DASHBOARD_URL: string;
  DASHBOARD_ID: string;
  FIREBASE_API_KEY: string;
  DATADOG_APPLICATION_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  DATADOG_SERVICE: string;
  DATADOG_SITE: string;
  APP_VERSION: string;
  MODE: string;
};

const meta = import.meta.env;

const Environment: IEnvironment = Object.freeze({
  SP_API_URL: (meta.VITE_SP_API_URL as string) || '',
  ATLAS_DATASOURCE: (meta.VITE_ATLAS_DATASOURCE as string) || '',
  ATLAS_DATABASE: (meta.VITE_ATLAS_DATABASE as string) || '',
  AWS_CONNECTOR_ID: (meta.VITE_AWS_CONNECTOR_ID as string) || '',
  DASHBOARD_URL: (meta.VITE_DASHBOARD_URL as string) || '',
  DASHBOARD_ID: (meta.VITE_DASHBOARD_ID as string) || '',
  FIREBASE_API_KEY: (meta.VITE_FIREBASE_API_KEY as string) || '',
  DATADOG_APPLICATION_ID: (meta.VITE_DATADOG_APPLICATION_ID as string) || '',
  DATADOG_CLIENT_TOKEN: (meta.VITE_DATADOG_CLIENT_TOKEN as string) || '',
  DATADOG_SERVICE: (meta.VITE_DATADOG_SERVICE as string) || '',
  DATADOG_SITE: (meta.VITE_DATADOG_SITE as string) || '',
  APP_VERSION, // https://stackoverflow.com/questions/67194082/how-can-i-display-the-current-app-version-from-package-json-to-the-user-using-vi
  MODE: meta.MODE,
});

export const isProd = () => Environment.MODE === 'production';

export default Environment;
