import { FC, useMemo } from 'react';
import { AgentModel } from '@/lib/models/agent.model';
import UsageCard from '../usage-card';
import AgentBadge from '../agent-badge';
import { Link } from '@tanstack/react-router';
import Icon from '../icon';

interface Props {
  agents: AgentModel[];
}

const AgentUsageCard: FC<Props> = ({ agents }) => {
  const items = useMemo(() => {
    return agents.map((agent) => ({
      id: agent.id,
      name: agent.name,
      BadgeEl: <AgentBadge agent={agent} size="small" />,
      LinkEl: (
        <Link params={{ agentId: agent.id }} to={`/config/assistants/$agentId`}>
          <Icon name="arrow-right" size="small" block />
        </Link>
      ),
    }));
  }, [agents]);

  return <UsageCard title="Assistants" items={items} icon="assistant" />;
};

export default AgentUsageCard;
