import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const PanelContent: FC<Props> = ({ children, className }) => {
  return (
    <Box pt={4} pb={4} pl={2} pr={2} className={className}>
      {children}
    </Box>
  );
};

export default PanelContent;
