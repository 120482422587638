import { FC } from 'react';
import Card from '@/components/card';
import Chip from '@/components/chip';
import PublicServiceButton from '@/components/public-service-button';
import Text from '@/components/text';
import { PolicyMeta } from '@/lib/models/policy.model';
import { PolicyModel } from '@/lib/models/policy.model';
import { PublicServiceMeta } from '@/lib/models/public-service.model';
import { Box, Stack } from '@mui/material';
import { GroupMeta } from '@/lib/models/group.model';
import { ContextProviderMeta } from '@/lib/models/context-provider';
import useAppData from '@/hooks/use-app-data.hook';
import CardHeader from '../card/card-header';
import { Link } from '@tanstack/react-router';
import { AppDataQueryKey } from '@/lib/query-client';
import { PrivateModelModel } from '@/lib/models/private-model.model';
import ScrollBox from '../scroll-box';
import { AgentMeta } from '@/lib/models/agent.model';

interface Props {
  policy: PolicyMeta | PolicyModel;
  groups?: GroupMeta[];
  contextProviders?: ContextProviderMeta[];
  privateModels?: PrivateModelModel[];
  agents?: AgentMeta[];
}

interface CardSectionProps {
  title: string;
  items: { id: string | null; name: string }[];
}

const CardSection: FC<CardSectionProps> = ({ title, items }) => {
  return (
    <Box mt={2.5}>
      <Box mb={1}>
        <Text size="small">{title}</Text>
      </Box>

      <Stack direction="row" gap={1} flexWrap="wrap">
        {items.map(({ id, name }) => {
          return <Chip key={id} label={name} />;
        })}
      </Stack>
    </Box>
  );
};

const PolicyCard: FC<Props> = ({
  policy,
  groups = [],
  contextProviders = [],
  privateModels = [],
  agents = [],
}) => {
  const isOrgPolicy = policy.scope === 'org';
  const { name, scope, publicServices } = policy;
  const isOrg = scope === 'org';
  const hasGroups = !!groups.length;
  const hasContext = !!contextProviders.length;
  const hasModels = !!privateModels.length;
  const hasAgents = !!agents.length;
  const link = isOrgPolicy ? '/config/default-policy' : `/config/group-policies/${policy.id}`;

  const { data: psList = [] } = useAppData<PublicServiceMeta[]>(AppDataQueryKey.PublicServices);

  const validPublicServices = psList.filter(({ id }) => publicServices.enabled?.includes(id));

  return (
    <Card>
      <CardHeader title={isOrg ? 'Organization' : name} icon={isOrg ? 'organization' : 'policy'}>
        <Link to={link}>
          <Text size="small">View Policy</Text>
        </Link>
      </CardHeader>

      <Box>
        <Box mb={1}>
          <Text size="small">Allowed Public Services</Text>
        </Box>
        <ScrollBox max={6} emptyMessage="This policy does not grant access to any public services.">
          {validPublicServices.map((publicService) => {
            return <PublicServiceButton publicService={publicService} key={publicService.id} />;
          })}
        </ScrollBox>

        {hasContext && <CardSection title="Assigned Data Sources" items={contextProviders} />}

        {hasModels && <CardSection title="Enabled Portal Private Models" items={privateModels} />}

        {hasAgents && <CardSection title="Assigned Assistants" items={agents} />}

        {!isOrgPolicy && hasGroups && <CardSection title="Assigned Groups" items={groups} />}
      </Box>
    </Card>
  );
};

export default PolicyCard;
