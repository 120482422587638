import { ChangeEvent, FC } from 'react';
import MuiPagination from '@mui/material/Pagination';
import {
  useGridApiContext,
  useGridSelector,
  GridPagination,
  gridRowCountSelector,
  gridPageSizeSelector,
} from '@mui/x-data-grid';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Box, Stack, Theme } from '@mui/material';
import Icon from '../icon';
import { DtGridApiCommunity } from '.';
import { getNiceNumber } from '@/lib/helpers';

const useStyles = createUseStyles((theme: Theme) => ({
  action: {
    '& .MuiPaginationItem-root.Mui-selected': {},
  },
  gridPagination: {
    '& .MuiToolbar-root': {
      paddingLeft: 30,
    },
    '& .MuiTablePagination-input': {
      marginRight: 0,
      '& .MuiTablePagination-select': {
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: theme.shape.borderRadius,
      },
    },
  },
  refreshButton: {
    marginTop: 5,
  },
}));

interface PaginationProps {
  page: number;
  onPageChange: (event: ChangeEvent, pageNumber: number) => void;
  className: string;
}

const PaginationAction: FC<PaginationProps> = ({ page, onPageChange, className = '' }) => {
  const styles = useStyles();
  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const compClass = classNames(className, styles.action);

  return (
    <MuiPagination
      color="standard"
      className={compClass}
      count={Math.ceil(rowCount / pageSize)}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as ChangeEvent<Element>, newPage - 1);
      }}
    />
  );
};

// @todo type this
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Pagination: FC = (props: any) => {
  const styles = useStyles();
  const { page, count } = props as { page: number; count: number };

  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const handleRefresh = () => {
    (apiRef.current as DtGridApiCommunity).refresh();
  };

  const start = page * pageSize + 1;
  const end = Math.min(start + pageSize - 1, rowCount);
  const hasRows = Boolean(count);

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%">
      <Box flexGrow={1} marginLeft={1}>
        <Icon
          color="active"
          className={styles.refreshButton}
          name="refresh"
          onClick={handleRefresh}
          spinOnClick
        />
      </Box>
      {hasRows && (
        <>
          <Box>
            {getNiceNumber(start)} - {getNiceNumber(end)} of {getNiceNumber(rowCount)}
          </Box>
          <GridPagination
            ActionsComponent={PaginationAction}
            {...props}
            className={styles.gridPagination}
            labelRowsPerPage="Page Size:"
          />
        </>
      )}
    </Stack>
  );
};

export default Pagination;
