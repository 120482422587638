import { Box } from '@mui/material';
import { FC, ReactNode, useEffect, useRef } from 'react';

interface Props {
  children: ReactNode;
  onResize: (entries: ResizeObserverEntry[]) => void;
  className?: string;
}

const ResizeBox: FC<Props> = ({ children, onResize, className = '' }) => {
  const ref = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    if (!ref.current || observer.current) {
      return;
    }

    observer.current = new ResizeObserver(onResize);
    observer.current.observe(ref.current);

    return () => {
      observer.current?.disconnect();
      observer.current = null;
    };
  }, [onResize]);

  return (
    <Box className={className} ref={ref}>
      {children}
    </Box>
  );
};

export default ResizeBox;
