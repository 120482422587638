import { Box } from '@mui/material';
import { CSSProperties, FC, ReactNode } from 'react';
import Checkbox from '../checkbox';
import { createUseStyles } from 'react-jss';

interface Props {
  value: string;
  checked: boolean;
  label: ReactNode;
  labelChecks?: boolean;
  disabled?: boolean;
  onChange: (values: string[]) => void;
  style: CSSProperties;
}

const useStyles = createUseStyles({
  listToggleItem: {
    padding: '0px 16px',
  },
});

const ListToggleItem: FC<Props> = ({
  value,
  checked,
  label,
  labelChecks,
  disabled,
  onChange,
  style,
}) => {
  const labelId = `transfer-list-item-${value}-label`;
  const styles = useStyles();

  return (
    <Box key={value} style={style} width="100%" className={styles.listToggleItem}>
      <Checkbox
        name={labelId}
        checked={checked}
        label={label}
        onChange={() => onChange([value])}
        labelChecks={labelChecks}
        disabled={disabled}
      />
    </Box>
  );
};

export default ListToggleItem;
