import { ReactNode, FC } from 'react';
import { JsonObject } from '@/lib/helpers';
import EventEmitter from 'eventemitter3';
import { EmitterContext, EmitterEventType, EmitterListener } from './helpers';

interface Props {
  children: ReactNode;
}

const EmitterBus = new EventEmitter();
const listeners: EmitterListener[] = [];

export const EmitterProvider: FC<Props> = ({ children }) => {
  const subscribe = (type: EmitterEventType, listenerFn: (data?: JsonObject) => void) => {
    const exists = listeners.some((existingListener) => existingListener === listenerFn);
    if (exists) {
      return;
    }

    EmitterBus.on(type, listenerFn);
  };

  const unsubscribe = (type: EmitterEventType, listenerFn: (data?: JsonObject) => void) => {
    const listenerIndex = listeners.findIndex(
      (existingListener) => existingListener === listenerFn
    );
    if (listenerIndex > -1) {
      listeners.splice(listenerIndex, 1);
    }

    EmitterBus.removeListener(type, listenerFn);
  };

  const emit = (type: EmitterEventType, data?: JsonObject) => {
    EmitterBus.emit(type, data);
  };

  return (
    <EmitterContext.Provider value={{ emit, subscribe, unsubscribe }}>
      {children}
    </EmitterContext.Provider>
  );
};
