import { FC } from 'react';
import { Box } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel: FC<Props> = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </Box>
  );
};

export default TabPanel;
