import { Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import Text from '../text';
import Card from '../card';
import { CARD_SIZE } from './helpers';

interface Props {
  title?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  emptyCard: {
    width: CARD_SIZE.width,
    height: CARD_SIZE.height,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    backgroundColor: theme.palette.grey[300],
  },
}));

const EmptyCard: FC<Props> = ({ title = 'No records found' }) => {
  const styles = useStyles();

  return (
    <Card className={styles.emptyCard}>
      <Text color="grey">{title}</Text>
    </Card>
  );
};

export default EmptyCard;
