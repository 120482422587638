import { FormHandle } from '@/hooks/use-form-handle.hook';
import { v4 as uuidv4 } from 'uuid';
import { FC, useMemo } from 'react';
import InputControl from '@/components/form/input-control';
import { AWS_REGION_OPTS, ViewState } from '@/lib/helpers';
import { Box, Link, Stack } from '@mui/material';
import ExternalLink from '@/components/external-link';
import ConnectorStepCard from './connector-step-card';
import { AwsConnectorModel } from '@/lib/models/connector/aws-connector.model';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import ConnectorStepHeader from './connector-step-header';

interface Props {
  connector: AwsConnectorModel;
  formHandle: FormHandle<ConnectorModel>;
  mode: ViewState;
}

const AwsConnector: FC<Props> = ({ formHandle, connector, mode }) => {
  const stackLink = useMemo(
    () =>
      `https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=https%3A%2F%2Fpublic-surepath-cf.s3.us-east-2.amazonaws.com%2Ftemplates%2Fsurepath_aws_connector.template&stackName=surepath-connector&param_BucketSuffix=${uuidv4()}`,
    []
  );

  const isNew = !connector.id;
  const inWriteMode = ['edit', 'add'].includes(mode);
  const stackComplete = connector.status === 'active';
  const { setFieldValue } = formHandle;

  const handleChangeAccountId = (value: string) => {
    if (!isNew || !value) {
      return;
    }

    setFieldValue('config.crossAccountRoleARN', AwsConnectorModel.getDefaultRoleARN(value));
  };

  return (
    <>
      {inWriteMode && (
        <Box>
          <InputControl
            name="config.awsAccountId"
            label="AWS Account ID"
            formHandle={formHandle}
            onChange={handleChangeAccountId}
          />

          <InputControl
            name="config.awsRegion"
            label="AWS Region"
            formHandle={formHandle}
            type="select"
            options={AWS_REGION_OPTS}
          />

          <InputControl
            name="config.crossAccountRoleARN"
            label="SurePath Role ARN"
            formHandle={formHandle}
            readonly={isNew}
          />
        </Box>
      )}

      <Box mt={2}>
        <ConnectorStepHeader title="Required External Setup" />
        <ConnectorStepCard title="Configure CloudFormation Stack" completed={stackComplete}>
          Please configure your AWS environment using the AWS CloudFormation stack below. Once
          complete, return to this page and verify the SurePath Role ARN of the role created by the
          stack.
          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
            <Box>
              <Link href={stackLink} target="_blank">
                Launch a Cloudformation Stack
              </Link>
            </Box>
            <Box>
              <ExternalLink
                href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_use_passrole.html"
                label="Learn more"
              />
            </Box>
          </Stack>
        </ConnectorStepCard>
      </Box>
    </>
  );
};

export default AwsConnector;
