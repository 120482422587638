import { ConnectorModel, CreateConnectorModel } from '../models/connector/connector.model';
import { Dataset, DtFilter, DtSort } from '@/components/data-table/helpers';
import { CreateResponse, DeleteResponse, PatchResponse, SAFE_LIMIT } from '.';
import { create, deleteApi, get, patch } from './sp-api.service';
import { downloadFile, JsonObject } from '../helpers';
import { SharepointConnectorModel } from '../models/connector/sharepoint-connector.model';
import { getArrayParam } from '../url-helpers';
import { orderBy } from 'lodash';
import { ContextProviderModel } from '../models/context-provider/context-provider.model';
import { PrivateModelModel } from '../models/private-model.model';
import { ConnectorType, getTypedConnector } from '../models/connector';
import { activateRag, getRagStatus, RagStatus } from './context-provider.service';

export const getConnectors = async (
  page: number,
  pageSize: number,
  dtSort?: DtSort,
  dtFilter?: DtFilter
): Promise<Dataset<ConnectorModel>> => {
  const response = await get('/connector-controller/connector');
  let rows = ((response as JsonObject[]) || []).map((data) => new ConnectorModel(data));

  // Filter
  if (dtFilter) {
    const { keyword } = dtFilter;
    if (keyword) {
      const safeKeyword = String(keyword).trim().toLowerCase();
      rows = rows.filter(({ name }) => String(name).toLowerCase().includes(safeKeyword));
    }
  }

  // Sort
  if (dtSort) {
    const { columnName, direction } = dtSort;
    rows = orderBy(rows, [columnName], [direction]);
  }

  // Paginate
  const startIndex = page * pageSize;
  const total = rows?.length || 0;
  rows = rows.slice(startIndex, startIndex + pageSize);

  return {
    page,
    pageSize,
    rows,
    total,
  };
};

export const getAllConnectors = async (): Promise<Dataset<ConnectorModel>> =>
  getConnectors(0, SAFE_LIMIT);

export const getContextProviderConnectors = async (): Promise<ConnectorModel[]> => {
  const { rows: connectors } = await getAllConnectors();

  return connectors.map(getTypedConnector).filter((connector) => {
    const { supportedFeatures, contextProviderTypes } = connector;
    return supportedFeatures.contextData && !!contextProviderTypes.length;
  });
};

export const getPrivateModelConnectors = async (): Promise<ConnectorModel[]> => {
  const { rows: connectors } = await getAllConnectors();

  return connectors.map(getTypedConnector).filter((connector) => {
    const { supportedFeatures } = connector;
    return supportedFeatures.privateModels;
  });
};

export const getConnectorsByType = async (
  connectorType: ConnectorType
): Promise<ConnectorModel[]> => {
  const connectors = await getAllConnectors();
  return connectors.rows.filter(({ type }) => type === connectorType);
};

export const getConnectorById = async (connectorId: string): Promise<ConnectorModel | null> => {
  const response = await get(`/connector-controller/connector/${connectorId}`);
  return response ? new ConnectorModel(response as JsonObject) : null;
};

export const getConnectorsByIds = async (connectorIds: string[]): Promise<ConnectorModel[]> => {
  if (!connectorIds.length) {
    return [];
  }

  const params = getArrayParam('connectorId', connectorIds);
  const response = await get('/connector-controller/connector', params);
  return response ? (response as JsonObject[]).map((data) => new ConnectorModel(data)) : [];
};

export const getAwsBedrockConnector = async (): Promise<ConnectorModel | null> => {
  const connectors = await getAllConnectors();

  const awsConnector = connectors.rows.find(
    ({ type, status, supportedFeatures }) =>
      type === 'aws' && status === 'active' && supportedFeatures.privateModels
  );

  return awsConnector || null;
};

export const getConnectorUsage = async (
  connectorId: string
): Promise<[ContextProviderModel[], PrivateModelModel[]]> => {
  const response = await get(`/connector-controller/connector/usage/${connectorId}`).catch(() => {
    // some connector types are not supported, causing the service to respond with a 500
    return null;
  });

  if (!response) {
    return [[], []];
  }

  const { contextProviders, privateModels } = response as {
    contextProviders: JsonObject[];
    privateModels: JsonObject[];
  };

  return [
    contextProviders.map((data) => new ContextProviderModel(data)),
    privateModels.map((data) => new PrivateModelModel(data)),
  ];
};

export const createConnector = async (model: ConnectorModel): Promise<CreateResponse> => {
  const createModel = new CreateConnectorModel(model);
  const response = await create(
    `/connector-controller/connector/${model.type}`,
    createModel._props
  );

  if (!response.created) {
    return response;
  }

  // connector has contextData and org doesn't have rag? turn it on now
  const connector = new ConnectorModel(response.data);

  if (connector.hasContextData) {
    const ragStatus = await getRagStatus();
    if (ragStatus === 'inactive') {
      await activateRag();
    }
  }

  return { created: true, model: connector };
};

export const patchConnector = async (model: ConnectorModel): Promise<PatchResponse> => {
  const createModel = new CreateConnectorModel(model);
  return patch(`/connector-controller/connector/${model.type}/${model.id}`, createModel._props);
};

export const deleteConnector = async (model: ConnectorModel): Promise<DeleteResponse> => {
  return deleteApi(`/connector-controller/connector/${model.type}/${model.id}`);
};

export const downloadSharepointCert = (connector: SharepointConnectorModel) => {
  const { publicCert } = connector.config;

  if (!publicCert) {
    return;
  }

  const blob = new Blob([atob(publicCert)], { type: 'application/x-pem-file' });

  return downloadFile(blob, 'surepath-public-cert.crt');
};

// If the kendra index for an organization is not yet available, "artificially" mark contextData connectors as pending
export const maskConnectorStatus = (
  connectors: ConnectorModel[],
  ragStatus: RagStatus
): ConnectorModel[] => {
  const ragEnabled = ragStatus === 'active';

  return connectors.map((connector) => {
    const { status, supportedFeatures } = connector;

    if (!ragEnabled && supportedFeatures.contextData && status === 'active') {
      connector.status = 'pending';
      switch (ragStatus) {
        case 'inactive':
          connector.error = 'Organization-wide RAG is not yet active';
          break;
        case 'pending':
          connector.error = 'Organization-wide RAG is pending activation';
          break;
        case 'failed':
          connector.error = 'Organization-wide RAG service is in a fail state';
          break;
      }
    }

    return connector;
  });
};
