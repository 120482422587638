import {
  FC,
  ForwardedRef,
  Fragment,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import debounce from 'lodash/debounce';
import {
  DataGrid,
  GridPaginationModel,
  GridRowClassNameParams,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import {
  Dataset,
  DtColumn,
  DtFilter,
  DtFilterValue,
  DtPage,
  DtSort,
  getNiceRowsPerPage,
  makeDtSort,
  makeMuiDataGridColumns,
  makeMuiRows,
  makeMuiSort,
} from './helpers';
import Pagination from './pagination';
import { Box, Stack, Theme } from '@mui/material';
import { QueryKey, useQuery } from 'react-query';
import Text from '../text';
import usePrior from '../../hooks/use-prior.hook';
import isEqual from 'lodash/isEqual';
import TextInput from '../form/text-input';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import FilterMenu from './filter-menu';
import { ListItem } from '../form/list-editor';
import { useInterval } from 'usehooks-ts';
import Panel from '../panel';
import PanelTitle from '../panel/panel-title';
import PanelContent from '../panel/panel-content';
import { cloneDeep } from 'lodash';
import useQueryHelper from '@/hooks/use-query-helper';
import Loader from '../loader';
import useDomSize from '@/hooks/use-dom-size.hook';

interface Props {
  title?: string;
  columns: DtColumn[];
  queryKey: QueryKey;
  rowHeight?: number;
  search?: boolean;
  searchPlaceholder?: string;
  selectedRowId?: number | string;
  rowsPerPage?: number[];
  onLoad: (
    page: number,
    pageSize: number,
    sort?: DtSort,
    filters?: DtFilter
  ) => Promise<Dataset<Record<string, ReactNode>>>;
  onRowClick?: (rowId: string) => void;
  onChangeFilter?: (filterValues: DtFilterValue[]) => DtFilterValue[];
  ref?: ForwardedRef<HTMLDivElement>;
  filter?: DtFilter;
  sort?: DtSort;
  poll?: number;
}

const useStyles = createUseStyles<string, { canRowClick: boolean; rowsHeight: number }>(
  (theme: Theme) => ({
    dataTable: {
      boxSizing: 'border-box',
      '& .MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-row': {
        cursor: ({ canRowClick }) => (canRowClick ? 'pointer' : 'default'),
        '&.selected': {
          backgroundColor: theme.palette.grey[100],
        },
      },
      '& .MuiDataGrid-virtualScroller': {
        height: ({ rowsHeight }) => rowsHeight,
      },
      '& .MuiDataGrid-columnHeaders': {
        border: 'none',
      },
      '& .MuiDataGrid-columnHeader': {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        '&.MuiDataGrid-columnHeaderTitle': {
          fontWeight: theme.palette.grey[600],
        },
        '&.MuiDataGrid-withBorderColor': {
          borderColor: theme.palette.grey[200],
          outline: 'none',
        },
      },
      '& .MuiDataGrid-cell': {
        borderColor: theme.palette.grey[200],
        whiteSpace: 'normal !important',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiTablePagination-displayedRows': {
        display: 'none',
      },
      pagination: {
        display: 'flex',
      },
    },
    toolbar: {
      height: 40,
    },
    cell: {
      outline: 'none !important',
    },
    row: {},
    panelContent: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  })
);

const NoRowsOverlay: FC = () => {
  return (
    <Box display="flex" padding={1} pt={2}>
      No results found.
    </Box>
  );
};

const NoResultsOverlay: FC = () => {
  return (
    <Box display="flex" padding={1} pt={2}>
      There were no results found that match your search criteria.
    </Box>
  );
};

const LoadingOverlay: FC = () => {
  return (
    <Box height="100%" width="100%" display="flex" alignItems="flex-start" pt={1}>
      <Loader />
    </Box>
  );
};

export interface DataTableInstance {
  refresh: () => void;
}

export type DtGridApiCommunity = GridApiCommunity & DataTableInstance;

const DataTable = forwardRef<DataTableInstance, Props>(
  (
    {
      title = '',
      columns,
      filter: propFilter,
      onLoad,
      onRowClick,
      onChangeFilter,
      queryKey,
      rowHeight = 50,
      rowsPerPage: propRowsPerPage = [10, 50, 100],
      search = false,
      searchPlaceholder = '',
      selectedRowId,
      poll = 0,
      sort: propSort,
    },
    ref
  ) => {
    const { getSize } = useDomSize();
    const [pageParams, setPageParams] = useState<DtPage>({
      page: 0,
      pageSize: propRowsPerPage[0],
      sort: propSort,
    });

    const [rowsPerPage, setRowsPerPage] = useState<number[]>(propRowsPerPage);
    const [measured, setMeasured] = useState(false);

    const [filter, setFilter] = useState<DtFilter>(
      propFilter || { keyword: '', fields: [], values: [] }
    );
    const [keywordVal, setKeywordVal] = useState('');

    const apiRef = useGridApiRef();

    const query = useQuery(
      [queryKey],
      () => {
        const { page, pageSize, sort } = pageParams;
        return onLoad(page, pageSize, sort, filter);
      },
      { enabled: measured }
    );
    const { data, refetch } = query;
    const { showLoader } = useQueryHelper(query);

    const { rows = [], total = 0 } = data || {};

    const styles = useStyles({
      canRowClick: !!onRowClick,
      rowsHeight: rowHeight * Math.max(rows.length, 1),
    });

    const muiColumns = useMemo(() => makeMuiDataGridColumns(columns), [columns]);
    const muiRows = useMemo(() => makeMuiRows(rows, columns), [rows, columns]);

    // make refetch available to child components
    (apiRef.current as DtGridApiCommunity).refresh = () => {
      refetch();
    };

    // make refetch available to sibling and parent components
    useImperativeHandle(ref, () => {
      return {
        refresh: () => {
          refetch();
        },
      };
    }, [refetch]);

    const handlePagination = useCallback((model: GridPaginationModel) => {
      const { page, pageSize } = model;

      setPageParams((oldParams) => {
        return {
          ...oldParams,
          page,
          pageSize,
        };
      });
    }, []);

    const handleSort = (model: GridSortModel) => {
      let updatedSort: DtSort | null = null;

      if (!model?.length) {
        // when you change the sort direction on the same column, it cycles betwee 'asc' | 'desc' | null
        // so the third time you click the button, model = [] ???
        const { sort } = pageParams;
        if (sort?.columnName) {
          updatedSort = { columnName: sort?.columnName, direction: 'asc' };
        }
      } else {
        updatedSort = makeDtSort(model);
      }

      if (!updatedSort) {
        return;
      }

      setPageParams((old) => ({
        ...old,
        page: 0,
        sort: updatedSort!,
      }));
    };

    const handleChangeKeyword = useCallback((keyword: string) => {
      const safeKeyword = String(keyword).trim();

      if (safeKeyword.length && safeKeyword.length < 3) {
        return;
      }

      setFilter((old) => ({
        ...old,
        keyword: safeKeyword,
      }));

      setPageParams((old) => ({
        ...old,
        page: 0,
      }));
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceHandleChangeKeyword = useCallback(
      debounce((keyword: string) => {
        handleChangeKeyword(keyword);
      }, 600),
      [handleChangeKeyword]
    );

    const handleChangeFilter = (item: ListItem, editIndex: number) => {
      let updatedValues = cloneDeep(filter.values);
      const { meta, value } = item;
      const { columnName } = meta as DtFilterValue;
      const filterValue = { columnName, value };

      if (editIndex === -1) {
        updatedValues.push(filterValue);
      } else {
        updatedValues[editIndex] = filterValue;
      }

      if (onChangeFilter) {
        updatedValues = onChangeFilter(updatedValues);
      }

      setFilter((old) => ({
        ...old,
        values: updatedValues,
      }));

      setPageParams((old) => ({
        ...old,
        page: 0,
      }));
    };

    const handleRemoveFilter = (_item: ListItem, editIndex: number) => {
      const updatedValues = [...filter.values];
      updatedValues.splice(editIndex, 1);
      setFilter((old) => ({
        ...old,
        values: updatedValues,
      }));

      setPageParams((old) => ({
        ...old,
        page: 0,
      }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleGetRowClassName = ({ id }: GridRowClassNameParams<any>) => {
      if (selectedRowId === id) {
        return 'selected';
      }
      return '';
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleRowClick = (params: GridRowClassNameParams<any>) => {
      onRowClick && onRowClick(params.id as string);
    };

    // pagination params changed? reload
    const priorPageParams = usePrior(pageParams);
    useEffect(() => {
      if (priorPageParams && !isEqual(pageParams, priorPageParams)) {
        refetch();
      }
    }, [pageParams, priorPageParams, refetch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceRefetch = useCallback(
      debounce(() => {
        refetch();
      }, 200),
      [refetch]
    );

    useEffect(() => {
      debounceHandleChangeKeyword(keywordVal);
    }, [keywordVal, debounceHandleChangeKeyword]);

    // filters changed? reload
    const priorFilter = usePrior(filter);
    useEffect(() => {
      if (priorFilter && !isEqual(filter, priorFilter)) {
        debounceRefetch();
      }
    }, [filter, priorFilter, debounceRefetch]);

    useEffect(() => {
      if (measured) {
        return;
      }

      const { height: availableHeight } = getSize();
      const rowsPerPage = getNiceRowsPerPage(rowHeight, propRowsPerPage, availableHeight);

      setPageParams((old) => ({
        ...old,
        pageSize: rowsPerPage[0],
      }));

      setRowsPerPage(rowsPerPage);

      setTimeout(() => {
        setMeasured(true);
      }, 0);
    }, [measured, getSize, rowHeight, propRowsPerPage]);

    // @todo need to "reset" the polling mechanism at the moment another refetch is triggered
    useInterval(() => {
      if (!poll) {
        return null;
      }

      refetch();
    }, poll);

    const hasData = !!data;
    const hasFilters = Boolean(filter.fields.length);
    const { page, sort } = pageParams;

    return (
      <Box ref={ref}>
        <Panel>
          <PanelTitle>
            <Stack width="100%" direction="row" className={styles.toolbar}>
              <Box width="50%">
                <Text size="large">{title}</Text>
              </Box>
              <Stack direction="row" justifyContent="flex-end" width="50%" gap={1}>
                {hasFilters && (
                  <FilterMenu
                    filter={filter}
                    onChange={handleChangeFilter}
                    onRemove={handleRemoveFilter}
                  />
                )}
                {search && (
                  <Box width="60%">
                    <TextInput
                      placeholder={searchPlaceholder}
                      name="keyword"
                      value={keywordVal}
                      onChange={setKeywordVal}
                      size="small"
                      clearable
                      startIcon="search"
                    />
                  </Box>
                )}
              </Stack>
            </Stack>
          </PanelTitle>
          <PanelContent className={styles.panelContent}>
            <Box className={styles.dataTable}>
              <DataGrid
                apiRef={apiRef}
                rows={muiRows}
                columns={muiColumns}
                getRowClassName={handleGetRowClassName}
                rowHeight={rowHeight}
                onSortModelChange={handleSort}
                onPaginationModelChange={handlePagination}
                onRowClick={handleRowClick}
                pageSizeOptions={rowsPerPage}
                pagination
                slots={{
                  pagination: Pagination,
                  noRowsOverlay: hasData ? NoRowsOverlay : Fragment,
                  noResultsOverlay: NoResultsOverlay,
                  loadingOverlay: LoadingOverlay,
                }}
                slotProps={{
                  pagination: { page, count: total },
                  row: { className: styles.row },
                  cell: { className: styles.cell },
                }}
                paginationMode="server"
                sortingMode="server"
                paginationModel={pageParams}
                sortModel={sort ? [makeMuiSort(sort)] : undefined}
                rowCount={total}
                checkboxSelection={false}
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
                loading={showLoader}
              />
            </Box>
          </PanelContent>
        </Panel>
      </Box>
    );
  }
);

export default DataTable;
