import { RouterProvider as TSRouteProvider } from '@tanstack/react-router';
import { FC } from 'react';
import router from './';
import useApp from '../hooks/use-app.hook';
import ErrorPage from '@/pages/error';
import PageLoader from '@/components/page-loader';

const RouteProvider: FC = () => {
  const { loadState } = useApp();

  if (loadState !== 'loaded') {
    return <PageLoader />;
  }

  return <TSRouteProvider router={router} defaultErrorComponent={ErrorPage} />;
};

export default RouteProvider;
