import classNames from 'classnames';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import RmDrawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { UseQueryResult } from 'react-query';
import Loader from '../loader';
import { Box, useTheme } from '@mui/material';
import useQueryHelper from '../../hooks/use-query-helper';
import { DRAWER_ANIMATE_DURATION } from './helpers';

export interface DrawerProps {
  open: boolean;
  onClose: () => void;
  onChange?: () => void;
  className?: string;
  query?: UseQueryResult;
  loading?: boolean;
  hideToolbar?: boolean;
}

interface Props extends DrawerProps {
  children: React.ReactNode;
}

const useStyles = createUseStyles({
  drawer: {
    position: 'relative',
    padding: '20px 0px 20px 0px',
    boxSizing: 'border-box',
  },
  inner: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
});

const Drawer: FC<Props> = ({ children, open, className = '', onClose, query, loading = false }) => {
  const styles = useStyles();
  const compClass = classNames(styles.drawer, className);
  const theme = useTheme();

  const { showLoader } = useQueryHelper(query, loading);
  const compShowLoader = open && showLoader;

  return (
    <RmDrawer
      direction="right"
      className={compClass}
      open={open}
      overlayOpacity={0}
      duration={DRAWER_ANIMATE_DURATION}
      size={550}
      zIndex={theme.zIndex.drawer}
      onClose={onClose}
    >
      <Box className={styles.inner}>
        {compShowLoader && <Loader />}
        {!compShowLoader && children}
      </Box>
    </RmDrawer>
  );
};

export default Drawer;
