import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC } from 'react';
import InputControl from '@/components/form/input-control';
import { Box } from '@mui/material';
import Button from '@/components/button';
import { downloadSharepointCert } from '@/lib/services/connector.service';
import { ViewState } from '@/lib/helpers';
import Tooltip from '@/components/tooltip';
import ConnectorStepCard from './connector-step-card';
import ConnectorStepHeader from './connector-step-header';
import { SharepointConnectorModel } from '@/lib/models/connector/sharepoint-connector.model';
import { ConnectorModel } from '@/lib/models/connector/connector.model';

interface Props {
  connector: SharepointConnectorModel;
  formHandle: FormHandle<ConnectorModel>;
  mode: ViewState;
}

const SharepointConnector: FC<Props> = ({ formHandle, mode, connector }) => {
  const isNew = !connector.id;
  const hasCert = !!connector.config.publicCert;

  const handleDownload = () => {
    if (!hasCert) {
      return;
    }
    downloadSharepointCert(connector);
  };

  const inWriteMode = ['edit', 'add'].includes(mode);

  return (
    <>
      {inWriteMode && (
        <Box>
          <InputControl
            name="config.sharepointSite"
            label="SharePoint Base URL"
            formHandle={formHandle}
          />
          <InputControl
            name="config.tenantId"
            label="SharePoint Tenant Id"
            formHandle={formHandle}
          />

          <InputControl
            name="config.clientId"
            label="SharePoint Client Id"
            formHandle={formHandle}
          />
        </Box>
      )}
      {!isNew && (
        <>
          <ConnectorStepHeader title="Required External Setup" />
          <Box mt={2}>
            <ConnectorStepCard title="Download Public Certificate">
              <Box mt={2}>
                After the SharePoint connector is created, download and install the SurePath public
                certificate.
              </Box>

              <Box mt={2}>
                <Tooltip
                  disabled={hasCert}
                  title="The SurePath public certificate has not yet been generated"
                >
                  <Button
                    label="Download Certificate"
                    icon="download"
                    onClick={handleDownload}
                    disabled={!hasCert}
                  />
                </Tooltip>
              </Box>
            </ConnectorStepCard>
          </Box>
        </>
      )}
    </>
  );
};

export default SharepointConnector;
