import { FC, useRef } from 'react';
import Page, { PageProps } from '@/components/page';
import { Box } from '@mui/material';
import { DtColumn, DtSort } from '@/components/data-table/helpers';
import Icon from '@/components/icon';
import PageHeader, { PageBlurb } from '@/components/page/header';
import DataTable, { DataTableInstance } from '@/components/data-table';
import { useNavigate } from '@tanstack/react-router';
import Text from '@/components/text';
import { createGroupPolicy, getGroupPolicies } from '@/lib/services/policy.service';
import useModal from '@/hooks/use-modal.hook';
import { PolicyModel } from '@/lib/models/policy.model';
import useToast from '@/hooks/use-toast.hook';
import Button from '@/components/button';
import { QueryKey } from '@/lib/query-client';
import DomSize from '@/components/dom-size';
import useFeature from '@/hooks/use-feature';
import Tooltip from '@/components/tooltip';
import PageFeatureToast from '@/components/page-feature-toast';

const columns: DtColumn[] = [
  { name: 'name', label: 'Name', flex: 2, sortable: true },
  { name: 'action', label: '', sortable: false },
];

const PoliciesPage: FC<PageProps> = () => {
  const dtRef = useRef<DataTableInstance>(null);
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { errorToast } = useToast();
  const { canChangePolicy, getTooltip } = useFeature();

  const handleView = (id: string) => {
    navigate({ to: '/config/group-policies/$policyId', params: { policyId: id } });
  };

  const handleAdd = () => {
    openModal('new-with-name', {
      title: 'Create New Group Policy',
      content: 'Please enter a name for the policy',
      onSubmit: ({ name }: { name: string }) => {
        createGroupPolicy(new PolicyModel({ name })).then((newPolicyId) => {
          closeModal();
          if (newPolicyId) {
            navigate({ to: '/config/group-policies/$policyId', params: { policyId: newPolicyId } });
            return;
          }

          errorToast('The group policy was not created');
        });
      },
    });
  };

  const loadData = async (page: number, pageSize: number, sort?: DtSort) => {
    const dataset = await getGroupPolicies(page, pageSize, sort);

    return {
      ...dataset,
      rows: dataset.rows.map(({ id, name, description }) => {
        const hasDescription = !!description;
        return {
          id,
          name: (
            <Box maxWidth="100%">
              <Box>{name}</Box>
              {hasDescription && (
                <Box pt={1}>
                  <Text color="grey" size="small" dotdot>
                    {description}
                  </Text>
                </Box>
              )}
            </Box>
          ),
          action: (
            <Box width="100%" display="flex" justifyContent="flex-end">
              <Icon name="arrow-right" onClick={() => handleView(id)} />
            </Box>
          ),
        };
      }),
    };
  };

  return (
    <Page title="Group Policies">
      <PageHeader>
        <PageBlurb>
          Create new group policies, and view and change permissions for existing policies.
        </PageBlurb>
        <Tooltip title={getTooltip('add-policy')} disabled={canChangePolicy}>
          <Button
            icon="plus"
            label="Add Group Policy"
            size="small"
            onClick={handleAdd}
            disabled={!canChangePolicy}
          />
        </Tooltip>
      </PageHeader>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />
      <DomSize>
        <DataTable
          queryKey={QueryKey.GroupPoliciesDataset}
          columns={columns}
          onLoad={loadData}
          ref={dtRef}
          search={false}
          sort={{ columnName: 'name', direction: 'asc' }}
          rowHeight={70}
        />
      </DomSize>
    </Page>
  );
};

export default PoliciesPage;
