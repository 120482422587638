import { FC, useEffect, useState } from 'react';
import InputControl from '@/components/form/input-control';

import { Box, Grid, Stack } from '@mui/material';
import FormSection from '@/components/form/form-section';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { useQuery } from 'react-query';
import useQueryHelper from '@/hooks/use-query-helper';
import Text from '@/components/text';
import { getNiceDate, LoadState } from '@/lib/helpers';
import CopyClipButton from '@/components/copy-clip-button';
import Button from '@/components/button';
import { IdentProviderModel } from '@/lib/models/ident-provider.model';
import { getOrg } from '@/lib/services/org.service';
import { connectWorkOS } from '@/lib/services/workos.service';
import { OrgModel } from '@/lib/models/org.model';
import Tooltip from '@/components/tooltip';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import InlineToast from '@/components/toasts/inline';
import { openNewTab } from '@/lib/url-helpers';
import useToast from '@/hooks/use-toast.hook';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import { getAuthCallbackUrl, getSamlEntityId } from '@/lib/services/auth.service';

const fields = ['enabled', 'lastUpdated', 'identityProviderConfig'];
const formModel = new IdentProviderModel();

const AuthForm: FC = () => {
  const [loadState, setLoadState] = useState<LoadState>('unloaded');
  const { errorToast } = useToast();

  const { canChangeAuth, canChangeSync, getTooltip } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(false);
      resetForm({ values });
    },
  });

  const handleConnectWorkOS = async (org: OrgModel) => {
    if (loadState !== 'unloaded') {
      return;
    }
    setLoadState('loading');

    const { name, domains } = org;
    const workOSLink = await connectWorkOS({ name, domains });

    setLoadState('unloaded');

    if (!workOSLink) {
      errorToast('Unable to connect to sync service');
      return;
    }

    openNewTab(workOSLink);
  };

  const { values, loadData } = formHandle;

  const query = useQuery([QueryKey.OrgView], async () => getOrg());

  const { data } = query;
  const { showLoader } = useQueryHelper(query);

  const hasCallbackUrl = !!values.identityProviderConfig?.authCallbackURIs.length;
  const callbackUrls = hasCallbackUrl
    ? values.identityProviderConfig?.authCallbackURIs
    : [getAuthCallbackUrl()];

  const organizationConnected = !!data?.externalReferences?.workos?.workosOrganizationId;
  const spEntityId = getSamlEntityId(data?.identityConfig?.gcipTenantId || '<tenant-id>');

  useEffect(() => {
    if (data) {
      const ssoProvider = data.identityConfig.providers.find(({ type }) => type === 'OIDC');

      if (ssoProvider) {
        loadData(ssoProvider);
      }
    }
  }, [data, loadData]);

  if (showLoader) {
    return null;
  }

  const readonly = !canChangeAuth;

  return (
    <Box>
      <PageFeatureToast featureId="change-auth" can={canChangeAuth} />
      <Form formHandle={formHandle}>
        <FormSection title="Single Sign-On">
          <>
            <Grid container marginBottom={2}>
              <Grid item xs={12}>
                Authentication and access control system that allows users to securely access
                multiple applications and services with a single set of credentials.
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputControl name="name" label="Provider Name" formHandle={formHandle} disabled />
              </Grid>
              <Grid item xs={6}>
                <InputControl name="type" label="Provider Type" formHandle={formHandle} disabled />
              </Grid>

              <Grid item xs={6}>
                <InputControl
                  name="identityProviderConfig.clientId"
                  label="Client Id"
                  formHandle={formHandle}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <InputControl
                  name="identityProviderConfig.issuerURL"
                  label="Issuer URL"
                  formHandle={formHandle}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <Box mb={2}>
                  <Text bold>Authorization Callback URLs</Text>
                </Box>
                <Box>
                  {callbackUrls!.map((uri) => {
                    return (
                      <Stack gap={1} flexDirection="row" key={uri} mb={2}>
                        <Text color="grey" size="small">
                          {uri}
                        </Text>
                        <CopyClipButton value={uri} size="small" />
                      </Stack>
                    );
                  })}
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box mb={2}>
                  <Text bold>Client Secret</Text>
                </Box>
                <Text color="grey" size="small">
                  Last Updated {getNiceDate(values.lastUpdated).formatted || 'n/a'}
                </Text>
              </Grid>

              <Grid item xs={6}>
                <Box mb={2}>
                  <Text bold>Service Provider Entity ID</Text>
                </Box>
                <Stack gap={1} flexDirection="row">
                  <Text color="grey" size="small">
                    {spEntityId}
                  </Text>
                  <CopyClipButton value={spEntityId} size="small" />
                </Stack>
              </Grid>
            </Grid>
            {canChangeAuth && (
              <Box mt={4}>
                <InlineToast
                  level="info"
                  message="For changes to Single Sign-On Settings, please contact support."
                />
              </Box>
            )}
          </>
        </FormSection>
        <FormSection title="Directory Sync">
          <>
            <Grid container marginBottom={2}>
              <Grid item xs={12}>
                Manage your organization's users/groups connection.
              </Grid>
            </Grid>
            <Grid container marginBottom={2} spacing={0}>
              <Grid item xs={12}>
                Status: {organizationConnected ? 'Connected' : 'Not Connected'}
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <Tooltip disabled={canChangeSync} title={getTooltip('change-sync')}>
                  <Button
                    size="large"
                    onClick={() => {
                      handleConnectWorkOS(data!);
                    }}
                    fullWidth
                    label="Manage"
                    disabled={readonly || !canChangeSync}
                    loading={loadState === 'loading'}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            {canChangeSync && (
              <Box mt={4}>
                <InlineToast
                  level="info"
                  message="Changes to users/groups or the connection can take up to two hours to take effect."
                />
              </Box>
            )}
          </>
        </FormSection>
      </Form>
    </Box>
  );
};

export default AuthForm;
