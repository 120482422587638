import { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { ListItem } from '@/components/form/list-editor';
import { DtFilterField } from '../helpers';
import { DateRangeValue, UiOption, getNiceDate, joinReactNodes } from '@/lib/helpers';
import Icon from '@/components/icon';
import Text from '@/components/text';
import Chip from '@/components/chip';

interface Props {
  item: ListItem;
}

const ViewItem: FC<Props> = ({ item }) => {
  const { meta, value, label } = item;
  const { control, options } = (meta || {}) as DtFilterField;

  let ViewEl: ReactNode = null;
  switch (control) {
    default:
      ViewEl = String(value);
      break;
    case 'date-range':
      ViewEl = (
        <Stack direction="row" alignItems="bottom" gap={0.5}>
          {joinReactNodes(
            (value as DateRangeValue).filter((v) => v).map((v) => getNiceDate(v).date),
            <Icon name="arrow-right" size="x-small" />
          )}
        </Stack>
      );
      break;
    case 'select-multi':
      {
        const opts: UiOption[] =
          options?.filter(({ value: optValue }) => (value as string[]).includes(optValue)) || [];

        ViewEl = (
          <Stack direction="row" alignItems="center" gap={0.5}>
            {opts.map(({ label, value }) => (
              <Chip key={value} label={label as string} size="small" />
            ))}
          </Stack>
        );
      }
      break;
    case 'select-search':
      ViewEl = <Chip label={(value as UiOption).label as string} size="small" />;
      break;
  }

  return (
    <Stack direction="row" gap={1}>
      <Box>
        <Text bold>{label}: </Text>
      </Box>
      <Box>{ViewEl}</Box>
    </Stack>
  );
};

export default ViewItem;
