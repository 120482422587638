import { Box } from '@mui/material';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  scrollOnLoad?: boolean;
}

const useStyles = createUseStyles({
  content: {
    overflowY: 'auto',
    padding: '0px 20px 0px 20px',
    boxSizing: 'border-box',
    flexGrow: 1,
    scrollBehavior: 'smooth',
  },
});

const DrawerContent: FC<Props> = ({ children, scrollOnLoad = false }) => {
  const styles = useStyles();

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollOnLoad || !contentRef.current) {
      return;
    }

    contentRef.current.scrollTop = contentRef.current?.scrollHeight || 0;
  }, [contentRef, scrollOnLoad]);

  return (
    <Box className={styles.content} ref={contentRef}>
      {children}
    </Box>
  );
};

export default DrawerContent;
