import { QueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { TOAST_CONFIG } from '../hooks/use-toast.hook';
import { AxiosError } from 'axios';
import { isProd } from './environment';
import { addError } from './datadog';

export enum AppDataQueryKey {
  PublicServices = 'app-data-public-services',
  OrgBasicInfo = 'app-data-org-basic-info',
  OrgRagStatus = 'org-rag-status',
}

export enum QueryKey {
  AdminUserView = 'admin-user-view',
  AdminUsersDataset = 'admin-users-dataset',
  AvailableModelsMap = 'available-models-map',
  AwsConnector = 'aws-connector',
  ConnectorsDataset = 'connectors-dataset',
  ConnectorMetaView = 'connector-meta-view',
  ConnectorProvidersList = 'connector-providers-list',
  ConnectorsTypeList = 'connectors-type-list',
  ContextProvidersDataset = 'context-providers-dataset',
  ContextProvidersList = 'context-providers-list',
  ContextProviderView = 'context-provider-view',
  ContextProviderConnectorDataset = 'context-provider-connectors-dataset',
  ContextProviderUsage = 'context-provider-usage',
  GroupPoliciesDataset = 'group-policies-dataset',
  GroupsDataset = 'groups-dataset',
  GroupsList = 'groups-list',
  GroupsNonSyncOptions = 'groups-non-sync-options',
  GroupView = 'group-view',
  GroupMetaView = 'group-meta-view',
  OrgPolicyView = 'org-policy-view',
  OrgView = 'org-view',
  PolicyView = 'policy-view',
  PolicyCompyView = 'policy-compy-view',
  PolicyMetaList = 'policy-meta-list',
  PrivateModelsDataset = 'private-models-dataset',
  PrivateModelUsage = 'private-model-usage',
  PrivateModelViewMeta = 'private-model-view-meta',
  PrivateModelsList = 'private-models-list',
  PublicServicesDataset = 'public-services-dataset',
  PublicServicesDownload = 'public-services-download',
  PublicServiceView = 'public-service-view',
  UserEventConversationHistory = 'user-event-conversation-history',
  UserEventsDataset = 'user-events-dataset',
  UserEventView = 'user-event-view',
  UserMetaView = 'user-meta-view',
  UsersDataset = 'users-dataset',
  AgentsList = 'agents-list',
  AgentView = 'agent-view',
  AgentUsage = 'agent-usage',
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 4000, // how long before cached data is marked as stale and automatically refreshed?
      cacheTime: 0, // how long before cached data is removed from cache via gc?
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: 1,
      onError: (error: AxiosError) => {
        addError(error);
        const message = isProd() ? 'There was an internal error' : error.message;
        enqueueSnackbar(message, { ...TOAST_CONFIG, variant: 'error' });
      },
    },
  },
});

export default queryClient;
