import { FC, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO, isValid } from 'date-fns';
import FormControl from '../form-control';
import { FormProps } from '../helpers';

interface Props extends FormProps {
  value: null | string | Date;
  onChange: (value: Date | null) => void;
  minDate?: string | Date;
  maxDate?: string | Date;
  size?: 'small' | 'medium';
}

const DateInput: FC<Props> = ({
  name,
  label = '',
  onChange,
  value,
  error = '',
  autoFocus = false,
  disabled = false,
  minDate = '',
  maxDate = '',
  size = 'medium',
}) => {
  const [dateIsValid, setDateIsValid] = useState(true);
  const handleChange = (value: Date) => {
    const updatedIsValid = !value || isValid(value);
    setDateIsValid(updatedIsValid);
    onChange(updatedIsValid ? value : null);
  };

  const dateVal = value && typeof value === 'string' ? parseISO(value) : value;
  const hasError = !dateIsValid || Boolean(error);

  return (
    <FormControl label="" error={hasError ? error : ''} name={name}>
      <DatePicker
        value={dateVal || null}
        name={name}
        label={label}
        onChange={handleChange}
        autoFocus={autoFocus}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        className=""
        slotProps={{ textField: { size } }}
      />
    </FormControl>
  );
};

export default DateInput;
