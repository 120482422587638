import { FC } from 'react';
import Card from '@/components/card';
import { Box, Stack } from '@mui/material';
import CardHeader from '../card/card-header';
import { PrivateModelModel } from '@/lib/models/private-model.model';
import NoneChip from '../chip/none-chip';
import PrivateModelButton from '../private-model-button';

interface Props {
  models: PrivateModelModel[];
}

const PrivateModelsCard: FC<Props> = ({ models }) => {
  const hasModels = !!models.length;

  return (
    <Card>
      <CardHeader title="Private Models" icon="select-all" />
      <Box>
        {!hasModels && <NoneChip />}
        {hasModels && (
          <Stack gap={1} mt={2}>
            {models.map((model) => {
              return <PrivateModelButton model={model} key={model.id} />;
            })}
          </Stack>
        )}
      </Box>
    </Card>
  );
};

export default PrivateModelsCard;
