import { FC } from 'react';
import Page, { PageProps } from '../components/page';
import { useNavigate } from '@tanstack/react-router';
import useApp from '@/hooks/use-app.hook';
import { Box, Stack } from '@mui/material';
import FlatButton from '@/components/flat-button';

const NotFoundPage: FC<PageProps> = () => {
  const { signedIn } = useApp();
  const navigate = useNavigate();
  const goHomeHref = signedIn ? '/user-events' : '/';

  return (
    <Page title="Page Not Found">
      <Stack height="300px" width="100%" alignItems="center" justifyContent="center">
        <Box>Disassemble? Yes, disassemble ALL OVER THE PLACE!</Box>
        <Box mt={3}>
          <FlatButton
            icon="arrow-left"
            label="Go Back"
            color="link"
            onClick={() => {
              navigate({ to: goHomeHref });
            }}
          />
        </Box>
      </Stack>
    </Page>
  );
};

export default NotFoundPage;
