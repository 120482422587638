import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getPolicyById, patchPolicy } from '@/lib/services/policy.service';
import useToast from '@/hooks/use-toast.hook';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { PolicyModel } from '@/lib/models/policy.model';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import FormButtons from '@/components/form/form-buttons';
import useQueryHelper from '@/hooks/use-query-helper';
import { getGroups } from '@/lib/services/group.service';
import { Dataset } from '@/components/data-table/helpers';
import { GroupModel } from '@/lib/models/group.model';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';

const fields = ['groups'];
const formModel = new PolicyModel();

interface Props {
  policyId: string;
}

const PolicyGroupsForm: FC<Props> = ({ policyId }) => {
  const { errorToast, toast } = useToast();
  const { canChangePolicy } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await patchPolicy(policyId, values.propSlice(fields));
      setSubmitting(false);
      if (updated) {
        resetForm({ values });
        toast('The policy was updated');
        return;
      }

      errorToast('The policy was not updated');

      setSubmitting(false);
      resetForm({ values });
    },
  });

  const policyQuery = useQuery([QueryKey.PolicyView, policyId], async () =>
    getPolicyById(policyId)
  );

  const groupsQuery = useQuery<Dataset<GroupModel>>([QueryKey.GroupsList], async () => {
    const groups = await getGroups(0, 1000, { columnName: 'name', direction: 'asc' });

    if (!groups) {
      return { rows: [], page: 0, pageSize: 0, total: 0 };
    }

    return groups;
  });

  const { data: groups } = groupsQuery;
  const { showLoader: showPolicyLoader } = useQueryHelper(policyQuery);
  const { showLoader: showGroupsLoader } = useQueryHelper(groupsQuery);

  const groupOptions = useMemo(() => {
    if (!groups?.rows) {
      return [];
    }

    return groups.rows.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [groups]);

  if (showPolicyLoader || showGroupsLoader) {
    return;
  }

  return (
    <Form formHandle={formHandle} query={policyQuery}>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />
      <FormSection title="Group Assignment">
        <InputControl
          name="groups"
          label="Groups"
          formHandle={formHandle}
          readonly={!canChangePolicy}
          type="groups-list-toggle"
          options={groupOptions}
        />
      </FormSection>

      <FormButtons formHandle={formHandle} readonly={!canChangePolicy} />
    </Form>
  );
};

export default PolicyGroupsForm;
