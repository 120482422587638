import { LinearProgress, Theme } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  className?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  loader: {
    width: '100%',
    color: theme.palette.grey[400],
  },
}));

const Loader: FC<Props> = ({ className }) => {
  const styles = useStyles();
  const compClass = classNames(styles.loader, className);
  return <LinearProgress color="inherit" className={compClass} />;
};

export default Loader;
