import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FormikValues } from 'formik';
import { FC, ReactNode, useEffect } from 'react';
import { UseQueryResult } from 'react-query';

interface Props {
  children: ReactNode;
  formHandle: FormHandle<FormikValues>;
  query?: UseQueryResult;
}

export const Form: FC<Props> = ({ formHandle, children, query }) => {
  const { handleSubmit, loadData } = formHandle;
  const { data } = query || {};

  useEffect(() => {
    if (data) {
      const formData = (Array.isArray(data) ? data[0] : data) as FormikValues;
      loadData(formData);
    }
  }, [data, loadData]);

  return <form onSubmit={handleSubmit}>{children}</form>;
};
