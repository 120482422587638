import { FC, useRef } from 'react';
import Page, { PageProps } from '../../components/page';
import { Box } from '@mui/material';
import DataTable, { DataTableInstance } from '../../components/data-table';
import PageHeader, { PageBlurb } from '../../components/page/header';
import useDrawer from '../../hooks/use-drawer';
import { AdminRoleLabels } from '../../lib/models/admin-user.model';
import Text from '../../components/text';
import Button from '../../components/button';
import { DtColumn, DtFilter, DtSort } from '@/components/data-table/helpers';
import { getAdminUsers } from '@/lib/services/admin-user.service';
import OpenDrawerButton from '@/components/drawers/open-drawer-button';
import { QueryKey } from '@/lib/query-client';
import DomSize from '@/components/dom-size';

const columns: DtColumn[] = [
  { name: 'name', label: 'Name', flex: 2, sortable: true },
  { name: 'email', label: 'Email', flex: 3, sortable: true },
  { name: 'role', label: 'Role', sortable: true },
  { name: 'action', label: '', sortable: false },
];

const AdminUsersPage: FC<PageProps> = () => {
  const { openDrawer, DrawerEl } = useDrawer('admin-user');

  const dtRef = useRef<DataTableInstance>(null);

  const handleRefresh = () => {
    dtRef.current?.refresh();
  };

  const handleAdd = () => {
    openDrawer({
      id: null,
      onChange: handleRefresh,
    });
  };

  const loadData = async (page: number, pageSize: number, sort?: DtSort, filter?: DtFilter) => {
    const dataset = await getAdminUsers(page, pageSize, sort, filter);

    return {
      ...dataset,
      rows: dataset.rows.map(({ id, name, email, role }) => {
        const handleView = () => {
          openDrawer({ id, onChange: handleRefresh });
        };

        return {
          id,
          name: <Box maxWidth="100%">{name}</Box>,
          email,
          role: <Text size="small">{AdminRoleLabels[role]}</Text>,
          action: (
            <Box width="100%" display="flex" justifyContent="flex-end">
              <OpenDrawerButton onClick={handleView} />
            </Box>
          ),
        };
      }),
    };
  };

  return (
    <Page title="Admin Users">
      <PageHeader>
        <PageBlurb>
          Create new admin users, and view and change permissions for existing users.
        </PageBlurb>
        <Button label="Add Admin User" icon="plus" size="small" onClick={handleAdd} />
      </PageHeader>
      <DomSize>
        <DataTable
          queryKey={QueryKey.AdminUsersDataset}
          columns={columns}
          onLoad={loadData}
          ref={dtRef}
          search={true}
          searchPlaceholder="Search by name or email"
          sort={{ columnName: 'name', direction: 'asc' }}
        />
      </DomSize>
      {DrawerEl}
    </Page>
  );
};

export default AdminUsersPage;
