import { trimEnd } from 'lodash';
import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import { ConnectorModel } from './connector.model';

export type WebcrawlerConfig = {
  url: string;
};

export class WebcralwerConnectorModel extends ConnectorModel {
  config: WebcrawlerConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'webcrawler';
    this.typeLabel = 'Webcrawler';
    this.iconSVG = '';
    this.icon = 'globe';

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: false,
      contextData: true,
      ingress: false,
    };

    this.config = (data.config as WebcrawlerConfig) || {
      url: '',
    };

    this.contextProviderTypes = ['surepath-kendra-webcrawler'];
  }

  get typedProps(): JsonObject {
    return {
      url: trimEnd(this.config.url, '/'),
    };
  }
}
