import { FC } from 'react';
import Icon from '../icon';

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const OpenDrawerButton: FC<Props> = ({ onClick, disabled }) => {
  return <Icon name="chevron-right" onClick={onClick} disabled={disabled} />;
};

export default OpenDrawerButton;
