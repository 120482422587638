import { AppBar, Box, Tab, Tabs as MuiTabs, Theme } from '@mui/material';
import TabPanel from './tab-panel';
import { FC, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { addUrlQueryParam, getUrlQueryParam } from '@/lib/url-helpers';
import useEmitter from '@/hooks/use-emitter';
import { JsonObject } from '@/lib/helpers';

interface Props {
  labels: string[];
  panels: ReactNode[];
  disabled?: number[];
}

const useStyles = createUseStyles((theme: Theme) => ({
  tabs: {
    '& header': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey[700],
      borderColor: theme.palette.grey[700],
      borderRadius: 5,
    },
    '& .MuiTab-root.Mui-selected': {
      borderBottomColor: theme.palette.secondary.main,
    },
    '& div[role="tabpanel"] span.MuiLinearProgress-root': {
      margin: '30px auto 0px auto',
      width: '50%',
    },
  },
}));

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const Tabs: FC<Props> = ({ labels, panels, disabled }) => {
  const styles = useStyles();
  const [tabIndex, setTabIndex] = useState(Number(getUrlQueryParam('tab')) || 0);
  const [blocked, setBlocked] = useState(false);

  const { subscribe, unsubscribe, emit } = useEmitter();

  const handleChange = useCallback(
    (_event: SyntheticEvent, tabIndex: number) => {
      if (blocked) {
        emit('tab-block');
        return;
      }
      setTabIndex(tabIndex);
      addUrlQueryParam('tab', String(tabIndex));
    },
    [blocked, emit]
  );

  const handleFormChange = useCallback((data?: JsonObject) => {
    setBlocked(!!data?.dirty);
  }, []);

  useEffect(() => {
    subscribe('form-change', handleFormChange);

    return () => {
      unsubscribe('form-change', handleFormChange);
    };
  }, [subscribe, unsubscribe, handleFormChange]);

  return (
    <Box className={styles.tabs}>
      <AppBar position="static" elevation={1}>
        <MuiTabs value={tabIndex} onChange={handleChange} textColor="inherit" variant="fullWidth">
          {labels.map((label, index) => (
            <Tab
              key={index}
              label={label}
              {...a11yProps(index)}
              disabled={disabled?.includes(index)}
            />
          ))}
        </MuiTabs>
      </AppBar>
      {panels.map((PanelComp, index) => (
        <TabPanel key={index} value={tabIndex} index={index}>
          {PanelComp}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Tabs;
