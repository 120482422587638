import { FC } from 'react';
import Icon from '../icon';
import { createUseStyles } from 'react-jss';
import { Box } from '@mui/material';

interface Props {
  href: string;
  label: string;
  withIcon?: boolean;
}

const useStyles = createUseStyles({
  externalLink: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    alignItems: 'center',
    '& .label': {
      marginTop: -2,
    },
  },
});

const ExternalLink: FC<Props> = ({ href, label, withIcon = true }) => {
  const styles = useStyles();
  const IconEl = withIcon ? <Icon name="link" size="small" /> : null;
  return (
    <a href={href} target="_blank" className={styles.externalLink}>
      {IconEl}
      <Box className="label">{label}</Box>
    </a>
  );
};

export default ExternalLink;
