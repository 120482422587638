import { FC, useState } from 'react';
import Page, { PageProps } from '@/components/page';
import { Box, Stack } from '@mui/material';
import DataTable from '@/components/data-table';
import PageHeader, { PageBlurb } from '@/components/page/header';
import { DtColumn, DtFilter, DtSort } from '@/components/data-table/helpers';
import Text from '@/components/text';
import { getPublicServices } from '@/lib/services/public-service.service';
import DownloadMenu from './download-menu';
import { createUseStyles } from 'react-jss';
import Chip from '@/components/chip';
import Icon from '@/components/icon';
import useDrawer from '@/hooks/use-drawer';
import { SignalOpts } from '@/lib/models/public-service.model';
import OpenDrawerButton from '@/components/drawers/open-drawer-button';
import NoneChip from '@/components/chip/none-chip';
import { QueryKey } from '@/lib/query-client';
import DomSize from '@/components/dom-size';

const columns: DtColumn[] = [
  { name: 'name', label: 'Name', flex: 2, sortable: true },
  { name: 'company', label: 'Company', sortable: true },
  { name: 'signals', label: 'Risk Signals', flex: 1.5, sortable: false },
  { name: 'risk', label: 'Risk Level', sortable: true },
  { name: 'support', label: 'SurePath AI Support', flex: 1.2, sortable: true },
  { name: 'status', label: 'Status', sortable: false },
  { name: 'action', label: '', sortable: false },
];

const filter: DtFilter = {
  keyword: '',
  fields: [
    {
      columnName: 'riskSignals',
      label: 'Risk Signals',
      control: 'select-multi',
      highlander: true,
      options: SignalOpts,
    },
    {
      columnName: 'riskLevel',
      label: 'Risk Level',
      control: 'select-multi',
      highlander: true,
      options: [
        { label: 'Low', value: 'LOW' },
        { label: 'Medium', value: 'MEDIUM' },
        { label: 'High', value: 'HIGH' },
      ],
    },
  ],
  values: [],
};

const useStyles = createUseStyles({
  favicon: {
    width: 32,
    height: 32,
  },
  description: {
    overflow: 'hidden',
  },
});

const PublicServicesPage: FC<PageProps> = () => {
  const styles = useStyles();
  const { openDrawer, DrawerEl } = useDrawer('public-service');
  const [selectedRowId, setSelectedRowId] = useState('');

  const handleView = (id: string) => {
    setSelectedRowId(id || '');
    openDrawer({
      id,
      onClose: () => {
        setSelectedRowId('');
      },
    });
  };

  const loadData = async (page: number, pageSize: number, sort?: DtSort, filter?: DtFilter) => {
    const dataset = await getPublicServices(page, pageSize, sort, filter);

    return {
      ...dataset,
      rows: dataset.rows.map(
        ({
          id,
          name,
          companyName,
          support,
          supportLevel,
          image,
          activeSignalLabels,
          risk,
          riskColor,
          isDeprecated,
        }) => {
          const hasImage = !!image;
          const hasRisk = !!risk?.base;
          const hasAction = !!support.level; // @todo remove this once v2 of the data is available

          return {
            id,
            name: (
              <Stack alignItems="center" direction="row" gap={2} width="100%">
                <Stack className={styles.favicon} alignItems="center" justifyContent="center">
                  {hasImage && <img src={image} className={styles.favicon} />}
                  {!hasImage && <Icon name="question-mark" color="grey" />}
                </Stack>
                <Box>
                  <Text size="small">{name}</Text>
                </Box>
              </Stack>
            ),
            company: companyName.value,
            signals: (
              <Stack gap={0.5}>
                {activeSignalLabels.map((label) => (
                  <Chip key={label} label={label} size="small" />
                ))}
              </Stack>
            ),
            risk: hasRisk ? (
              <Chip color={riskColor} label={risk.base.level} size="small" />
            ) : (
              <NoneChip notAvailable />
            ),
            support: <Text size="small">{String(supportLevel).toUpperCase()}</Text>,
            status: isDeprecated ? (
              <Chip color="warning" label="DEPRECATED" size="small" />
            ) : (
              <Chip label="ACTIVE" size="small" color="success" />
            ),
            action: (
              <Box width="100%" display="flex" justifyContent="flex-end">
                {hasAction && <OpenDrawerButton onClick={() => handleView(id)} />}
              </Box>
            ),
          };
        }
      ),
    };
  };

  return (
    <Page title="Public Services">
      <PageHeader>
        <PageBlurb>View and download a catalog of supported public services.</PageBlurb>
        <DownloadMenu />
      </PageHeader>
      <DomSize>
        <DataTable
          queryKey={QueryKey.PublicServicesDataset}
          searchPlaceholder="Search by name"
          columns={columns}
          onLoad={loadData}
          search={true}
          filter={filter}
          rowHeight={86}
          selectedRowId={selectedRowId}
        />
      </DomSize>
      {DrawerEl}
    </Page>
  );
};

export default PublicServicesPage;
