import { FormControl } from '@mui/material';
import { FC, ReactNode } from 'react';
import Text from '../text';

interface Props {
  children: ReactNode;
}

const FormLabel: FC<Props> = ({ children }) => (
  <FormControl margin="normal">
    <Text bold>{children}</Text>
  </FormControl>
);

export default FormLabel;
