import { AxiosError } from 'axios';
import { JsonObject } from '../helpers';
import { OrgBasicInfo, OrgModel, CreateOrgModel } from '../models/org.model';
import { AuthProvider } from './auth.service';
import { get, patch } from './sp-api.service';
import { PatchResponse, DEFAULT_SERVICE_ERROR, FieldError } from '.';

type ServiceFieldError = {
  field: string;
  rule: string;
};

export const getOrg = async (): Promise<OrgModel | null> => {
  const response = await get(`/orgs/${AuthProvider.orgId}`);

  if (!response) {
    return null;
  }

  return new OrgModel(response as JsonObject);
};

export const patchOrg = async (org: OrgModel): Promise<PatchResponse> => {
  const createOrgModel = new CreateOrgModel(org);

  return patch(`/orgs/${AuthProvider.orgId}`, createOrgModel._props, handlePatchErrorResponse);
};

export const getOrgBasicInfoAppData = async (): Promise<OrgBasicInfo | null> => {
  const response = await get(`/orgs/${AuthProvider.orgId}`, { fields: 'name,mode,trial' });

  if (!response) {
    return null;
  }

  return new OrgModel(response as JsonObject);
};

const handlePatchErrorResponse = (error: AxiosError): PatchResponse => {
  if (!error.response) {
    return { patched: false, error: DEFAULT_SERVICE_ERROR };
  }

  const { status, data } = error.response as { status: number; data: JsonObject };

  if (status !== 400 || !Array.isArray(data.errors)) {
    return { patched: false, error: DEFAULT_SERVICE_ERROR };
  }

  return { patched: false, fieldErrors: getFieldErrors(data.errors as ServiceFieldError[]) };
};

const getFieldErrors = (errors: ServiceFieldError[]): FieldError[] => {
  return errors.map(({ field, rule }) => ({
    type: 'validation',
    value: null,
    msg: rule,
    path: field,
    location: 'body',
  }));
};
