import { FormControl as MuiFormControl, InputLabel, FormHelperText, Theme } from '@mui/material';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  name: string;
  label?: ReactNode;
  error?: string;
  className?: string;
  fullWidth?: boolean;
  children: ReactNode;
  size?: 'small' | 'medium';
}

const useStyles = createUseStyles((theme: Theme) => ({
  helperText: {
    marginLeft: 0,
  },
  formControl: {
    '& label.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.common.black,
      opacity: 0.8,
    },
    '& div.MuiInputBase-root': {
      '& fieldset': {
        borderColor: `${theme.palette.grey[500]} !important`,
      },
    },
  },
}));

const FormControl: FC<Props> = ({
  className = '',
  name,
  children,
  label = '',
  error = '',
  fullWidth = true,
  size = 'medium',
}) => {
  const styles = useStyles();
  const hasError = !!error;
  const hasLabel = !!label;
  const labelId = `${name}-label`;
  const compClass = classNames(className, styles.formControl);
  const margin = size === 'small' ? 'dense' : 'normal';

  return (
    <MuiFormControl fullWidth={fullWidth} className={compClass} margin={margin} size={size}>
      {hasLabel && <InputLabel id={labelId}>{label}</InputLabel>}
      {children}
      {hasError && (
        <FormHelperText className={styles.helperText} error>
          {error}
        </FormHelperText>
      )}
    </MuiFormControl>
  );
};

export default FormControl;
