import { FC } from 'react';
import Card from '@/components/card';
import { Box, Stack } from '@mui/material';
import CardHeader from '../card/card-header';
import NoneChip from '../chip/none-chip';
import { IconType } from '../icon';
import CardRow from '../card/card-row';
import Text from '../text';
import { UsageItem } from './helpers';

interface Props {
  title: string;
  items: UsageItem[];
  icon: IconType;
}

const UsageCard: FC<Props> = ({ items, icon, title }) => {
  const hasItems = !!items.length;

  return (
    <Card>
      <CardHeader title={title} icon={icon} />
      <Box>
        {!hasItems && <NoneChip />}
        {hasItems && (
          <Stack gap={1} mt={3}>
            {items.map(({ id, name, BadgeEl, LinkEl }) => {
              const hasBadge = !!BadgeEl;
              const hasLink = !!LinkEl;

              return (
                <CardRow key={id}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      {hasBadge && BadgeEl}
                      <Text>{name}</Text>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                      {hasLink && LinkEl}
                    </Stack>
                  </Stack>
                </CardRow>
              );
            })}
          </Stack>
        )}
      </Box>
    </Card>
  );
};

export default UsageCard;
