import { Dataset, DtSort } from '@/components/data-table/helpers';
import {
  ContextProviderModel,
  CreateContextProviderModel,
} from '../models/context-provider/context-provider.model';
import { create, deleteApi, get, patch, post } from './sp-api.service';
import { JsonObject } from '../helpers';
import { orderBy } from 'lodash';
import { CreateResponse, DeleteResponse, PatchResponse, SAFE_LIMIT } from '.';
import { PolicyModel } from '../models/policy.model';
import { AgentModel } from '../models/agent.model';
import { getPoliciesByDatasourceIds } from './policy.service';
import { getAgentsByDatasourceIds } from './agent.service';

export type RagStatus = 'active' | 'inactive' | 'pending' | 'failed';

export type ContextProviderUsage = {
  policies: PolicyModel[];
  agents: AgentModel[];
  inUse: boolean;
};

export const getAllProviders = async (): Promise<ContextProviderModel[]> =>
  (await getProviders(0, SAFE_LIMIT)).rows;

export const getProviders = async (
  page: number,
  pageSize: number,
  dtSort?: DtSort
): Promise<Dataset<ContextProviderModel>> => {
  const response = await get('/datasource-controller/datasource');

  let rows = ((response as JsonObject[]) || []).map((data) => new ContextProviderModel(data));

  // Sort
  if (dtSort) {
    const { columnName, direction } = dtSort;
    rows = orderBy(rows, [columnName], [direction]);
  }

  // Paginate
  const startIndex = page * pageSize;
  const total = rows?.length || 0;
  rows = rows.slice(startIndex, startIndex + pageSize);

  return {
    page,
    pageSize,
    rows,
    total,
  };
};

// @todo replace with datasource-controller get by list of ids call
export const getProvidersByIds = async (providerIds: string[]): Promise<ContextProviderModel[]> => {
  const allProviders = await getAllProviders();
  return allProviders.filter(({ id }) => providerIds.includes(id));
};

export const getProviderById = async (providerId: string): Promise<ContextProviderModel | null> => {
  const response = await get(`/datasource-controller/datasource/${providerId}`).catch((error) => {
    console.error(error);
    return null;
  });

  return response ? new ContextProviderModel(response as JsonObject) : null;
};

export const createProvider = async (model: ContextProviderModel): Promise<CreateResponse> => {
  const createProviderModel = new CreateContextProviderModel(model);

  const response = await create(`/datasource-controller/datasource`, createProviderModel._props);

  if (!response.created) {
    return response;
  }

  return { created: true, model: new ContextProviderModel(response.data) };
};

export const patchProvider = async (model: ContextProviderModel): Promise<PatchResponse> => {
  const createModel = new CreateContextProviderModel(model);
  return patch(`/datasource-controller/datasource/${model.id}`, createModel._props);
};

export const deleteProvider = async (providerId: string): Promise<DeleteResponse> => {
  return deleteApi(`/datasource-controller/datasource/${providerId}`);
};

export const getRagStatus = async (): Promise<RagStatus> => {
  const response = await get('/datasource-controller/rag').catch(() => {
    return { status: 'failed' };
  });

  return (response as JsonObject)?.status as RagStatus;
};

export const activateRag = async () => {
  const response = await post('/datasource-controller/rag', {}).catch(() => {
    return { status: 'failed' };
  });

  return (response as JsonObject)?.status === 'pending';
};

export const getProviderUsage = async (
  providerIds: string[]
): Promise<Map<string, ContextProviderUsage>> => {
  const [policies, agents] = await Promise.all([
    getPoliciesByDatasourceIds(providerIds),
    getAgentsByDatasourceIds(providerIds),
  ]);

  const usageMap: Map<string, ContextProviderUsage> = new Map();

  providerIds.forEach((id) => {
    const usage = {
      policies: policies.filter(({ contextDataSources }) =>
        contextDataSources.enabled.includes(id)
      ),
      agents: agents.filter(({ dataSourceIds }) => dataSourceIds.includes(id)),
    };

    usageMap.set(id, { ...usage, inUse: !!usage.policies.length || !!usage.agents.length });
  });

  return usageMap;
};
