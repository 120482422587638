import { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import Icon, { IconType } from '../icon';
import Text from '../text';
import { createUseStyles } from 'react-jss';

interface Props {
  icon?: IconType;
  title: string;
  children?: ReactNode;
}

const useStyles = createUseStyles({
  header: {
    marginBottom: 16,
  },
});

const CardHeader: FC<Props> = ({ icon, title, children = null }) => {
  const styles = useStyles();
  const hasIcon = !!icon;

  return (
    <Stack direction="row" gap={1} className={styles.header} alignItems="center">
      {hasIcon && <Icon name={icon} />}
      <Text bold>{title}</Text>
      <Box flexGrow={1} display="flex" justifyContent="flex-end">
        {children}
      </Box>
    </Stack>
  );
};

export default CardHeader;
