import { DEFAULT_PAGE_SIZE, Dataset, DtFilter, DtSort } from '@/components/data-table/helpers';
import {
  MongoSort,
  findAll,
  findAllAndCount,
  findOne,
  getOidParams,
} from './atlas-data-api.service';
import { PublicServiceMeta, PublicServiceModel } from '../models/public-service.model';
import { JsonObject, UiOption, downloadCsv, downloadFile, getNiceDate } from '../helpers';
import { flatten, uniq } from 'lodash';
import { SAFE_LIMIT } from '.';
import { formatISO } from 'date-fns';

const COLLECTION = 'publicServices';

export type PublicCatalogDownloadFormat = 'zscaler' | 'netskope' | 'csv' | 'cisco-umbrella';

export const PublicCatalogDownloadOpts: UiOption[] = [
  { label: 'Zscaler Config', value: 'zscaler' },
  { label: 'Netskope Config', value: 'netskope' },
  { label: 'Cisco Umbrella Config (Events API)', value: 'cisco-umbrella' },
  // { label: 'CSV', value: 'csv' },
];

export const getPublicServices = async (
  page = 0,
  pageSize: number = DEFAULT_PAGE_SIZE,
  dtSort?: DtSort,
  dtFilter?: DtFilter
): Promise<Dataset<PublicServiceModel>> => {
  const skip = page * pageSize;
  const filter: JsonObject = {};

  const { keyword, values = [] } = dtFilter || {};
  if (keyword) {
    filter.$or = [
      { name: { $regex: `.*${keyword}.*`, $options: 'i' } },
      { website: { $regex: `.*${keyword}.*`, $options: 'i' } },
    ];
  }

  values.forEach(({ columnName, value }) => {
    switch (columnName) {
      case 'riskSignals':
        if ((value as string[]).includes('trainOnUserData')) {
          filter['signals.trainOnUserData.value'] = true;
        }

        if ((value as string[]).includes('noModeration')) {
          filter['signals.noModeration.value'] = true;
        }
        break;
      case 'riskLevel': {
        filter['risk.base.level'] = { $in: value };
        break;
      }
    }
  });
  console.log(dtSort);

  // by default, deprecated services should be listed last
  let sort: MongoSort = { 'support.deprecated.value': 1, 'support.level': -1 };
  if (dtSort) {
    let fieldName = '';
    const { columnName, direction } = dtSort;
    switch (columnName) {
      case 'name':
        fieldName = 'name';
        break;
      case 'support':
        fieldName = 'support.level';
        break;
      case 'risk':
        fieldName = 'risk.base.score';
        break;
      case 'company':
        fieldName = 'companyName';
        break;
    }

    if (fieldName) {
      sort = { [fieldName]: direction === 'asc' ? 1 : -1 };
    }
  }

  console.log(sort);

  const { documents, total } = await findAllAndCount(COLLECTION, filter, pageSize, skip, sort);

  const rows: PublicServiceModel[] = documents.map((data) => new PublicServiceModel(data));

  return {
    page,
    pageSize,
    rows,
    total,
  };
};

export const getPubliceServiceById = async (
  modelId: string
): Promise<PublicServiceModel | null> => {
  const params = {
    filter: {
      _id: { $oid: modelId },
    },
  };

  const response = await findOne(COLLECTION, params);

  return response ? new PublicServiceModel(response) : null;
};

export const getPubliceServicesByIds = async (ids: string[]): Promise<PublicServiceModel[]> => {
  const params = {
    filter: {
      _id: { $in: getOidParams(ids) },
    },
  };

  const response = await findAll(COLLECTION, params);

  return response?.length ? response.map((data) => new PublicServiceModel(data)) : [];
};

export const downloadCatalog = async (
  format: PublicCatalogDownloadFormat,
  orgId?: string
): Promise<void> => {
  const { rows } = await getPublicServices(0, 1000);

  let hosts = flatten(
    rows
      .filter(({ support }) => !support.deprecated?.value)
      .map(({ hosts, unauthenticatedHosts }) => [...hosts, ...unauthenticatedHosts])
  ).map((host) => String(host).toLowerCase());

  // unique + remove surepath hosts
  hosts = uniq(hosts).filter((host) => !host.includes('surepath'));

  // alphabetize
  hosts.sort();

  const { date } = getNiceDate(new Date());
  const filename = `surepath-ai-public-services-${format}-${date}`;

  switch (format) {
    case 'zscaler':
    case 'netskope':
      {
        let fileContent: string[] = [];

        /*
         * We need to differentiate between zscaler and netskope here because wildcards are represented different in
         * either system. ZScaler wildcards look like this: `.openai.com` whereas Netskope uses a star like this `*.openai.com`
         *
         * https://help.zscaler.com/zia/url-format-guidelines
         */
        if (format === 'zscaler') {
          fileContent = hosts.map((host) => host.replace(/^\*{1}/, ''));
        } else {
          fileContent = hosts.map((host) => host.replace(/^\.{1}/, '*.'));
        }

        const fileBlob = new Blob([fileContent.join('\r\n')], { type: 'text/plain' });
        downloadFile(fileBlob, `${filename}.txt`);
      }
      break;

    case 'cisco-umbrella':
      {
        const eventTime = formatISO(new Date());

        const dstHosts = hosts.map((host) => {
          const domain = host.replace(/^\*?\./, '');

          return {
            dstDomain: domain,
            protocolVersion: '1.0a',
            providerName: 'SurePath AI',
            deviceId: orgId || '',
            deviceVersion: '1.0',
            eventTime,
            alertTime: eventTime,
            dstUrl: `https://${domain}`,
            disableDstSafeguards: true,
          };
        });

        const fileBlob = new Blob([JSON.stringify(dstHosts, null, 2)], { type: 'text/plain' });
        downloadFile(fileBlob, `${filename}.json`);
      }
      break;

    case 'csv':
      {
        const headers = ['Name', 'Description', 'Support', 'Type', 'Hosts'];
        const csvData = rows.map(({ name, description, support, type, hosts }) => [
          name,
          description,
          support.level,
          type,
          hosts.join(','),
        ]);

        downloadCsv([headers, ...csvData], `${filename}.csv`);
      }
      break;
  }
};

export const getPublicServiceAppData = async (): Promise<PublicServiceMeta[]> => {
  const params = {
    projection: {
      name: 1,
      image: 1,
      description: 1,
      risk: 1,
      support: 1,
    },
    skip: 0,
    limit: SAFE_LIMIT,
    sort: { name: 1 },
  };

  const response = await findAll(COLLECTION, params);

  return response.map((data) => new PublicServiceModel(data).meta);
};
