import Icon from '@/components/icon';
import Text from '@/components/text';
import { Box, Stack, Theme } from '@mui/material';
import { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { AuthRole, AuthRoleLabels, deactivateAssumeRole } from '@/lib/services/auth.service';
import useToast from '@/hooks/use-toast.hook';
import LoadingButton from '@mui/lab/LoadingButton';
import { LoadState } from '@/lib/helpers';

const useStyles = createUseStyles((theme: Theme) => ({
  assumeRoleToast: {
    backgroundColor: '#ffa32fe0',
    padding: 10,
    height: 60, // affects height rules in app-header and app-sidebar. if you change this, you MUST change those
    color: theme.palette.common.white,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    color: '#ff8e00',
    cursor: 'pointer',
    boxShadow: 'none',
    '&:hover': {
      opacity: 0.9,
      boxShadow: 'none',
      backgroundColor: theme.palette.common.white,
    },
  },
}));

interface Props {
  role: AuthRole;
  orgName: string;
}

const AssumeRoleInlineToast: FC<Props> = ({ role, orgName }) => {
  const styles = useStyles();
  const { errorToast } = useToast();
  const [loadState, setLoadState] = useState<LoadState>('unloaded');

  const handleExit = () => {
    if (loadState !== 'unloaded') {
      return;
    }
    setLoadState('loading');
    deactivateAssumeRole().then((response) => {
      setLoadState('unloaded');
      if (!response) {
        errorToast('Failed to deactivate role assumption');
      }
    });
  };

  const roleLabel = AuthRoleLabels[role];
  const loading = loadState === 'loading';

  return (
    <Box width="100%">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.assumeRoleToast}
      >
        <Stack alignItems="center" gap={1} direction="row">
          <Box mt={0.5}>
            <Icon name="assume-role" />
          </Box>
          <Box>
            <Text bold dotdot size="large">
              Role Assumption: [{roleLabel}] {orgName}
            </Text>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
          <LoadingButton
            className={styles.button}
            size="small"
            variant="contained"
            type="button"
            fullWidth={false}
            onClick={handleExit}
            loading={loading}
          >
            Deactivate
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AssumeRoleInlineToast;
