import { FC, useEffect } from 'react';
import Page, { PageProps } from '../../components/page';
import { Box, Paper, Stack } from '@mui/material';
import Text from '@/components/text';
import { createUseStyles } from 'react-jss';
import Icon from '@/components/icon';
import { getAuthRedirectUrl } from '@/lib/services/auth.service';

const useStyles = createUseStyles({
  lockIcon: {
    margin: 4,
  },
  container: {
    margin: '8px auto',
    width: 500,
    padding: 20,
  },
});

// This is a failsafe redirection page that should be technically impossible to get to. See the note in @/src/router/index.tsx for more.

const LoginPage: FC<PageProps> = () => {
  const styles = useStyles();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = getAuthRedirectUrl();
    }, 2000);
  }, []);

  return (
    <Page>
      <Paper elevation={1} className={styles.container}>
        <Stack alignItems="center" justifyContent="center" gap={2}>
          <Box>
            <Icon name="lock-person" color="active" />
          </Box>
          <Box>
            <Text size="x-large">Sign In Required</Text>
          </Box>

          <Box mt={2}>Redirecting to sign-in page...</Box>
        </Stack>
      </Paper>
    </Page>
  );
};

export default LoginPage;
