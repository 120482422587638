import { FC, Fragment } from 'react';
import { BreadCrumb, BreadCrumbMap } from './helpers';
import { Box, Stack } from '@mui/material';
import Icon from '../icon';
import { Link } from '@tanstack/react-router';
import Text from '../text';

interface Props {
  breadcrumb: BreadCrumb | BreadCrumb[];
  pageTitle: string;
}

const Breadcrumbs: FC<Props> = ({ breadcrumb, pageTitle }) => {
  const crumblist = Array.isArray(breadcrumb) ? breadcrumb : [breadcrumb];

  return (
    <Stack direction="row" gap={0.4} alignItems="flex-end">
      {crumblist.map((crumbId) => {
        const { label, value } = BreadCrumbMap[crumbId];
        return (
          <Fragment key={crumbId}>
            <Box>
              <Link to={value}>{label}</Link>
            </Box>
            <Icon name="chevron-right" />
          </Fragment>
        );
      })}
      <Text>{pageTitle}</Text>
    </Stack>
  );
};

export default Breadcrumbs;
