import Text from '@/components/text';
import { Box, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  label: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  section: {
    margin: '16px 0px',
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

export const ReadonlySection: FC<Props> = ({ label }) => {
  const styles = useStyles();
  return (
    <Box className={styles.section}>
      <Text bold size="large">
        {label}
      </Text>
    </Box>
  );
};
