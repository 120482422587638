import { FC } from 'react';
import Text from '@/components/text';
import { Box, Stack } from '@mui/material';
import Icon from '@/components/icon';
import StatusChip, { ChipStatus } from '@/components/chip/status-chip';

interface Props {
  inputModalities?: string[];
  outputModalities?: string[];
  status?: ChipStatus;
}

const ModeFooter: FC<{ title: string; modalities: string[] }> = ({ title, modalities }) => {
  if (!modalities.length) {
    return null;
  }

  const hasText = modalities.includes('text');
  const hasImage = modalities.includes('image');
  const hasEmbed = modalities.includes('embedding');

  return (
    <Stack flexDirection="row" justifyContent="space-between" width="100%">
      <Text size="x-small" color="grey">
        {title}
      </Text>
      <Stack flexDirection="row">
        <Icon name="text-file" size="small" color={hasText ? 'link' : 'grey'} />
        <Icon name="image" size="small" color={hasImage ? 'link' : 'grey'} />
        <Icon name="vector" size="small" color={hasEmbed ? 'link' : 'grey'} />
      </Stack>
    </Stack>
  );
};

const PrivateModelCardFooter: FC<Props> = ({
  inputModalities = [],
  outputModalities = [],
  status,
}) => {
  const hasModalities = !!inputModalities.length || !!outputModalities.length;
  const hasStatus = !!status;

  return (
    <Box
      position="relative"
      flexGrow={1}
      width="100%"
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      {hasModalities && (
        <Stack
          justifyContent="space-between"
          alignItems="start"
          flexDirection="column"
          width="100%"
          gap={0.5}
        >
          <ModeFooter title="Input Modalities" modalities={inputModalities} />
          <ModeFooter title="Output Modalities" modalities={outputModalities} />
        </Stack>
      )}
      {hasStatus && <StatusChip value={status} size="small" />}
    </Box>
  );
};

export default PrivateModelCardFooter;
