import { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import Button from '@/components/button';
import FormSection from '@/components/form/form-section';
import { downloadFile, UiOption } from '@/lib/helpers';
import TextInput from '@/components/form/text-input';
import Text from '@/components/text';
import Select from '@/components/form/select';
import SurepathRootCert from '@/assets/surepath-ai-root-ca-09-2024.cer?raw';

const integrationConfig = {
  cert: {
    label: 'SurePath AI Root CA Certificate',
    filename: 'surepath-ai-root-ca-09-2024',
    text: 'SurePath AI Root CA Certificate',
  },
  edge: {
    label: 'SurePath AI Proxy URL',
    url: 'http://edge.surepath.ai:8080/',
  },
  proxyPAC: {
    label: 'SurePath AI Proxy PAC URL',
    url: 'https://pacfile.surepath.ai/default/current.pac',
  },
  doh: {
    label: 'SurePath AI DNS-over-HTTPS (DOH) Provider',
    url: 'https://doh.surepath.ai/dns-query',
  },
  portal: {
    label: 'SurePath AI Private Portal',
    url: 'https://portal.surepath.ai/',
  },
  ready: {
    label: 'SurePath AI Readiness Check',
    url: 'https://ready.surepath.ai/',
  },
};

type CertificateFormat = 'cer' | 'pem' | 'crt';

const IntForm: FC = () => {
  const { cert, edge, proxyPAC, doh, portal, ready } = integrationConfig;

  const CertificateExtensions: UiOption[] = [
    { label: 'CER', value: 'cer' },
    { label: 'PEM', value: 'pem' },
    { label: 'CRT', value: 'crt' },
  ];

  const [downloadExtension, setDownloadExtension] = useState<CertificateFormat>('cer');

  const handleChange = (val: CertificateFormat) => {
    setDownloadExtension(val);
  };

  const handleDownloadCert = () => {
    if (!downloadExtension) {
      return;
    }

    const blob = new Blob([SurepathRootCert], { type: 'text/plain' });
    return downloadFile(blob, cert.filename + '.' + downloadExtension);
  };

  const canDownload = !!downloadExtension;

  return (
    <Box>
      <FormSection title="File Downloads">
        <Box mb={4}>Links to downloads for files required to integrate with SurePath AI.</Box>
        <Box maxWidth="900px">
          <Text bold size="large">
            {cert.label}
          </Text>
          <Box my={2}>
            This certificate is needed to integrate with SurePath AI. Depending on the integration
            type, it may need to be installed on endpoints, proxy servers, or SASE platforms.
          </Box>

          <Stack gap={3} direction="row" alignItems="center" mt={3}>
            <Box width="200px">
              <Select
                label="Certificate format"
                value={downloadExtension}
                name="download-format"
                options={CertificateExtensions}
                onChange={handleChange}
                size="small"
              />
            </Box>
            <Box>
              <Button
                label="Download Certificate"
                disabled={!canDownload}
                icon="download"
                onClick={handleDownloadCert}
              />
            </Box>
          </Stack>
        </Box>
      </FormSection>

      <FormSection title="Proxy and Provider URLs">
        <Box mb={4}>
          All the URLs necessary to implement the integration between a network or endpoints and the
          SurePath AI platform.
        </Box>
        <Box maxWidth="900px">
          <Text bold size="large">
            {edge.label}
          </Text>
          <Box my={2}>
            This URL address is used for a proxy-based integration with SurePath AI using a SASE platform
            such as ZScaler, NetSkope, or Palo Alto Prisma Access.
          </Box>
          <Box mb={2}>
            <TextInput name="edgeURL" label={edge.label} readonly value={edge.url} copyClip />
          </Box>

          <Text bold size="large">
            {proxyPAC.label}
          </Text>
          <Box my={2}>
            This proxy PAC URL is used for direct integration with SurePath AI on individual devices. 
            It can be manually installed on end-user devices for testing purposes or distributed by 
            administrators using management tools such as Microsoft Intune or Active Directory Group Policy.
          </Box>
          <Box mb={2}>
            <TextInput name="proxyPACURL" label={proxyPAC.label} readonly value={proxyPAC.url} copyClip />
          </Box>

          <Text bold size="large">
            {doh.label}
          </Text>
          <Box my={2}>
            This URL address is used for a DNS-based integration with SurePath AI. This can be used
            at the operating system level or within a browser such as Google Chrome. This DOH
            provider will steer all generative AI requests to the SurePath AI platform.
          </Box>
          <Box mb={2}>
            <TextInput name="dohURL" label={doh.label} readonly value={doh.url} copyClip />
          </Box>
        </Box>
      </FormSection>

      <FormSection title="Website URLs">
        <Box mb={4}>Links to important websites that are part of the SurePath AI platform.</Box>
        <Box maxWidth="900px">
          <Text bold size="large">
            {portal.label}
          </Text>
          <Box my={2}>
            This is the URL for the SurePath AI Private Portal where end users can leverage the
            organization's private models.
          </Box>
          <Box mb={2}>
            <TextInput
              name="portalURL"
              label={portal.label}
              readonly
              value={portal.url}
              copyClip
              openUrl
            />
          </Box>
          <Text bold size="large">
            {ready.label}
          </Text>
          <Box my={2}>
            The SurePath AI Readiness Check, or Ready for short, allows administrators to verify
            that the integration is working as expected and that generative AI traffic is being
            routed through the SurePath AI platform.
          </Box>
          <Box mb={2}>
            <TextInput
              name="readyURL"
              label={ready.label}
              readonly
              value={ready.url}
              copyClip
              openUrl
            />
          </Box>
        </Box>
      </FormSection>
    </Box>
  );
};

export default IntForm;
