import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC } from 'react';
import InputControl from '@/components/form/input-control';
import { ViewState } from '@/lib/helpers';
import { Box } from '@mui/material';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { WebcralwerConnectorModel } from '@/lib/models/connector/webcrawler-connector.model';

interface Props {
  connector: WebcralwerConnectorModel;
  formHandle: FormHandle<ConnectorModel>;
  mode: ViewState;
}

const WebcrawlerConnector: FC<Props> = ({ formHandle, mode }) => {
  const inWriteMode = ['edit', 'add'].includes(mode);

  return (
    <>
      {inWriteMode && (
        <Box>
          <InputControl type="url" name="config.url" label="URL" formHandle={formHandle} />
        </Box>
      )}
    </>
  );
};

export default WebcrawlerConnector;
