import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import Text from '../../text';
import { createUseStyles } from 'react-jss';
import Panel from '@/components/panel';
import PanelTitle from '@/components/panel/panel-title';
import PanelContent from '@/components/panel/panel-content';

interface Props {
  title: string;
  subtitle?: string;
  children: ReactNode;
}

const useStyles = createUseStyles({
  section: {
    marginBottom: 30,
  },
  subtitle: {
    marginBottom: 15,
  },
});

const FormSection: FC<Props> = ({ children, title, subtitle }) => {
  const styles = useStyles();
  const hasSubtitle = Boolean(subtitle);

  return (
    <Panel className={styles.section}>
      <PanelTitle variant="filled">
        <Text size="large">{title}</Text>
      </PanelTitle>
      <PanelContent>
        {hasSubtitle && (
          <Box className={styles.subtitle} p={4}>
            <Text size="medium">{subtitle}</Text>
          </Box>
        )}
        {children}
      </PanelContent>
    </Panel>
  );
};

export default FormSection;
