import { BreadCrumb } from '@/components/app-header/helpers';
import { LoadState } from '@/lib/helpers';
import { AuthUser } from '@/lib/services/auth.service';
import { createContext } from 'react';

export interface AppSettings {
  loadState: LoadState;
  pageLoadState: LoadState;
  signedIn: boolean;
  signIn: (authUser: AuthUser) => boolean;
  signOut: () => Promise<boolean>;
  user: AuthUser | null;
  pageTitle: { plain: string; breadcrumb?: BreadCrumb | BreadCrumb[] };
  setPageTitle: (title: string, breadcrumb?: BreadCrumb | BreadCrumb[]) => void;
  setPageLoadState: (loadState: LoadState) => void;
  isDemo: boolean;
}

export const AppContext = createContext<AppSettings>({
  loadState: 'unloaded',
  pageLoadState: 'unloaded',
  signedIn: false,
  signIn: () => false,
  signOut: async () => Promise.resolve(false),
  user: null,
  pageTitle: { plain: '' },
  setPageTitle: () => null,
  setPageLoadState: () => null,
  isDemo: false,
});
