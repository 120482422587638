import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC } from 'react';
import InputControl from '@/components/form/input-control';
import { ViewState } from '@/lib/helpers';
import { Box } from '@mui/material';
import { ContextProviderModel } from '@/lib/models/context-provider/context-provider.model';
import Text from '@/components/text';
import { WebcrawlerConfig } from '@/lib/models/connector/webcrawler-connector.model';
import NoneChip from '@/components/chip/none-chip';
import TextInput from '@/components/form/text-input';
import { getTypedConnector } from '@/lib/models/connector';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import Card from '@/components/card';

interface Props {
  provider: ContextProviderModel;
  formHandle: FormHandle<ContextProviderModel>;
  mode: ViewState;
  connector?: ConnectorModel | null;
}

const WebcrawlerContextProvider: FC<Props> = ({ connector, formHandle, mode }) => {
  const inWriteMode = ['edit', 'add'].includes(mode);

  const typedConnector = connector ? getTypedConnector(connector) : { config: { url: '' } };
  const { url } = typedConnector.config as WebcrawlerConfig;
  const hasUrl = !!url;

  return (
    <>
      {inWriteMode && (
        <Box>
          <Box mt={2} mb={1.5}>
            <Text bold>Connector Base URL</Text>
          </Box>
          <Box mb={2}>
            {hasUrl && (
              <TextInput name="baseUrl" value={url} readonly copyClip openUrl size="small" />
            )}
            {!hasUrl && <NoneChip />}
          </Box>

          <Box mb={2}>
            <Card>
              <Box mb={1}>
                <Text bold>Crawl Base Path</Text>
              </Box>
              <Box>
                <Text>
                  When enabled, the connector base URL will be crawled with no relative path, in
                  addition to any paths you specify below in the "Paths" section.
                </Text>
              </Box>

              <InputControl
                name="config.includeBasePath"
                label=""
                formHandle={formHandle}
                type="switch"
                required
              />
            </Card>
          </Box>

          <Box mb={1}>
            <InputControl
              name="config.paths"
              label="Paths"
              formHandle={formHandle}
              type="list-editor"
              required
              height={90}
              textPrefix="/"
            />
          </Box>

          <InputControl
            name="config.depth"
            label="Crawl Depth"
            formHandle={formHandle}
            type="number"
          />
        </Box>
      )}
    </>
  );
};

export default WebcrawlerContextProvider;
