import { UserEventAction } from '@/lib/models/user-event';
import { ChipColor } from '../chip';

export const UserEventActionMap: Map<UserEventAction, { label: string; color: ChipColor }> =
  new Map([
    [UserEventAction.CONTINUE, { label: 'continue', color: 'default' }],
    [UserEventAction.ALLOW, { label: 'intercept', color: 'success' }],
    [UserEventAction.BLOCK, { label: 'block', color: 'error' }],
    [UserEventAction.DROP, { label: 'drop', color: 'error' }],
    [UserEventAction.PORTAL, { label: 'portal', color: 'default' }],
    [UserEventAction.REROUTE, { label: 'intercept', color: 'success' }],
    [UserEventAction.PASSTHROUGH, { label: 'access', color: 'success' }],
    [UserEventAction.ERROR, { label: 'error', color: 'error' }], // error splash page reached
    [UserEventAction.DOWNTIME, { label: 'downtime', color: 'warning' }],
    [UserEventAction.HTTPERROR, { label: 'HTTP error', color: 'error' }], // http response code was non 2xx
    [UserEventAction.AUTH, { label: 'auth redirect', color: 'default' }],
    [UserEventAction.INTERCEPTAUTH, { label: 'login', color: 'default' }],
    [UserEventAction.INLINEBLOCK, { label: 'block', color: 'error' }],
    [UserEventAction.REDACT, { label: 'redact', color: 'warning' }],
  ]);

export const getUserEventActionLabel = (action: UserEventAction): string =>
  UserEventActionMap.has(action) ? UserEventActionMap.get(action)!.label : '';
