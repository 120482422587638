import { Box, Stack } from '@mui/material';
import { FC, ReactNode, isValidElement } from 'react';
import Text from '../../text';
import { getNiceDate } from '../../../lib/helpers';
import { ReadonlyField } from './helpers';
import { createUseStyles } from 'react-jss';
import { ReadonlySection } from './section';

interface Props {
  fields: ReadonlyField[];
  data: Record<string, unknown>;
  onData?: (data: Record<string, unknown>) => Record<string, unknown>;
}

const useStyles = createUseStyles({
  propVal: {
    width: '100%',
  },
});

const ReadonlyView: FC<Props> = ({ fields, data = {}, onData }) => {
  const compData = { ...(onData ? onData(data) : data) };
  const styles = useStyles();

  return (
    <Stack width="100%">
      {fields.map(({ label, prop, propType }) => {
        const rawVal = compData[prop];
        let propVal: ReactNode = '';

        if (propType === 'section') {
          return <ReadonlySection key={`${prop}-section`} label={label} />;
        }

        switch (typeof rawVal) {
          case 'string':
          case 'number':
            propVal = String(rawVal);
            break;
          case 'boolean':
            propVal = rawVal ? 'Yes' : 'No';
            break;
          case 'object':
            if (isValidElement(rawVal)) {
              propVal = rawVal;
            } else if (propType === 'date') {
              propVal = getNiceDate(rawVal as Date).formatted;
            }
            break;
        }

        return (
          <Box key={prop} width="100%" mb={3}>
            <Box mb={1}>
              <Text bold>{label}</Text>
            </Box>
            <Text color="grey" className={styles.propVal}>
              {propVal}
            </Text>
          </Box>
        );
      })}
    </Stack>
  );
};

export default ReadonlyView;
