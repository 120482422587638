import { Box, Stack, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import Text from '../text';
import Icon from '../icon';

interface Props {
  children: ReactNode;
  onClose: () => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  header: {
    padding: '0px 20px 10px 20px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  title: {
    maxWidth: 380,
  },
}));

const DrawerHeader: FC<Props> = ({ children, onClose }) => {
  const styles = useStyles();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className={styles.header}
      mb={1}
    >
      <Box className={styles.title}>
        <Text size="large">{children}</Text>
      </Box>
      <Box>
        <Icon name="close" onClick={() => onClose()} />
      </Box>
    </Stack>
  );
};

export default DrawerHeader;
