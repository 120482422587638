import { Stack } from '@mui/material';
import { FC } from 'react';
import FlatButton from '../flat-button';
import Tooltip from '../tooltip';

interface Props {
  onEdit: () => void;
  onDelete: () => void;
  canEdit?: boolean;
  canDelete?: boolean;
  deleteTooltip?: string;
  editTooltip?: string;
}

const DrawerToolbar: FC<Props> = ({
  onEdit,
  onDelete,
  canEdit = true,
  canDelete = true,
  deleteTooltip = '',
  editTooltip = '',
}) => {
  return (
    <Stack direction="row" justifyContent="flex-end" mb={2} alignItems="center" gap={1.5}>
      <Tooltip title={editTooltip} disabled={!editTooltip}>
        <FlatButton onClick={onEdit} disabled={!canEdit} icon="edit" label="Edit" color="black" />
      </Tooltip>

      <Tooltip title={deleteTooltip} disabled={!deleteTooltip}>
        <FlatButton
          onClick={onDelete}
          disabled={!canDelete}
          icon="delete"
          label="Delete"
          color="error"
        />
      </Tooltip>
    </Stack>
  );
};

export default DrawerToolbar;
