import { FC, ForwardedRef } from 'react';
import lodashGet from 'lodash/get';
import Checkbox from '../checkbox';
import TextInput from '../text-input';
import { FormikValues } from 'formik';
import DateInput from '../date-input';
import { FormProps } from '../helpers';
import DateRangeInput from '../date-range';
import Switch from '../switch';
import Select from '../select';
import { FormHandle } from '../../../hooks/use-form-handle.hook';
import * as yup from 'yup';
import SelectMulti from '../select-multi';
import ListToggle from '../list-toggle';
import ListEditor, { ListItem } from '../list-editor';
import { DateRangeValue, UiOption } from '@/lib/helpers';
import SwitchList from '../switch-list';
import PublicServiceAcl from '../public-service-acl';
import GroupsListToggle from '../groups-list-toggle';
import PolicyPublicActionSelect from '../policy-public-action-select';

export type InputControlType =
  | 'text'
  | 'password'
  | 'email'
  | 'url'
  | 'number'
  | 'checkbox'
  | 'date'
  | 'date-range'
  | 'textarea'
  | 'switch'
  | 'select'
  | 'select-multi'
  | 'select-search'
  | 'list-toggle'
  | 'list-editor'
  | 'switch-list'
  | 'public-service-acl'
  | 'groups-list-toggle'
  | 'policy-public-action-select';

export type InputControlValue = null | string | number | boolean | Date | DateRangeValue | string[];

// @todo ts won't catch if you do something silly like pass props like { type: 'text', options: [...]}. in other words, we need some way to validate props against the known type
interface Props extends FormProps {
  type?: InputControlType;
  formHandle: FormHandle<FormikValues>;
  autoComplete?: string;
  onEnter?: (value: string) => void;
  rows?: number;
  options?: UiOption[];
  multiple?: boolean;
  leftTitle?: string;
  rightTitle?: string;
  height?: string | number;
  inputRef?: ForwardedRef<HTMLInputElement>;
  onChange?: (val: InputControlValue) => void;
  labelChecks?: boolean;
  maxLength?: number;
  textPrefix?: string;
}

// @todo typing?
const getErrorText = (formikError: string | string[]): string => {
  if (!formikError) {
    return '';
  }

  if (Array.isArray(formikError)) {
    return formikError.join(', ');
  }

  // @todo handle more types?
  return String(formikError);
};

const fieldIsRequired = (fieldName: string, validationSchema: yup.ObjectSchema<yup.AnyObject>) => {
  const field = lodashGet(validationSchema.fields, fieldName.replace(/\./, '.fields.'));

  if (!field) {
    return false;
  }

  // @todo oof
  /* eslint-disable */
  const isRequired = !!(field as any).tests.find((test: any) => test.OPTIONS.name === 'required');
  /* eslint-enable */

  return isRequired;
};

const InputControl: FC<Props> = ({
  formHandle,
  name,
  label,
  disabled = false,
  fullWidth = true,
  type = 'text',
  autoComplete = '',
  autoFocus = false,
  onEnter = () => null,
  rows,
  options = [],
  leftTitle = '',
  rightTitle = '',
  height,
  size = 'medium',
  inputRef,
  onChange,
  readonly = false,
  labelChecks,
  loadState,
  maxLength,
  textPrefix = '',
  emptyMessage = '',
}) => {
  const {
    values,
    errors,
    // touched,
    setFieldValue,
    setFieldTouched,
    validationSchema,
    validateOnChange,
  } = formHandle;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const value = lodashGet(values, name);
  // const touch = lodashGet(touched, name);
  const error = getErrorText(lodashGet(errors, name) as string | string[]);
  const required = fieldIsRequired(name, validationSchema);

  const handleFormChange = (val: InputControlValue) => {
    setFieldValue(name, val, validateOnChange);

    // https://github.com/jaredpalmer/formik/issues/2059
    setTimeout(() => {
      setFieldTouched(name, validateOnChange);
    });

    onChange && onChange(val);
  };

  const formProps: FormProps = {
    name,
    label,
    disabled,
    autoFocus,
    error,
    fullWidth,
    required,
    ref: inputRef,
    readonly,
  };

  switch (type) {
    case 'text':
    case 'email':
    case 'url':
    case 'number':
    case 'password':
    case 'textarea':
    default: {
      let inputType = 'text';
      if (['password', 'textarea', 'url', 'number'].includes(type)) {
        inputType = type;
      }

      return (
        <TextInput
          {...formProps}
          onChange={handleFormChange}
          onEnter={onEnter}
          value={value as string}
          type={inputType}
          autoComplete={autoComplete}
          rows={rows}
          maxLength={maxLength}
          textPrefix={textPrefix}
        />
      );
    }
    case 'checkbox': {
      let checked = value as boolean;

      // formik passes an array for some reason
      if (Array.isArray(value)) {
        checked = Boolean(value.length) && value[0] === 'on';
      }

      return (
        <Checkbox
          {...formProps}
          checked={checked}
          onChange={handleFormChange}
          labelChecks={labelChecks}
        />
      );
    }
    case 'switch': {
      let checked = value as boolean;

      // formik passes an array for some reason
      if (Array.isArray(value)) {
        checked = Boolean(value.length) && value[0] === 'on';
      }

      return <Switch {...formProps} checked={checked} onChange={handleFormChange} />;
    }
    case 'switch-list':
      return (
        <SwitchList
          {...formProps}
          value={value as string[]}
          options={options}
          onChange={handleFormChange}
          height={height}
          emptyMessage={emptyMessage}
        />
      );
    case 'date':
      return <DateInput {...formProps} value={value as Date} onChange={handleFormChange} />;
    case 'date-range':
      return (
        <DateRangeInput
          {...formProps}
          value={value as DateRangeValue}
          onChange={handleFormChange}
        />
      );
    case 'select':
      return (
        <Select
          {...formProps}
          value={value as string}
          options={options}
          onChange={handleFormChange}
          size={size}
          fullWidth
        />
      );
    case 'select-multi': {
      const handleSelectMultiChange = (options: UiOption[]) => {
        handleFormChange(options.map(({ value }) => value));
      };

      return (
        <SelectMulti
          {...formProps}
          value={value as string[]}
          options={options}
          onChange={handleSelectMultiChange}
        />
      );
    }
    case 'list-toggle': {
      const handleListToggleChange = (options: UiOption[]) => {
        handleFormChange(options.map(({ value }) => value));
      };

      return (
        <ListToggle
          {...formProps}
          value={value as string[]}
          options={options}
          onChange={handleListToggleChange}
          leftTitle={leftTitle}
          rightTitle={rightTitle}
          height={height}
          labelChecks={labelChecks}
          loadState={loadState}
        />
      );
    }

    case 'groups-list-toggle': {
      const handleListToggleChange = (options: UiOption[]) => {
        handleFormChange(options.map(({ value }) => value));
      };

      return (
        <GroupsListToggle
          {...formProps}
          value={value as string[]}
          onChange={handleListToggleChange}
          options={options}
        />
      );
    }

    case 'public-service-acl': {
      const handleListToggleChange = (options: UiOption[]) => {
        handleFormChange(options.map(({ value }) => value));
      };

      return (
        <PublicServiceAcl
          {...formProps}
          value={value as string[]}
          onChange={handleListToggleChange}
        />
      );
    }

    case 'list-editor': {
      const safeValue = value ? (value as string[]) : [];

      const getOptions = (value: string[]) =>
        value.map((val: string) => ({
          label: val,
          value: val,
        }));

      const handleRemove = (_item: ListItem, index: number) => {
        const updatedValue = [...safeValue];
        updatedValue.splice(index, 1);
        handleFormChange(updatedValue);
      };
      const handleChange = (item: ListItem, editIndex: number) => {
        const updatedValue = [...safeValue];
        if (editIndex === -1) {
          updatedValue.unshift(item.value as string);
        } else {
          updatedValue[editIndex] = item.value as string;
        }

        handleFormChange(updatedValue);
      };

      return (
        <ListEditor
          items={getOptions(safeValue)}
          {...formProps}
          onRemove={handleRemove}
          onChange={handleChange}
          height={height}
          textPrefix={textPrefix}
        />
      );
    }

    case 'policy-public-action-select': {
      const handleChange = (value: boolean) => {
        handleFormChange(value);
      };

      return (
        <PolicyPublicActionSelect {...formProps} value={value as boolean} onChange={handleChange} />
      );
    }
  }
};

export default InputControl;
