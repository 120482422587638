import { Alert, Box } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  errors: FormikErrors<FormikValues>;
  validateOnChange: boolean;
}

const useStyles = createUseStyles({
  formMessage: {
    paddingTop: 2,
    paddingBottom: 2,
  },
});

const FormMessage: FC<Props> = ({ errors, validateOnChange }) => {
  const styles = useStyles();
  const hasErrors = Boolean(Object.keys(errors).length) && validateOnChange;
  return (
    <Box display="flex" alignItems="center" height="100%" color="error">
      {hasErrors && (
        <Alert severity="error" className={styles.formMessage}>
          Please resolve the above errors
        </Alert>
      )}
    </Box>
  );
};

export default FormMessage;
