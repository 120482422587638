import { Box, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { FC } from 'react';
import Button from '../../button';
import useModal from '../../../hooks/use-modal.hook';
import Modal from '..';
import { object, string } from 'yup';
import useFormHandle from '@/hooks/use-form-handle.hook';
import InputControl from '@/components/form/input-control';
import { Form } from '@/components/form';

export interface Props {
  title?: string;
  content: string;
  onSubmit: (values: { name: string }) => void;
}

export const NewWithNameModal: FC<Props> = ({ title = 'Confirm', content, onSubmit }) => {
  const { closeModal } = useModal();

  const formHandle = useFormHandle({
    initialValues: { name: '' },
    validationSchema: object({ name: string().required('Name is required') }),
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  const { handleSubmit } = formHandle;

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack>
          <Box>{content}</Box>
          <Box>
            <Form formHandle={formHandle}>
              <InputControl
                name="name"
                label="Name"
                formHandle={formHandle}
                autoFocus
                onEnter={() => handleSubmit()}
              />
            </Form>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button label="Cancel" type="cancel" onClick={() => handleClose()} />
        <Button onClick={() => handleSubmit()} label="Create" />
      </DialogActions>
    </Modal>
  );
};

export default NewWithNameModal;
