import axios from 'axios';
import Environment from '../environment';
import { DateRangeValue, JsonObject, getTimestamp } from '../helpers';
import { endOfDay, startOfDay } from 'date-fns';
import { DtSort } from '@/components/data-table/helpers';
import { AuthProvider } from './auth.service';
import { handleResponse } from '.';

export type FindAndCountResult = { documents: JsonObject[]; total: number };

export type MongoSort = Record<string, number> | Record<string, number>[];

export type MongoUpdateResult = { matchedCount: number; modifiedCount: number };

const axiosClient = axios.create({
  baseURL: `${Environment.SP_API_URL}/data`,
  timeout: 15000,
  responseType: 'json',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use((config) => {
  config.headers.jwtTokenString = AuthProvider.token;
  return config;
});

export const findOne = async (
  collection: string,
  params: JsonObject
): Promise<JsonObject | null> => {
  return axiosClient
    .post('/action/findOne', { ...getDefaultQueryParams(), collection, ...params })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.document as JsonObject) || null);
};

export const findAll = async (collection: string, params: JsonObject): Promise<JsonObject[]> => {
  return axiosClient
    .post('/action/find', { ...getDefaultQueryParams(), collection, ...params })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.documents as JsonObject[]) || []);
};

export const countAll = async (collection: string, filter: JsonObject): Promise<number> => {
  return axiosClient
    .post('/action/aggregate', {
      ...getDefaultQueryParams(),
      collection,
      pipeline: [
        {
          $match: filter,
        },
        {
          $group: {
            _id: null,
            count: { $sum: 1 },
          },
        },
      ],
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => {
      if (Array.isArray(response?.documents) && response.documents.length) {
        return ((response.documents[0] as JsonObject).count as number) || 0;
      }

      return 0;
    });
};

export const findAllAndCount = async (
  collection: string,
  filter: JsonObject,
  limit: number,
  skip = 0,
  sort?: MongoSort | null
): Promise<FindAndCountResult> => {
  return axiosClient
    .post('/action/aggregate', {
      ...getDefaultQueryParams(),
      collection,
      pipeline: [
        { $match: filter },
        { $sort: sort || { _id: -1 } },
        {
          $facet: {
            metadata: [{ $count: 'total' }],
            data: [{ $skip: skip }, { $limit: limit }],
          },
        },
      ],
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => {
      if (!response) {
        return { documents: [], total: 0 };
      }

      let documents: JsonObject[] = [];
      let total = 0;

      try {
        const { data, metadata } = (response.documents as JsonObject[])[0] as {
          data: JsonObject[];
          metadata: [{ total: number }];
        };

        if (!data?.length || !metadata?.length) {
          return { documents: [], total: 0 };
        }

        documents = data;
        total = metadata[0].total;
      } catch (err) {
        console.error('unexpected response format from aggregation query');
        console.error(err);
        console.log(response);
        return { documents: [], total: 0 };
      }

      return { documents, total };
    });
};

export const aggregate = async (
  collection: string,
  pipeline: JsonObject[]
): Promise<JsonObject[]> => {
  return axiosClient
    .post('/action/aggregate', { ...getDefaultQueryParams(), collection, pipeline })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.documents as JsonObject[]) || []);
};

export const insertOne = async (
  collection: string,
  document: JsonObject
): Promise<string | null> => {
  delete document.id;
  delete document._id;
  document._lastChangedAt = new Date();
  document._createdAt = new Date();

  return axiosClient
    .post('/action/insertOne', {
      ...getDefaultQueryParams(),
      collection,
      document,
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.insertedId as string) || '');
};

export const patchOne = async (
  collection: string,
  filter: JsonObject,
  data: JsonObject
): Promise<JsonObject | null> => {
  return axiosClient
    .post('/action/updateOne', {
      ...getDefaultQueryParams(),
      collection,
      filter,
      update: {
        $set: {
          ...data,
          _lastChangedAt: new Date(),
        },
      },
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.document as JsonObject) || null);
};

export const update = async (
  collection: string,
  filter: JsonObject,
  update: JsonObject
): Promise<MongoUpdateResult | null> => {
  const compUpdate = { ...update };
  if (!compUpdate.$set) {
    compUpdate.$set = {};
  }

  (compUpdate.$set as JsonObject)._lastChangedAt = new Date();

  return axiosClient
    .post('/action/updateMany', {
      ...getDefaultQueryParams(),
      collection,
      filter,
      update: compUpdate,
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response as MongoUpdateResult) || null);
};

export const deleteOne = async (collection: string, filter: JsonObject): Promise<boolean> => {
  return axiosClient
    .post('/action/deleteOne', {
      ...getDefaultQueryParams(),
      collection,
      filter,
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => response?.deletedCount === 1);
};

export const getOrgFilter = (): JsonObject => {
  return { orgId: { $oid: AuthProvider.orgId || null } };
};

type MongoDateFilter = { $gte?: number; $lte?: number };

// @todo only supports timestamp fields
export const getDateRangeFilter = (value: DateRangeValue, useMs = true): MongoDateFilter | null => {
  if (!value.length) {
    return null;
  }

  const filter: MongoDateFilter = {};

  if (value[0]) {
    const startTimestamp = getTimestamp(startOfDay(value[0]));
    if (typeof startTimestamp === 'number') {
      filter.$gte = useMs ? startTimestamp * 1000 : startTimestamp;
    }
  }

  if (value[1]) {
    const endTimestamp = getTimestamp(endOfDay(value[1]));
    if (typeof endTimestamp === 'number') {
      filter.$lte = useMs ? endTimestamp * 1000 : endTimestamp;
    }
  }

  return filter;
};

export const getSort = (dtSort: DtSort | undefined): MongoSort | null => {
  if (!dtSort) {
    return null;
  }

  const { columnName, direction } = dtSort;

  return {
    [columnName]: direction === 'asc' ? 1 : -1,
  };
};

export const getOidParam = (id: string) => ({ $oid: id });

export const getOidParams = (ids: string[]) => ids.map(getOidParam);

export const getAccessListOidParams = (data: JsonObject) => {
  const accessList: { enabled: { $oid: string }[]; disabled: { $oid: string }[] } = {
    enabled: [],
    disabled: [],
  };

  if (data.enabled) {
    accessList.enabled = getOidParams(data.enabled as string[]);
  }
  if (data.disabled) {
    accessList.disabled = getOidParams(data.disabled as string[]);
  }

  return accessList;
};

const getDefaultQueryParams = (): { dataSource: string; database: string } => {
  return { dataSource: Environment.ATLAS_DATASOURCE, database: Environment.ATLAS_DATABASE };
};
