import { FC, useMemo } from 'react';
import { PolicyModel } from '@/lib/models/policy.model';
import UsageCard from '../usage-card';
import Icon from '../icon';
import { Link } from '@tanstack/react-router';

interface Props {
  policies: PolicyModel[];
}

const PolicyUsageCard: FC<Props> = ({ policies }) => {
  const items = useMemo(() => {
    return policies.map(({ id, name, scope }) => {
      const isOrgPolicy = scope === 'org';

      const link = isOrgPolicy ? '/config/default-policy' : `/config/group-policies/${id}`;
      return {
        id,
        name,
        BadgeEl: isOrgPolicy ? <Icon name="organization" size="small" /> : undefined,
        LinkEl: (
          <Link to={link}>
            <Icon name="arrow-right" size="small" block />
          </Link>
        ),
      };
    });
  }, [policies]);

  return <UsageCard title="Policies" items={items} icon="policy" />;
};

export default PolicyUsageCard;
