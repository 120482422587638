import ErrorPage from '@/pages/error';
import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

interface Props {
  children: ReactNode;
}

const Fallback: FC<{ error: Error }> = ({ error }) => {
  return (
    <Box sx={{ marginTop: 20 }}>
      <ErrorPage error={error} />
    </Box>
  );
};

const ErrorBoundary: FC<Props> = ({ children }) => {
  return <ReactErrorBoundary FallbackComponent={Fallback}>{children}</ReactErrorBoundary>;
};

export default ErrorBoundary;
