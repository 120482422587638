import { object, string } from 'yup';
import { BaseMeta, BaseModel } from './base.model';
import { JsonObject } from '../helpers';

export type GroupMeta = BaseMeta & {
  name: string;
  policies: string[];
  isExternal: boolean;
};

type GroupExtRef = {
  workos: {
    workosGroupId: string;
    workosOrganizationId: string | null;
    workosDirectoryId: string;
    workosCreatedAt: string;
    workosUpdatedAt: string;
  };
};

export class GroupModel extends BaseModel {
  orgId: string;
  name: string;
  description: string;
  externalReferences: GroupExtRef;
  createdAt: string;
  updatedAt: string;
  policies: string[];

  schema = object({
    name: string().required('Name is required'),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof GroupModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.description = (data.description as string) || '';

    this.externalReferences = (data.externalReferences as GroupExtRef) || {
      workos: {
        workosGroupId: '',
        workosOrganizationId: null,
        workosDirectoryId: '',
        workosCreatedAt: '',
        workosUpdatedAt: '',
      },
    };
    this.createdAt = (data.createdAt as string) || '';
    this.updatedAt = (data.updatedAt as string) || '';
    this.policies = (data.policies as string[]) || [];
  }

  get meta(): GroupMeta {
    return {
      id: this.id || '',
      name: this.name,
      policies: this.policies,
      isExternal: this.isExternal,
    };
  }

  get source(): string {
    if (this.isExternal) {
      return 'sync';
    }

    return 'surepath';
  }

  get sourceLabel(): string {
    if (this.source === 'sync') {
      return 'Sync';
    }

    return 'SurePath AI';
  }

  get isExternal(): boolean {
    return !!this.externalReferences?.workos?.workosGroupId;
  }

  get canDelete(): boolean {
    return !this.isExternal;
  }

  get canEdit(): boolean {
    return !this.isExternal;
  }
}
