import { FC, ReactNode } from 'react';
import ModelIcon, { ModelIconId } from '../model-icon';
import { Stack } from '@mui/material';
import Text from '../text';

interface Props {
  name: ReactNode;
  iconId: string;
}

const ModelOption: FC<Props> = ({ name, iconId }) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <ModelIcon iconId={iconId as ModelIconId} size="small" />
      <Text>{name}</Text>
    </Stack>
  );
};

export default ModelOption;
