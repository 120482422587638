import { JsonObject, UiOption } from '@/lib/helpers';
import { BaseMeta } from '../base.model';
import {
  WebcrawlerConfig,
  WebcrawlerContextProviderModel,
} from './webcrawler-context-provider.model';
import {
  SharepointConfig,
  SharepointContextProviderModel,
} from './sharepoint-context-provider.model';
import { ContextProviderModel } from './context-provider.model';
import { cloneDeep } from 'lodash';
import { AwsKbConfig, AwsKbContextProviderModel } from './aws-kb-context-provider.model';

export const DEFAULT_AWS_REGION = 'us-east-1';

export type ContextProviderMeta = BaseMeta & {
  name: string;
};

export type ContextProviderType =
  | 'surepath-kendra-sharepoint'
  | 'surepath-kendra-webcrawler'
  | 'customer-bedrock-knowledgebase'
  | 'unknown';

export type ContextProviderStatus =
  | 'pending'
  | 'active'
  | 'updating'
  | 'deleting'
  | 'error'
  | 'unknown';

export type KendraBaseConfig = {
  indexId: string;
  dataSourceId: string;
  region: string;
};

export type KendraMetrics = {
  succeededDocsCount: number;
  failedDocsCount: number;
  totalDocsCount: number;
};

export type ContextProviderConfig = SharepointConfig | WebcrawlerConfig | AwsKbConfig;

export const ContextProviderMap: Map<ContextProviderType, ContextProviderModel> = new Map();

ContextProviderMap.set('surepath-kendra-sharepoint', new SharepointContextProviderModel());
ContextProviderMap.set('surepath-kendra-webcrawler', new WebcrawlerContextProviderModel());
ContextProviderMap.set('customer-bedrock-knowledgebase', new AwsKbContextProviderModel());

export const ContextProviderTypeOptions: UiOption[] = Array.from(ContextProviderMap.entries()).map(
  ([type, model]) => {
    return {
      label: model.typeLabel,
      value: type as string,
    };
  }
);

export type ContextProviderScanSchedule = 'daily' | 'weekly' | 'monthly';

export const ContextProviderScanScheduleOptions: UiOption[] = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

// see getTypedConnector
export const getTypedContextProvider = (model: ContextProviderModel) => {
  const typedModel = ContextProviderMap.get(model.type);

  if (!typedModel) {
    return model;
  }

  const Constructor = typedModel.constructor as new (data?: JsonObject) => typeof typedModel;

  const config = {
    ...typedModel.config,
    ...model.config,
  };

  const data = cloneDeep({ ...model._props, config });

  return new Constructor(data)!;
};
