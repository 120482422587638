import { getPublicServiceAppData } from '@/lib/services/public-service.service';
import { QueryFunction } from 'react-query';
import queryClient, { AppDataQueryKey } from '@/lib/query-client';
import { getOrgBasicInfoAppData } from './services/org.service';
import { getRagStatus } from './services/context-provider.service';

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;

export type QueryOptions = {
  cacheTime?: number; // how long before garbage collection
  staleTime?: number; // how long before refetch
  enabled?: boolean;
};

export const AppDataQueries: Map<
  AppDataQueryKey,
  { queryFn: QueryFunction; options: QueryOptions }
> = new Map();

// Public Services Catalog
AppDataQueries.set(AppDataQueryKey.PublicServices, {
  queryFn: getPublicServiceAppData,
  options: {
    staleTime: HOUR * 24,
    cacheTime: HOUR * 48,
  },
});

// Org basic info
AppDataQueries.set(AppDataQueryKey.OrgBasicInfo, {
  queryFn: getOrgBasicInfoAppData,
  options: {
    staleTime: HOUR * 24,
    cacheTime: HOUR * 48,
  },
});

// Rag status
AppDataQueries.set(AppDataQueryKey.OrgRagStatus, {
  queryFn: getRagStatus,
  options: {
    staleTime: MINUTE,
    cacheTime: MINUTE * 2,
  },
});

export const prefetchAppData = async () => {
  const prefetchQueries: Promise<void>[] = [];

  for (const [queryKey, { queryFn, options }] of AppDataQueries) {
    prefetchQueries.push(queryClient.prefetchQuery(queryKey, queryFn, options));
  }

  return Promise.all(prefetchQueries);
};
