import { FC } from 'react';
import Chip from '.';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material';

interface Props {
  notAvailable?: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  noneChip: {
    opacity: 0.5,
    border: `1px dashed ${theme.palette.grey[400]}`,
    background: 'none',
  },
}));

const NoneChip: FC<Props> = ({ notAvailable }) => {
  const styles = useStyles();
  return <Chip label={notAvailable ? 'n/a' : 'none'} size="small" className={styles.noneChip} />;
};

export default NoneChip;
