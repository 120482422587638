import Text from '@/components/text';
import { Box, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import AddCard from './add-card';
import { GridCardCategory, GridCard as GridCardProps } from './helpers';
import GridCard from './grid-card';
import EmptyCard from './empty-card';

const useStyles = createUseStyles((theme: Theme) => ({
  cardGridHeader: {
    padding: '0px 0px 8px 0px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: 16,
  },
}));

interface Props {
  title?: string;
  onAdd?: () => void;
  cards: GridCardProps[];
  categories?: GridCardCategory[];
  canAdd?: boolean;
  showAdd?: boolean;
  addTitle?: string;
  addDisabledTooltip?: string;
  showEmpty?: boolean;
}

const CardGrid: FC<Props> = ({
  title,
  onAdd,
  canAdd = true,
  showAdd = true,
  cards,
  addTitle = '',
  addDisabledTooltip,
  categories = [],
  showEmpty = false,
}) => {
  const styles = useStyles();

  const handleAdd = () => {
    onAdd?.();
  };

  const hasTitle = !!title;
  const hasCategories = !!categories.length;
  const showEmptyCard = showEmpty && !cards.length;

  return (
    <Box width="100%">
      {hasTitle && (
        <Stack className={styles.cardGridHeader}>
          <Text size="large">{title}</Text>
        </Stack>
      )}
      <Stack gap={2} flexWrap="wrap" flexDirection="row" width="100%">
        {hasCategories &&
          categories.map(({ title, id: categoryId }) => {
            const categoryCards = cards.filter(
              ({ categoryId: cardCatId }) => cardCatId === categoryId
            );

            return (
              <Box key={categoryId} width="100%" mb={2}>
                <Box mb={1}>
                  <Text color="grey">{title}</Text>
                </Box>
                <Stack gap={2} flexWrap="wrap" flexDirection="row">
                  {categoryCards.map((gridCard) => {
                    const { id } = gridCard;
                    return <GridCard gridCard={gridCard} key={id} />;
                  })}
                </Stack>
              </Box>
            );
          })}
        {!hasCategories &&
          cards.map((gridCard) => {
            const { id } = gridCard;
            return <GridCard gridCard={gridCard} key={id} />;
          })}

        {showEmptyCard && <EmptyCard />}

        {showAdd && (
          <AddCard
            title={addTitle}
            disabled={!canAdd}
            onClick={handleAdd}
            disabledTooltip={addDisabledTooltip}
          />
        )}
      </Stack>
    </Box>
  );
};

export default CardGrid;
