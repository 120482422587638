import { Box, FormHelperText, List, ListItem, Stack, Theme } from '@mui/material';
import { FC, ReactNode, useRef, useState } from 'react';
import Icon from '@/components/icon';
import { FormProps } from '../helpers';
import { createUseStyles } from 'react-jss';
import TextInput from '../text-input';
import Text from '@/components/text';
import { DateRangeValue, removePrefix, UiOption } from '@/lib/helpers';
import Button from '@/components/button';

export type ListItem = {
  label: string;
  value: string | string[] | DateRangeValue | UiOption;
  meta?: Record<string, unknown>;
};

interface Props extends FormProps {
  items: ListItem[];
  onChange: (item: ListItem, editIndex: number) => void;
  onRemove: (item: ListItem, index: number) => void;
  onInput?: (onChange: (item: ListItem) => void, item: ListItem, editIndex: number) => ReactNode;
  onItemView?: (item: ListItem) => ReactNode;
  height?: number | string;
  textPrefix?: string;
}

const useStyles = createUseStyles<string, { height: string | number }>((theme: Theme) => ({
  listEditor: ({ height }) => ({
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    '& ul': {
      height: height || 200,
      overflowY: 'auto',
      paddingTop: 0,
    },
    '& li': {
      cursor: 'pointer',
      paddingTop: 0,
      '& svg.MuiSvgIcon-root': {
        opacity: 0.4,
        '&:hover': {
          opacity: 1,
        },
      },
      '&:hover': {
        '& svg.MuiSvgIcon-root': {
          opacity: 0.8,
        },
      },
    },
    '& .MuiFormControl-root': {
      padding: 0,
      margin: 0,
    },
  }),
  listItem: {
    padding: '4px 0px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  header: {
    padding: 10,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: 10,
  },
}));

const removeItemPrefix = (item: ListItem, textPrefix: string): ListItem => {
  const editItem = { ...item };

  if (textPrefix && typeof editItem.value === 'string') {
    editItem.value = removePrefix(editItem.value, textPrefix);
  }

  return editItem;
};

const addItemPrefix = (item: ListItem, textPrefix: string): ListItem => {
  const editItem = { ...item };

  if (textPrefix && typeof editItem.value === 'string') {
    editItem.value = `${textPrefix}${editItem.value}`;
  }

  return editItem;
};

const ListEditor: FC<Props> = ({
  label,
  error,
  items,
  onChange,
  onRemove,
  onInput,
  onItemView,
  readonly = false,
  height = '',
  required = false,
  textPrefix = '',
}) => {
  const styles = useStyles({ height });
  const hasError = Boolean(error);
  const [modItem, setModItem] = useState<ListItem>({ label: '', value: '' });
  const [editIndex, setEditIndex] = useState<number>(-1);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    if (!modItem.value) {
      return;
    }

    const editItem = addItemPrefix(modItem, textPrefix);

    onChange(editItem, editIndex);
    setEditIndex(-1);
    setModItem({ label: '', value: '' });
  };

  const handleEdit = (item: ListItem, index: number) => {
    setEditIndex(index);
    const editItem = removeItemPrefix(item, textPrefix);
    setModItem(editItem);
    inputRef.current?.focus();
  };

  const handleCancel = () => {
    setEditIndex(-1);
    setModItem({ label: '', value: '' });
  };

  const handleInputChange = (value: string) => {
    setModItem((old) => ({
      ...old,
      value,
    }));
  };

  const editing = editIndex > -1;
  const hasLabel = Boolean(label);
  const hasCustomInput = Boolean(onInput);
  const canEdit = !readonly;
  const canAdd = !readonly && Boolean(modItem.value);

  return (
    <Stack gap={1} className={styles.listEditor}>
      {hasLabel && (
        <Box className={styles.header}>
          <Text bold>
            {label}
            {required && ' *'}
          </Text>
        </Box>
      )}
      <List>
        {items.map((item, index) => {
          const ItemViewEl: ReactNode = onItemView ? onItemView(item) : item.label;
          return (
            <ListItem key={index}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                className={styles.listItem}
              >
                <Box flexGrow={1}>{ItemViewEl}</Box>
                <Stack
                  width="60px"
                  gap={0.5}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {canEdit && (
                    <>
                      <Icon
                        name="edit"
                        disabled={editing}
                        onClick={() => handleEdit(item, index)}
                      />
                      <Icon
                        name="delete"
                        disabled={editing}
                        color="error"
                        onClick={() => onRemove(item, index)}
                      />
                    </>
                  )}
                </Stack>
              </Stack>
            </ListItem>
          );
        })}
      </List>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        width="100%"
        className={styles.footer}
      >
        <Box flexGrow={1}>
          {canEdit &&
            (hasCustomInput ? (
              onInput!(setModItem, modItem, editIndex)
            ) : (
              <TextInput
                size="small"
                name="list-value"
                value={modItem.value as string}
                onChange={handleInputChange}
                onEnter={handleChange}
                ref={inputRef}
                textPrefix={textPrefix}
              />
            ))}
        </Box>
        <Stack minWidth="30px" direction="row" gap={0.5} justifyContent="flex-end">
          {canEdit && (
            <>
              <Button
                size="small"
                label={editing ? 'Save' : 'Add'}
                onClick={handleChange}
                disabled={!canAdd}
              />
              {editing && (
                <Button
                  size="small"
                  label="Cancel"
                  type="cancel"
                  onClick={handleCancel}
                  disabled={!canAdd}
                />
              )}
            </>
          )}
        </Stack>
      </Stack>
      {hasError && (
        <Box px={1} pb={1}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      )}
    </Stack>
  );
};

export default ListEditor;
