import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import AzureIcon from '@/assets/connector-icons/azure.svg';
import { ConnectorModel } from './connector.model';

export type AzureOpenAIConfig = {
  accountId?: string;
};

export class AzureOpenAIConnectorModel extends ConnectorModel {
  config: AzureOpenAIConfig;

  // @todo schema extends base and covers config

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'azure-openai';
    this.typeLabel = 'Azure OpenAI';
    this.iconSVG = AzureIcon;

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: true,
      contextData: false,
      ingress: false,
    };

    this.config = (data.config as AzureOpenAIConfig) || {
      accountId: '',
    };

    this.comingSoon = true;
  }
}
