import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import { ConnectorModel } from './connector.model';
import NetskopeLogo from '@/assets/connector-icons/netskope.png';

export type NetskopeConfig = {
  xAuthenticatedUserEnabled: boolean;
  tenantInfo: string;
};

export class NetskopeConnectorModel extends ConnectorModel {
  config: NetskopeConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'netskope';
    this.typeLabel = 'Netskope';
    this.iconSVG = NetskopeLogo;

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: false,
      contextData: false,
      ingress: true,
    };

    this.config = (data.config as NetskopeConfig) || {
      xAuthenticatedUserEnabled: false,
      tenantInfo: '',
    };

    this.highlander = true;
  }
}
