import { Box } from '@mui/material';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { DomSizeContext } from './helpers';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
}

const useStyles = createUseStyles({
  sizeContext: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },
});

const DomSize: FC<Props> = ({ children }) => {
  const [size, setSize] = useState({ height: 0, width: 0 });
  const ref = useRef<HTMLDivElement>(null);
  const styles = useStyles();

  const handleGetSize = useCallback(() => {
    const container = ref.current;

    if (!container) {
      return { height: 0, width: 0 };
    }

    return {
      height: container.clientHeight,
      width: container.clientWidth,
    };
  }, []);

  useEffect(() => {
    setSize(handleGetSize());
  }, [handleGetSize]);

  return (
    <DomSizeContext.Provider value={{ ...size, getSize: handleGetSize }}>
      <Box ref={ref} className={styles.sizeContext}>
        {children}
      </Box>
    </DomSizeContext.Provider>
  );
};

export default DomSize;
