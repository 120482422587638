import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as JssThemeProvider } from 'react-jss';
import Theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import { AppProvider } from './contexts/app';
import { QueryClientProvider } from 'react-query';
import queryClient from './lib/query-client';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ModalProvider } from './contexts/modal';
import RouteProvider from './router/provider';
import { initDatadog } from './lib/datadog';
import Environment from './lib/environment';
import ErrorBoundary from './components/error-boundary';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { EmitterProvider } from './contexts/emitter';
import AssumeRoleToast from './components/toasts/assume-role';

initDatadog();
console.log(`build v${APP_VERSION}-${Environment.MODE}`);

// https://notistack.com/features/customization#custom-variant-(typescript)
declare module 'notistack' {
  interface VariantOverrides {
    assumeRole: true;
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MuiThemeProvider theme={Theme}>
      <JssThemeProvider theme={Theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <ErrorBoundary>
            <AppProvider>
              <EmitterProvider>
                <QueryClientProvider client={queryClient}>
                  <HotkeysProvider>
                    <ModalProvider>
                      <SnackbarProvider Components={{ assumeRole: AssumeRoleToast }}>
                        <RouteProvider />
                      </SnackbarProvider>
                    </ModalProvider>
                  </HotkeysProvider>
                </QueryClientProvider>
              </EmitterProvider>
            </AppProvider>
          </ErrorBoundary>
        </LocalizationProvider>
      </JssThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>
);
