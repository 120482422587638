import { Stack, IconButton, Theme, Toolbar, Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Icon from '../icon';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import useApp from '../../hooks/use-app.hook';
import Text from '../text';
import Logo from '../../assets/logo.svg';
import Breadcrumbs from './breadcrumbs';
import Loader from '../loader';
import UserMenu from './user-menu';
import Chip from '../chip';
import useFeature from '@/hooks/use-feature';

interface Props {
  open: boolean;
  onToggleOpen: () => void;
  width: number;
  signedIn: boolean;
  hasBanner?: boolean;
}

const useStyles = createUseStyles<
  string,
  { hasBanner: boolean; width: number; open: boolean; signedIn: boolean }
>((theme: Theme) => ({
  header: ({ hasBanner, width, open, signedIn }) => ({
    backgroundColor: signedIn ? theme.palette.grey[200] : theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    position: 'absolute',
    top: hasBanner ? 60 : 0,
    zIndex: theme.zIndex.appBar,
    height: 64, // affects height rules in app-header and app-sidebar. if you change this, you MUST change those
    boxSizing: 'border-box',
    overflow: 'hidden',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: width,
      width: `calc(100% - ${width}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }),
  headerInner: {
    paddingRight: 24,
  },
  pageTitle: {
    flexGrow: 1,
    color: theme.palette.common.black,
  },
  sidebarButton: {
    marginRight: 30,
  },
  logo: {
    width: 150,
  },
  logoLink: {
    '&:hover': {
      opacity: 1,
    },
  },
  userMenu: {
    padding: 0,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& > .MuiFab-root': {
      boxShadow: 'none',
      background: 'none',
    },
    '& .MuiSpeedDial-actions': {
      paddingBottom: 36,
    },
  },
  modeBadge: {
    backgroundColor: '#ebfeff !important',
    '& .MuiChip-label': {
      color: '#00adb6 !important',
    },
  },
}));

const AppHeader: FC<Props> = ({ open, onToggleOpen, width, signedIn, hasBanner = false }) => {
  const styles = useStyles({ hasBanner, signedIn, open: signedIn && open, width });
  const { pageTitle, pageLoadState } = useApp();
  const { breadcrumb, plain } = pageTitle;
  const hasCrumbs = !!breadcrumb;
  const pageLoading = pageLoadState === 'loading';

  const { orgMode } = useFeature();
  const hasTrial = orgMode === 'trial';
  const hasDiscovery = orgMode === 'discovery';

  return (
    <AppBar className={styles.header} elevation={0}>
      <Toolbar className={styles.headerInner}>
        {!signedIn && (
          <a href="/" className={styles.logoLink}>
            <img src={Logo} alt="SurePath.ai" className={styles.logo} />
          </a>
        )}
        {signedIn && !open && (
          <IconButton
            edge="start"
            color="primary"
            onClick={onToggleOpen}
            className={styles.sidebarButton}
          >
            <Icon name="menu" />
          </IconButton>
        )}
        {signedIn && (
          <Stack
            direction="row"
            gap={0.5}
            alignItems="center"
            className={styles.pageTitle}
            justifyContent="flex-start"
            width="100%"
          >
            {hasCrumbs && <Breadcrumbs breadcrumb={breadcrumb} pageTitle={plain} />}
            {!hasCrumbs && (
              <Text size="x-large" noWrap>
                {plain}
              </Text>
            )}
            <Stack
              flexGrow={1}
              flexDirection="row"
              justifyContent="flex-end"
              gap={3}
              alignItems="center"
            >
              <Box width={50}>{pageLoading && <Loader />}</Box>

              {signedIn && hasTrial && (
                <Chip label="TRIAL MODE" color="success" className={styles.modeBadge} />
              )}
              {signedIn && hasDiscovery && (
                <Chip label="DISCOVERY MODE" color="success" className={styles.modeBadge} />
              )}

              {signedIn && <UserMenu />}
            </Stack>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
