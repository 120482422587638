import { FC, Fragment } from 'react';
import { NavigateOptions, useNavigate } from '@tanstack/react-router';
import ListItemText from '@mui/material/ListItemText';
import { Box, Divider, Drawer, List, ListSubheader, Theme, Toolbar } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { Scrollbars } from 'react-custom-scrollbars-2';
import SurepathLogoWhite from '../../assets/logo-white-0924.svg';
import useApp from '@/hooks/use-app.hook';
import { openNewTab, pathIsExternal } from '@/lib/url-helpers';
import { SidebarSection } from './helpers';
import MenuItem from './menu-item';
import useFeature from '@/hooks/use-feature';

interface Props {
  width: number;
  open: boolean;
  onToggleOpen: () => void;
}

const SidebarSections: SidebarSection[] = [
  {
    key: 'observe',
    title: 'Observe',
    items: [
      {
        key: 'user-events',
        label: 'User Activity',
        icon: 'chat',
        link: '/user-events',
      },
      {
        key: 'insights',
        label: 'Insights',
        icon: 'bar-chart',
        link: '/home',
      },
    ],
  },
  {
    key: 'govern',
    title: 'Govern',
    items: [
      {
        key: 'default-policy',
        label: 'Default Policy',
        link: '/config/default-policy',
        icon: 'policy',
      },
      {
        key: 'group-policies',
        label: 'Group Policies',
        link: '/config/group-policies',
        icon: 'group',
      },
      {
        key: 'public-services',
        label: 'Public Services',
        icon: 'globe',
        link: '/config/public-services',
      },
      {
        key: 'private-models',
        label: 'Private Models',
        icon: 'select-all',
        link: '/config/private-models',
      },
      {
        key: 'data-sources',
        label: 'Data Sources',
        icon: 'doc-search',
        link: '/config/data-sources',
      },
      {
        key: 'agents',
        label: 'Assistants',
        icon: 'assistant',
        link: '/config/assistants',
      },
    ],
  },

  {
    key: 'configure',
    title: 'Configure',
    items: [
      {
        key: 'org-settings',
        label: 'Organization',
        icon: 'organization',
        link: '/config/org-settings',
      },
      {
        key: 'identity',
        label: 'Users and Groups',
        icon: 'person',
      },
      {
        key: 'admin-users',
        label: 'Admin Users',
        link: '/config/admin-users',
        feature: 'canManageAdmins',
        parent: 'identity',
      },
      {
        key: 'users',
        label: 'End Users',
        link: '/config/end-users',
        parent: 'identity',
      },
      {
        key: 'groups',
        label: 'Groups',
        link: '/config/groups',
        parent: 'identity',
      },

      {
        key: 'connectors',
        label: 'Connectors',
        link: '/config/connectors',
        icon: 'cloudsync',
      },
    ],
  },
];

const useStyles = createUseStyles<
  string,
  { width: string | number; open: boolean; assumeActive: boolean }
>((theme: Theme) => ({
  drawer: ({ width, open }) => ({
    position: 'relative',
    whiteSpace: 'nowrap',
    width,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.surface.primary.main,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
    zIndex: theme.zIndex.appBar - 10,
    fontSize: theme.typography.body1.fontSize,
    height: '100%',
  }),
  drawerHeader: {
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 4px 0px 12px',
  },
  sectionHeader: {
    marginLeft: 3,
    marginRight: 3,
    background: 'none',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
  },
  scrollContainer: {
    width: '100%',
    // account for app-header height, and assume-role banner height. If you changes those, you MUST change this
    height: ({ assumeActive }) => (assumeActive ? 'calc(100% - 124px)' : 'calc(100% - 64px)'),
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      scrollbarWidth: 'thin',
      scrollbarColor: '#ffffff35 transparent',
    },
    '& div::-webkit-scrollbar': {
      width: 10,
      backgroundColor: 'transparent',
    },
    '& div::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      '-webkit-box-shadow': 'none',
    },
    '& div::-webkit-scrollbar-thumb': {
      backgroundColor: '#ffffff35',
      outline: 'none',
      borderRadius: theme.shape.borderRadius,
    },
  },
  scrollThumbVert: {
    backgroundColor: '#ffffff45',
    borderRadius: theme.shape.borderRadius,
  },
  scrollHideHorizontal: {
    display: 'none',
  },
  scrollView: {
    overflowX: 'hidden !important',
    height: '100%',
  },
  divider: {
    margin: '10px 0px',
    borderColor: theme.palette.grey[600],
  },
  logo: {
    width: 150,
  },
  userMenu: ({ open }) => ({
    padding: open ? 15 : 0,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& > .MuiFab-root': {
      boxShadow: 'none',
      background: 'none',
    },
    '& .MuiSpeedDial-actions': {
      paddingBottom: 36,
    },
  }),
}));

const AppSidebar: FC<Props> = ({ width, open }) => {
  const navigate = useNavigate();
  const { user } = useApp();
  const assumeActive = !!user?.assumed;
  const { hasFeature } = useFeature();

  const handleNavigate = (to: string, target = '') => {
    if (pathIsExternal(to)) {
      openNewTab(to, target);
      return;
    }
    navigate({ to } as NavigateOptions);
  };

  /*
   * Opening a sub-item menu may cause the sidebar to expand below the bottom of the viewport, but this
   * unfortunately does not trigger the custom scrollbars to appear, event though they are active. Fire
   * a window resize event to trigger the force-update function of the scrollbar component.
   *
   * There might be some unintended side-effects, but afaik there is no other solution outside forking
   * the react-custom-scrollbars-2 repo and exposing the forceUpdate function.
   */
  const handleMenuOpen = () => {
    window.dispatchEvent(new Event('resize'));
  };

  const styles = useStyles({ width, open, assumeActive });

  return (
    <Drawer
      open={open}
      PaperProps={{ className: styles.drawer }}
      variant="permanent"
      anchor="left"
      id="app-sidebar"
    >
      <Toolbar className={styles.drawerHeader}>
        <img src={SurepathLogoWhite} alt="SurePath AI" className={styles.logo} />
      </Toolbar>
      <List component="nav" className={styles.scrollContainer}>
        <Scrollbars
          renderView={(props) => <div {...props} className={styles.scrollView} />}
          renderThumbVertical={(props) => <div {...props} className={styles.scrollThumbVert}></div>}
          renderTrackHorizontal={(props) => (
            <div {...props} className={styles.scrollHideHorizontal}></div>
          )}
          renderThumbHorizontal={(props) => (
            <div {...props} className={styles.scrollHideHorizontal}></div>
          )}
          autoHide={false}
        >
          {SidebarSections.map(({ title, items, key }, idx) => {
            const hasTitle = Boolean(title);
            const DividerEl =
              idx < SidebarSections.length - 1 ? <Divider className={styles.divider} /> : null;

            const allowedItems = items.filter(({ feature }) => !feature || hasFeature(feature));
            const parentItems = allowedItems.filter(({ parent }) => !parent);

            return (
              <Box key={key}>
                {open && hasTitle && (
                  <ListSubheader component="div" className={styles.sectionHeader} disableSticky>
                    <ListItemText
                      primaryTypographyProps={{ className: styles.sectionHeader }}
                      primary={title}
                    />
                  </ListSubheader>
                )}
                {parentItems.map((item) => {
                  const { key } = item;
                  const subItems = allowedItems.filter(({ parent }) => parent === key);

                  return (
                    <Fragment key={key}>
                      <MenuItem
                        item={item}
                        onNavigate={handleNavigate}
                        open={open}
                        subItems={subItems}
                        onOpen={handleMenuOpen}
                      />
                    </Fragment>
                  );
                })}
                {DividerEl}
              </Box>
            );
          })}
        </Scrollbars>
      </List>
    </Drawer>
  );
};

export default AppSidebar;
