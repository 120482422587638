import { FC, useEffect, useState } from 'react';
import Page, { PageProps } from '../../components/page';
import { Box, Stack } from '@mui/material';
import Icon from '@/components/icon';
import { signOut } from '@/lib/services/auth.service';
import Chip, { ChipColor } from '@/components/chip';
import Card from '@/components/card';

/* This is a utility page that is not linked anywhere, but allows
 * you to clear your firebase local storage. Helpful if you end up in a
 * borked auth state.
 */

const LogoutPage: FC<PageProps> = () => {
  const [loggedOut, setLoggedOut] = useState<boolean | null>(null);

  useEffect(() => {
    signOut(false).then(setLoggedOut);
  }, []);

  let chipLabel = 'pending';
  let chipColor: ChipColor = 'warning';

  if (loggedOut !== null) {
    chipColor = loggedOut ? 'success' : 'error';
    chipLabel = loggedOut ? 'SUCCESS' : 'FAILED';
  }

  return (
    <Page>
      <Box width={400} mt={6} mx="auto">
        <Card>
          <Stack alignItems="center" justifyContent="center" gap={4}>
            <Box>
              <Icon name="lock-person" color="active" />
            </Box>
            <Box>Deleting local credentials...</Box>
            <Chip label={chipLabel} color={chipColor} />
          </Stack>
        </Card>
      </Box>
    </Page>
  );
};

export default LogoutPage;
