import {
  Select as MuiSelect,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  Theme,
  Stack,
  Checkbox,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';
import FormControl from '../form-control';
import { FormProps } from '../helpers';
import { createUseStyles } from 'react-jss';
import { UiOption } from '@/lib/helpers';
import Text from '@/components/text';

interface Props extends FormProps {
  value: string[];
  options: UiOption[];
  size?: 'medium' | 'small';
  onChange: (options: UiOption[]) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  input: {
    '& fieldset': {
      borderWidth: '1px !important',
    },
    '&.Mui-disabled fieldset': {
      borderColor: `${theme.palette.grey[600]} !important`,
    },
    '& .MuiFormLabel-filled .MuiSelect-select': {
      paddingBottom: '15.5px',
    },
  },
  option: {
    '& span.MuiCheckbox-root': {
      padding: '4px 4px 4px 0px',
    },
  },
  selectedChip: {
    height: 23,
    overflow: 'hidden',
  },
}));

const SelectMulti: FC<Props> = ({
  name,
  value = [''],
  options,
  label = '',
  disabled = false,
  autoFocus = false,
  onChange,
  fullWidth,
  error = '',
  size = 'medium',
  readonly = false,
}) => {
  const styles = useStyles();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // convert the heinous return value of the MUI Select ([any,any,any..., string]) into UiOption[]
    const values = (event.target.value || []) as (UiOption | string)[];
    const selected: UiOption[] = [];
    let newValue = '';

    values.forEach((opt) => {
      if (typeof opt === 'string') {
        newValue = opt;
        return;
      }
      selected.push(opt);
    });

    const existIndex = selected.findIndex(({ value }) => value === newValue);
    if (existIndex === -1) {
      const newOption = options.find(({ value }) => value === newValue);
      if (newOption) {
        selected.push(newOption);
      }
    } else {
      selected.splice(existIndex, 1);
    }
    onChange(selected);
  };

  const labelId = `${name}-label`;
  const valueOpts = options.filter((opt) => value.includes(opt.value));

  return (
    <FormControl label={label} error={error} name={name}>
      <MuiSelect
        labelId={labelId}
        id={name}
        multiple
        disabled={disabled || readonly}
        autoFocus={autoFocus}
        value={valueOpts}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} className={styles.input} />}
        renderValue={(selected: UiOption[]) => {
          return (
            <Box display="flex" gap={0.5} className={styles.selectedChip}>
              {selected.map(({ label, value }) => (
                <Chip key={value} label={label} size="small" />
              ))}
            </Box>
          );
        }}
        fullWidth={fullWidth}
        error={!!error}
        size={size}
      >
        {options.map(({ label, value: optValue, disabled = false }) => {
          return (
            <MenuItem key={optValue} value={optValue} disabled={disabled} className={styles.option}>
              <Stack direction="row" gap={1} alignItems="center">
                <Checkbox checked={value.includes(optValue)} disableRipple />
                <Text>{label}</Text>
              </Stack>
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};

export default SelectMulti;
