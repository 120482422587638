import Text from '@/components/text';
import { Box, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  title: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  stepHeader: {
    margin: '16px 0px',
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const ConnectorStepHeader: FC<Props> = ({ title }) => {
  const styles = useStyles();

  return (
    <Box className={styles.stepHeader}>
      <Text bold size="large">
        {title}
      </Text>
    </Box>
  );
};

export default ConnectorStepHeader;
