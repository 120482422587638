import { Backdrop, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import Loader from '../loader';
import SurepathLogoCarbon from '../../assets/logo-carbon-0924.svg';

const useStyles = createUseStyles((theme: Theme) => ({
  loader: {
    zIndex: theme.zIndex.drawer + 1000,
    backgroundColor: theme.palette.common.white,
    '& img': {
      display: 'block',
      maxWidth: 250,
      maxHeight: 40,
      width: 'auto',
      height: 'auto',
    },
  },
}));

const PageLoader: FC = () => {
  const styles = useStyles();

  return (
    <Backdrop className={styles.loader} open={true}>
      <Stack gap={3} alignItems="center" justifyContent="center" width="300px">
        <img src={SurepathLogoCarbon} alt="SurePath AI" />
        <Loader />
      </Stack>
    </Backdrop>
  );
};

export default PageLoader;
