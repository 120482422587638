import { FC, useEffect, useState } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import FormSection from '@/components/form/form-section';
import { useQuery } from 'react-query';
import useQueryHelper from '@/hooks/use-query-helper';
import { QueryKey } from '@/lib/query-client';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import { getAgentById, updateAgent } from '@/lib/services/agent.service';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { AgentModel } from '@/lib/models/agent.model';
import useToast from '@/hooks/use-toast.hook';
import { Form } from '@/components/form';
import Text from '@/components/text';
import InputControl from '@/components/form/input-control';
import { createUseStyles } from 'react-jss';
import FormButtons from '@/components/form/form-buttons';
import ImageUploader from '@/components/image-uploader';
import { ImageExtension } from '@/components/image-uploader/helpers';
import ImageUploadPreviewCard from '@/components/image-uploader/preview-card';

interface Props {
  agentId: string;
}

const MAX_TITLE_LENGTH = 30;
const MAX_GREETING_LENGTH = 100;

const fields = ['portal'];
const formModel = new AgentModel();

const useStyles = createUseStyles((theme: Theme) => ({
  portalBadgeForm: {
    width: '50%',
    boxSizing: 'border-box',
    marginRight: 100,
    paddingRight: 16,
  },
  imagePreview: {
    '& img': {
      height: 40,
      width: 40,
    },
  },
  badgePlaceholder: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.grey[200],
    border: `1px dashed ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    '& .MuiAvatar-root': {
      backgroundColor: '#7ebd94',
    },
  },
  titleForm: {
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  portalAssistantPreview: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 16,
    borderRadius: theme.shape.borderRadius,
  },
}));

const MAX_FILE_SIZE_KB = 300;
const IMAGE_HEIGHT_PX = 40;
const IMAGE_WIDTH_PX = 40;
const ALLOWED_IMAGE_TYPES: ImageExtension[] = ['png', 'jpg'];

const AgentPortalForm: FC<Props> = ({ agentId }) => {
  const styles = useStyles();

  const [badgeImage, setBadgeImage] = useState('');
  const { canChangeAgents } = useFeature();

  const query = useQuery([QueryKey.AgentView], async () => getAgentById(agentId));

  const { data: agent = new AgentModel(), refetch } = query;
  const { showLoader } = useQueryHelper(query);

  const { toast, errorToast } = useToast();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      values.portal.badge.image = badgeImage;
      const updated = await updateAgent(values);

      setSubmitting(false);
      resetForm({ values });

      if (updated) {
        refetch();
        toast('The assistant was updated');
        return;
      }

      errorToast('The assistant was not updated');
    },
  });

  const handleUploadImage = (dataURI: string) => {
    const updatedAgent = new AgentModel(agent?._props);
    updatedAgent.portal.badge.image = dataURI;

    updateAgent(updatedAgent).then(({ updated }) => {
      if (updated) {
        setBadgeImage(dataURI);
        toast('The badge image was updated');
        return;
      }

      errorToast('The badge image was not updated');
    });
  };

  const handleRemoveImage = () => {
    const updatedAgent = new AgentModel(agent?._props);
    updatedAgent.portal.badge.image = '';

    updateAgent(updatedAgent).then(({ updated }) => {
      if (updated) {
        setBadgeImage('');
        toast('The badge image was removed');
        return;
      }

      errorToast('The badge image was not removed');
    });
  };

  useEffect(() => {
    setBadgeImage(agent?.portal.badge.image || '');
  }, [agent]);

  if (showLoader) {
    return null;
  }

  const readonly = !canChangeAgents;
  const hasImage = !!badgeImage;
  const { values } = formHandle;

  return (
    <Box>
      <PageFeatureToast featureId="change-agent" can={canChangeAgents} />

      <Form formHandle={formHandle} query={query}>
        <FormSection title="Title and Greeting">
          <Box mb={4}>
            The title appears next to assistant responses in the Portal, along with the badge image.
            The greeting will appear directly underneath the assistant selector when starting a new
            task.
          </Box>

          <Stack direction="row" gap={6} mt={5} width="100%">
            {!readonly && (
              <Box width="50%">
                <Stack gap={2}>
                  <Box mb={2}>
                    <Box>
                      <Text bold>Assistant Title</Text>
                    </Box>

                    <Box width="300px">
                      <InputControl
                        name="portal.badge.title"
                        label="Assistant Title"
                        readonly={readonly}
                        maxLength={MAX_TITLE_LENGTH}
                        formHandle={formHandle}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <Box>
                      <Text bold>Assistant Greeting</Text>
                    </Box>

                    <Box>
                      <InputControl
                        name="portal.greeting"
                        label="Assistant Greeting"
                        maxLength={MAX_GREETING_LENGTH}
                        formHandle={formHandle}
                        readonly={readonly}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            )}

            <Box width="50%">
              <Stack gap={2}>
                <Box>
                  <Text bold>Badge and Title Preview</Text>
                </Box>
                <Box>
                  <Text size="small">
                    Below is a preview of the Portal Assistant profile, including a combination of
                    the badge image and title
                  </Text>
                </Box>

                <Box mt={2} gap={2} className={styles.portalAssistantPreview}>
                  <Stack direction="row" gap={2}>
                    <Box>
                      {hasImage && (
                        <Box className={styles.imagePreview}>
                          <img src={badgeImage} />
                        </Box>
                      )}
                      {!hasImage && <Box className={styles.badgePlaceholder}></Box>}
                    </Box>
                    <Stack gap={2}>
                      <Text bold>{values.portal.badge.title}</Text>
                      <Box>{values.portal.greeting}</Box>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </FormSection>

        <FormSection title="Description">
          <Box mb={2}>
            The description will appear in the Assistants Tab of the Portal when viewing a list of
            available assistants. Portal users can filter the list of assistants by keyword search
            on both the title and description.
          </Box>
          <InputControl
            name="portal.description"
            label="Description"
            formHandle={formHandle}
            readonly={!canChangeAgents}
            type="textarea"
            rows={2}
          />
        </FormSection>

        <FormSection title="Badge Image">
          <Box mb={4}>
            The badge image appears next to assistant responses in the Portal, along with the title.
          </Box>
          <Stack direction="row" gap={6} width="100%">
            {!readonly && (
              <Box width="50%">
                <ImageUploader
                  title="Upload New Badge"
                  onUpload={handleUploadImage}
                  maxFileSizeKb={MAX_FILE_SIZE_KB}
                />
              </Box>
            )}
            <Box width="50%">
              <ImageUploadPreviewCard
                imageHeight={IMAGE_HEIGHT_PX}
                imageWidth={IMAGE_WIDTH_PX}
                maxFileSize={MAX_FILE_SIZE_KB}
                allowedImageTypes={ALLOWED_IMAGE_TYPES}
                title="Current Badge"
                onRemove={handleRemoveImage}
                readonly={readonly}
                image={badgeImage}
              />
            </Box>
          </Stack>
        </FormSection>

        <FormButtons formHandle={formHandle} readonly={!canChangeAgents} />
      </Form>
    </Box>
  );
};

export default AgentPortalForm;
