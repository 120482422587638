import { UiOption } from '@/lib/helpers';

export type BreadCrumb = 'group' | 'context' | 'policy' | 'agent';

export const BreadCrumbMap: Record<BreadCrumb, UiOption> = {
  group: { label: 'Groups', value: '/config/groups' },
  context: { label: 'Data Sources', value: '/config/data-sources' },
  policy: { label: 'Group Policies', value: '/config/group-policies' },
  agent: { label: 'Assistants', value: '/config/assistants' },
};
