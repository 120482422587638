import { FC } from 'react';
import { AgentModel } from '@/lib/models/agent.model';
import { Avatar, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getFirstLastName } from '@/lib/helpers';
import classNames from 'classnames';

interface Props {
  agent?: AgentModel;
  size?: 'small' | 'medium';
}

const useStyles = createUseStyles<string, { width: number; size: string }>((theme: Theme) => ({
  agentBadge: {
    backgroundColor: '#7ebd94',
    width: ({ width }) => width,
    height: ({ width }) => width,
    fontSize: ({ size }) => (size === 'small' ? theme.typography.fontSize : undefined),
    '&.unavailable': {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));

const AgentBadge: FC<Props> = ({ agent, size = 'medium' }) => {
  const width = size === 'medium' ? 40 : 24;
  const styles = useStyles({ width, size });

  if (!agent) {
    return <Avatar className={classNames(styles.agentBadge, 'unavailable')}></Avatar>;
  }

  const { image, title } = agent.portal.badge;

  if (image) {
    return <img src={image} alt={title} width={width} />;
  }

  const { first, last } = getFirstLastName(title);
  const initials = `${first[0] || ''}${last[0] || ''}`.toUpperCase();

  return <Avatar className={styles.agentBadge}>{initials}</Avatar>;
};

export default AgentBadge;
