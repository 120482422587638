import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { FormProps } from '../helpers';
import ListToggle from '../list-toggle';
import { UiOption } from '@/lib/helpers';
import PublicServiceButton from '@/components/public-service-button';
import { Box } from '@mui/material';
import useDrawer from '@/hooks/use-drawer';
import { PublicServiceMeta, PublicServiceModel } from '@/lib/models/public-service.model';
import useQueryHelper from '@/hooks/use-query-helper';
import useAppData from '@/hooks/use-app-data.hook';
import { AppDataQueryKey } from '@/lib/query-client';

interface Props extends FormProps {
  value: string[];
  onChange: (options: UiOption[]) => void;
}

const useStyles = createUseStyles({
  psList: {
    '& label.MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      marginRight: 0,
    },
    '& span.MuiFormControlLabel-label': {
      display: 'block',
      width: '100%',
    },
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      flexGrow: 1,
    },
  },
});

const PublicServiceAcl: FC<Props> = (props) => {
  const styles = useStyles();

  const { openDrawer, DrawerEl } = useDrawer('public-service');

  const handleView = useCallback(
    (event: MouseEvent, { id }: PublicServiceModel) => {
      event.stopPropagation(); // prevent clicking on info from opening the ps button accordion
      event.preventDefault();
      openDrawer({ id });
    },
    [openDrawer]
  );

  const query = useAppData<PublicServiceMeta[]>(AppDataQueryKey.PublicServices);
  const { data = [] } = query;
  const { showLoader } = useQueryHelper(query);

  const psOptions: UiOption[] = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((publicService) => {
      return {
        label: (
          <PublicServiceButton publicService={publicService} onInfoClick={handleView} noDetails />
        ),
        value: publicService.id,
        meta: { keyword: publicService.name },
      };
    });
  }, [data, handleView]);

  return (
    <>
      <Box className={styles.psList}>
        <ListToggle
          {...props}
          label="Public Services"
          leftTitle="Available Public Services"
          rightTitle="Allowed Public Services"
          options={psOptions}
          labelChecks={true}
          loadState={showLoader ? 'loading' : 'loaded'}
        />
      </Box>
      {DrawerEl}
    </>
  );
};

export default PublicServiceAcl;
