import { FC } from 'react';
import { ContextDetails } from '@/lib/models/user-event';
import Text from '@/components/text';
import Stack from '@mui/material/Stack';
import { capitalize } from 'lodash';

interface Props {
  contextDetails: ContextDetails;
  content: string;
}

const ContextHistory: FC<Props> = ({
  contextDetails: { type, summary, name, origin },
  content,
}) => {
  const textContent = origin === 'user' ? summary : content; // If user context, show summary, else is enterprise andno summary exists so default to content
  return (
    <Stack gap={2}>
      <Text size="small" bold>
        {type === 'file' ? 'File' : capitalize(type)}: {name}
      </Text>
      <Text size="small">{textContent}</Text>
    </Stack>
  );
};

export default ContextHistory;
