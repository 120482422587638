import { FC } from 'react';
import Card from '@/components/card';
import Text from '@/components/text';
import { Box } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { CARD_SIZE, GridCard as GridCardProps } from './helpers';
import Icon, { IconType } from '@/components/icon';

interface Props {
  gridCard: GridCardProps;
}

const useStyles = createUseStyles({
  gridCard: {
    width: CARD_SIZE.width,
    height: CARD_SIZE.height,
  },
  gridCardInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
  },
  detail: {
    maxHeight: 90,
    overflow: 'hidden',
  },
});

const GridCard: FC<Props> = ({ gridCard }) => {
  const { title, iconId, IconEl, detail, children, onClick } = gridCard;

  const styles = useStyles();

  const hasIconId = !!iconId;
  const hasIconEl = !hasIconId && !!IconEl;
  const hasIcon = hasIconId || hasIconEl;
  const hasTitle = !!title;
  const hasDetail = !!detail;

  return (
    <Card className={styles.gridCard} hover={!!onClick} onClick={onClick}>
      <Box className={styles.gridCardInner}>
        {hasIcon && (
          <Box mb={1}>
            {hasIconId && <Icon name={IconEl as IconType} />}
            {hasIconEl && IconEl}
          </Box>
        )}
        {hasTitle && (
          <Box mb={0.5}>
            <Text>{title}</Text>
          </Box>
        )}
        {hasDetail && (
          <Text size="x-small" color="grey" className={styles.detail}>
            {detail}
          </Text>
        )}
        {children}
      </Box>
    </Card>
  );
};

export default GridCard;
