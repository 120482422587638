import { Box, FormHelperText, List, ListItem, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { FormProps } from '../helpers';
import { createUseStyles } from 'react-jss';
import Text from '@/components/text';
import { UiOption } from '@/lib/helpers';
import Switch from '../switch';
import Tooltip from '@/components/tooltip';

export type ListItem = {
  label: string;
  value: unknown;
  meta?: Record<string, unknown>;
};

interface Props extends FormProps {
  options: UiOption[];
  value: string[];
  onChange: (values: string[]) => void;
  height?: string | number;
}

const useStyles = createUseStyles<string, { height: string | number }>((theme: Theme) => ({
  listEditor: ({ height }) => ({
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    '& ul': {
      height: height || 'auto',
      overflowY: 'auto',
    },
    '& .MuiFormControl-root': {
      padding: 0,
      margin: 0,
    },
    '& li.MuiListItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
  listItem: {
    padding: '16px 0px',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  header: {
    padding: 10,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: 10,
  },
}));

const SwitchList: FC<Props> = ({
  label,
  error,
  onChange,
  options,
  name,
  value,
  disabled,
  readonly,
  height = '',
  emptyMessage = '',
}) => {
  const styles = useStyles({ height });

  const handleSwitchChange = (optValue: string, checked: boolean) => {
    let updatedValue = value ? [...value] : [];
    if (checked) {
      updatedValue.push(optValue);
    } else {
      updatedValue = updatedValue.filter((v) => v !== optValue);
    }
    onChange(updatedValue);
  };

  const hasLabel = !!label;
  const hasError = !!error;
  const hasItems = !!options?.length;

  return (
    <Stack gap={1} className={styles.listEditor}>
      {hasLabel && (
        <Box className={styles.header}>
          <Text bold>{label}</Text>
        </Box>
      )}
      <List>
        {!hasItems && (
          <ListItem>
            <Text italic color="grey">
              {emptyMessage || 'No options available'}
            </Text>
          </ListItem>
        )}
        {options.map((option, index) => {
          const { label, value: optValue, disabled: optDisabled, tooltip } = option;
          const checked = !!value?.includes(optValue);

          let SwitchEl = (
            <Switch
              name={name}
              checked={checked}
              onChange={(checked) => handleSwitchChange(optValue, checked)}
              disabled={optDisabled || disabled || readonly}
              fullWidth={false}
            />
          );

          if (tooltip) {
            SwitchEl = <Tooltip title={tooltip}>{SwitchEl}</Tooltip>;
          }

          return (
            <ListItem key={index}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                className={styles.listItem}
              >
                <Box flexGrow={1}>{label}</Box>

                {SwitchEl}
              </Stack>
            </ListItem>
          );
        })}
      </List>
      {hasError && <FormHelperText error>{error}</FormHelperText>}
    </Stack>
  );
};

export default SwitchList;
