import { FileWithPath } from 'react-dropzone';

export type FileWithPreview = FileWithPath & { preview: string };

export type ImageExtension = 'jpg' | 'png';

export const ImageFileTypes: { ext: ImageExtension; mimetype: string }[] = [
  { ext: 'jpg', mimetype: 'image/jpeg' },
  { ext: 'png', mimetype: 'image/png' },
];
