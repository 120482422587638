import { Dataset, DtFilter, DtSort } from '@/components/data-table/helpers';
import { GroupModel } from '../models/group.model';
import {
  countAll,
  deleteOne,
  findAll,
  findAllAndCount,
  findOne,
  getOidParam,
  getOidParams,
  getOrgFilter,
  getSort,
  insertOne,
  patchOne,
} from './atlas-data-api.service';
import { JsonObject, UiOption } from '../helpers';
import { DeleteResponse, UpdateResponse } from '.';
import { removeGroupFromUsers } from './user.service';
import { removeGroupFromPolicies } from './policy.service';

const COLLECTION = 'groups';

export const getGroups = async (
  page: number,
  pageSize: number,
  dtSort?: DtSort,
  dtFilter?: DtFilter
): Promise<Dataset<GroupModel>> => {
  const skip = page * pageSize;
  const filter = getOrgFilter();

  const { keyword } = dtFilter || {};

  if (keyword) {
    filter.$or = [
      { name: { $regex: `.*${keyword}.*`, $options: 'i' } },
      { description: { $regex: `.*${keyword}.*`, $options: 'i' } },
    ];
  }

  const { documents, total } = await findAllAndCount(
    COLLECTION,
    filter,
    pageSize,
    skip,
    getSort(dtSort)
  );

  const rows: GroupModel[] = documents.map((doc) => new GroupModel(doc));

  return {
    page,
    pageSize,
    rows,
    total,
  };
};

export const getNonSyncGroupOptions = async (): Promise<UiOption[]> => {
  const params = {
    filter: {
      ...getOrgFilter(),
      'externalReferences.workos.workosDirectoryId': { $exists: false },
    },
    projection: { _id: 1, name: 1 },
    limit: 5000,
  };

  const documents = await findAll(COLLECTION, params);

  return documents.map(({ _id, name }) => ({ value: _id as string, label: name as string }));
};

export const groupHasDuplicateName = async (groupName: string): Promise<boolean> => {
  const params = {
    filter: { ...getOrgFilter(), name: groupName },
    projection: { _id: 1 },
    limit: 2,
  };

  const documents = await findAll(COLLECTION, params);

  return documents.length > 1;
};

export const getDuplicateGroupNames = async (): Promise<string[]> => {
  const params = {
    filter: getOrgFilter(),
    projection: { name: 1 },
    limit: 5000,
  };

  const documents = await findAll(COLLECTION, params);

  const groupNameMap: Map<string, number> = new Map();

  documents.forEach(({ name }) => {
    const groupName = name as string;
    const count = groupNameMap.get(groupName) || 0;
    groupNameMap.set(groupName, count + 1);
  });

  return Array.from(groupNameMap.entries())
    .filter((entry) => entry[1] > 1)
    .map((entry) => entry[0]);
};

export const countGroupUsers = async (groupId: string): Promise<number> => {
  return countAll('users', { groups: { $elemMatch: { $eq: getOidParam(groupId) } } });
};

export const getGroupIdsByName = async (keyword: string): Promise<string[]> => {
  const params = {
    filter: {
      name: { $regex: `.*${keyword}.*`, $options: 'i' },
      ...getOrgFilter(),
    },
  };

  const groups = await findAll(COLLECTION, params);

  return groups.map(({ _id }) => _id as string);
};

export const getGroupById = async (groupId: string): Promise<GroupModel | null> => {
  const params = {
    filter: {
      _id: { $oid: groupId },
      ...getOrgFilter(),
    },
  };

  const response = await findOne(COLLECTION, params);

  return response ? new GroupModel(response) : null;
};

export const getGroupsByModelIds = async (modelIds: string[]): Promise<GroupModel[]> => {
  const params = {
    filter: {
      portalDefaultModelId: { $in: modelIds.map((modelId) => ({ $oid: modelId })) },
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response ? response.map((data) => new GroupModel(data)) : [];
};

export const getGroupsByPolicyId = async (policyId: string): Promise<GroupModel[]> => {
  const params = {
    filter: {
      policies: getOidParam(policyId),
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response ? response.map((data) => new GroupModel(data)) : [];
};

export const getGroupsByIds = async (groupIds: string[]): Promise<GroupModel[]> => {
  const params = {
    filter: {
      _id: { $in: getOidParams(groupIds) },
      ...getOrgFilter(),
    },
  };

  const response = await findAll(COLLECTION, params);

  return response ? response.map((data) => new GroupModel(data)) : [];
};

export const patchGroup = async (groupId: string, data: JsonObject): Promise<UpdateResponse> => {
  const filter = {
    _id: getOidParam(groupId),
    ...getOrgFilter(),
  };

  const response = await patchOne(COLLECTION, filter, data);

  return { updated: response === null };
};

export const createGroup = async (group: GroupModel): Promise<string | null> => {
  const { orgId } = getOrgFilter();

  const document = {
    name: group.name,
    description: group.description,
    orgId,
    policies: [],
  };

  return insertOne(COLLECTION, document);
};

export const deleteGroup = async (groupId: string): Promise<DeleteResponse> => {
  const groupIdParam = getOidParam(groupId);

  const { updated: usersUpdated } = await removeGroupFromUsers(groupId);

  if (!usersUpdated) {
    return { deleted: false, error: 'Could not remove users from group' };
  }

  const { updated: policiesUpdated } = await removeGroupFromPolicies(groupId);

  if (!policiesUpdated) {
    return { deleted: false, error: 'Could not remove policies from group' };
  }

  const filter = {
    _id: groupIdParam,
    ...getOrgFilter(),
  };

  const deleted = await deleteOne(COLLECTION, filter);

  return { deleted };
};
