import { datadogRum } from '@datadog/browser-rum';
import Environment from './environment';

// map vite default mode values to valid datadog environment values
const getDatadogEnvironment = (mode: string): string => {
  switch (mode) {
    case 'development':
      return 'dev';
    case 'stage':
      return 'stage';
    case 'production':
      return 'prod';
  }

  return '';
};

export const initDatadog = () => {
  const {
    DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE,
    DATADOG_SITE,
    MODE,
    APP_VERSION,
  } = Environment;

  if (!DATADOG_APPLICATION_ID || !DATADOG_CLIENT_TOKEN || !DATADOG_SERVICE || !DATADOG_SITE) {
    console.warn('telemetry not loaded');
    return;
  }

  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: getDatadogEnvironment(MODE),
    version: APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
};

export const setDatadogUser = (id: string, email: string) => {
  datadogRum.setUser({ id, email });
};

export const addError = (error: Error) => {
  datadogRum.addError(error);
};
