import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import { ConnectorModel } from './connector.model';
import ZscalerLogo from '@/assets/connector-icons/zscaler.svg';

export type ZscalerConfig = {
  xAuthenticatedUserEnabled: boolean;
};

export class ZscalerConnectorModel extends ConnectorModel {
  config: ZscalerConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'zscaler';
    this.typeLabel = 'Zscaler';
    this.iconSVG = ZscalerLogo;

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: false,
      contextData: false,
      ingress: true,
    };

    this.config = (data.config as ZscalerConfig) || {
      xAuthenticatedUserEnabled: false,
    };

    this.highlander = true;
  }
}
