import useToast from '@/hooks/use-toast.hook';
import { OrgModel } from '@/lib/models/org.model';
import { patchOrg } from '@/lib/services/org.service';
import { Box, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import ImageUploader from '@/components/image-uploader';
import ImageUploadPreviewCard from '@/components/image-uploader/preview-card';
import { ImageExtension } from '@/components/image-uploader/helpers';

export type FileWithPreview = FileWithPath & { preview: string };

interface Props {
  organization: OrgModel;
  readonly: boolean;
}

const MAX_FILE_SIZE_KB = 100;
const IMAGE_HEIGHT_PX = 16;
const IMAGE_WIDTH_PX = 16;
const ALLOWED_IMAGE_TYPES: ImageExtension[] = ['png'];

const OrgFaviconForm: FC<Props> = ({ organization, readonly }) => {
  const { favicon } = organization.branding;

  const { toast, errorToast } = useToast();
  const [image, setImage] = useState(favicon || '');

  const handleUploadImage = (dataURL: string) => {
    const updatedOrg = new OrgModel(organization._props);
    updatedOrg.branding.favicon = dataURL;

    patchOrg(updatedOrg).then(({ patched, error }) => {
      if (patched) {
        setImage(dataURL);
        toast('The favicon was updated');
        return;
      }

      errorToast(error || 'The favicon was not updated');
    });
  };

  const handleRemoveImage = () => {
    const updatedOrg = new OrgModel(organization._props);
    updatedOrg.branding.favicon = '';

    patchOrg(updatedOrg).then(({ patched, error }) => {
      if (patched) {
        setImage('');
        toast('The favicon was removed');
        return;
      }

      errorToast(error || 'The favicon was not removed');
    });
  };

  return (
    <>
      <Box mb={4}>
        The Organization Favicon will appear in browser tabs, address bars, and bookmark lists.
      </Box>
      <Stack direction="row" gap={6} width="100%">
        {!readonly && (
          <Box width="50%">
            <ImageUploader
              title="Upload New Favicon"
              onUpload={handleUploadImage}
              imageHeight={IMAGE_HEIGHT_PX}
              imageWidth={IMAGE_WIDTH_PX}
              maxFileSizeKb={MAX_FILE_SIZE_KB}
              allowedImageTypes={ALLOWED_IMAGE_TYPES}
            />
          </Box>
        )}
        <Box width="50%">
          <ImageUploadPreviewCard
            imageHeight={IMAGE_HEIGHT_PX}
            imageWidth={IMAGE_WIDTH_PX}
            maxFileSize={MAX_FILE_SIZE_KB}
            allowedImageTypes={ALLOWED_IMAGE_TYPES}
            title="Current Favicon"
            onRemove={handleRemoveImage}
            readonly={readonly}
            image={image}
          />
        </Box>
      </Stack>
    </>
  );
};

export default OrgFaviconForm;
