import { FC, MouseEvent } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { PublicServiceMeta, PublicServiceModel } from '@/lib/models/public-service.model';
import Icon from '../icon';
import Text from '../text';
import Chip from '../chip';
import AccordionButton from '../accordion-button';
import NoneChip from '../chip/none-chip';

interface Props {
  publicService: PublicServiceModel | PublicServiceMeta;
  onInfoClick?: (event: MouseEvent, publicService: PublicServiceModel | PublicServiceMeta) => void;
  noDetails?: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  favicon: {
    height: 16,
    width: 16,
  },
  description: {
    maxHeight: 100,
    overflowY: 'auto',
  },
  noDetails: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
  },
}));

const PublicServiceButton: FC<Props> = ({ publicService, onInfoClick, noDetails = false }) => {
  const styles = useStyles();
  const { image, name, description, supportLevel, risk, riskColor } = publicService;
  const hasImage = !!image;
  const firstSentence = description ? description.split('.').shift() : '';
  const hasRisk = Boolean(risk?.base);

  const RiskChipEl = hasRisk ? (
    <Chip color={riskColor} label={risk.base.level} size="small" />
  ) : (
    <NoneChip notAvailable />
  );

  const hasInfoButton = !!onInfoClick;

  const TitleEl = (
    <Stack direction="row" gap={1} alignItems="center" width="100%">
      {hasImage && <img src={image} className={styles.favicon} />}
      {!hasImage && <Icon name="question-mark" color="grey" />}
      <Text>{name}</Text>
      {hasInfoButton && (
        <Stack flexDirection="row" justifyContent="flex-end" alignItems="center" flexGrow={1}>
          <Icon
            name="info-circle"
            onClick={(event: MouseEvent) => onInfoClick(event, publicService)}
            size="small"
            color="grey"
          />
        </Stack>
      )}
    </Stack>
  );

  if (noDetails) {
    return <Box className={styles.noDetails}>{TitleEl}</Box>;
  }

  return (
    <AccordionButton title={TitleEl}>
      <Stack gap={1}>
        <Box className={styles.description}>
          <Text size="small">{firstSentence}</Text>
        </Box>
        <Stack direction="row">
          <Stack gap={1} width="40%">
            <Text bold size="small">
              Risk Level
            </Text>
            <Box>{RiskChipEl}</Box>
          </Stack>

          <Stack gap={1}>
            <Text bold size="small">
              Support Level
            </Text>
            <Text size="small">{String(supportLevel).toUpperCase()}</Text>
          </Stack>
        </Stack>
      </Stack>
    </AccordionButton>
  );
};

export default PublicServiceButton;
