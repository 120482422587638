import { FC } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getPolicyById, patchPolicy } from '@/lib/services/policy.service';
import useToast from '@/hooks/use-toast.hook';
import { PolicyModel } from '@/lib/models/policy.model';
import FormSection from '@/components/form/form-section';
import useQueryHelper from '@/hooks/use-query-helper';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import FormButtons from '@/components/form/form-buttons';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import { getAllAgents } from '@/lib/services/agent.service';

const fields = ['assistants'];
const formModel = new PolicyModel();

interface Props {
  policyId: string;
}

const AgentsForm: FC<Props> = ({ policyId }) => {
  const { errorToast, toast } = useToast();
  const { canChangeAgents } = useFeature();

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await patchPolicy(policyId, values.propSlice(fields));

      setSubmitting(false);
      resetForm({ values });

      if (updated) {
        toast('The policy was updated');
        return;
      }

      errorToast('The policy was not updated');
    },
  });

  const groupPolicyQuery = useQuery([QueryKey.PolicyView, policyId], async () =>
    getPolicyById(policyId)
  );

  const agentsQuery = useQuery([QueryKey.AgentsList], async () => getAllAgents());

  const { data: agents } = agentsQuery;
  const { showLoader: policyLoading } = useQueryHelper(groupPolicyQuery);
  const { showLoader: agentsLoading } = useQueryHelper(agentsQuery);

  const agentOptions = useMemo(() => {
    return (agents || []).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [agents]);

  if (policyLoading || agentsLoading) {
    return;
  }

  return (
    <Form formHandle={formHandle} query={groupPolicyQuery}>
      <PageFeatureToast featureId="change-agent" can={canChangeAgents} />
      <FormSection title="Assistant Assignment">
        <InputControl
          name="assistants.enabled"
          label="Assistants"
          formHandle={formHandle}
          readonly={!canChangeAgents}
          type="list-toggle"
          leftTitle="Available Assistants"
          rightTitle="Assigned Assistants"
          options={agentOptions}
        />
      </FormSection>

      <FormButtons formHandle={formHandle} readonly={!canChangeAgents} />
    </Form>
  );
};

export default AgentsForm;
