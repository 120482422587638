import { JsonObject, UiOption } from '@/lib/helpers';
import { BaseMeta, BaseModel } from '@/lib/models/base.model';
import { difference, flatten, has } from 'lodash';
import { array, boolean, object, string } from 'yup';

export type PolicyAccessList = {
  enabled: string[];
  disabled: string[];
};

type PolicyScope = 'org' | 'group';

export type SensitiveDataConfig = {
  action: 'block' | 'redirect';
  detect: boolean;
  entities: string[];
};

export type PortalConfig = {
  enabled: boolean;
  defaultModelId?: string;
  availablePrivateModelIds: string[];
};

export type PolicyMeta = BaseMeta & {
  name: string;
  description: string;
  scope: string;
  groups: string[];
  publicServices: PolicyAccessList & { allowPublicServicesByDefault?: boolean };
  contextDataSources: PolicyAccessList;
  portal: PortalConfig;
  assistants: PolicyAccessList;
};

export const PublicServiceHandlingOptions: UiOption[] = [
  { label: 'Block', value: 'block' },
  { label: 'Allow', value: 'allow' },
];

// @todo J5 Demo
export const IntentOptions: UiOption[] = [
  'Corporate Social Responsibility',
  'Customer Service Or Support',
  'Engineering',
  'Facilities Management',
  'Finance And Accounting',
  'Health Safety And Environment',
  'Human Resources',
  'Information Technology',
  'Legal And Compliance',
  'Marketing',
  'Operations',
  'Product Management',
  'Public Relations',
  'Purchasing And Procurement',
  'Quality Assurance',
  'Sales',
  'Software And Hardware Development',
  'Strategic Planning And Business Development',
  'Supply Chain And Logistics',
].map((name) => ({ label: name, value: name }));

export class PolicyModel extends BaseModel {
  orgId: string;
  name: string;
  description: string;
  publicServices: PolicyAccessList & { allowPublicServicesByDefault?: boolean };
  contextDataSources: PolicyAccessList;
  assistants: PolicyAccessList;
  intents: PolicyAccessList;
  sensitiveData: SensitiveDataConfig;
  groups: string[];
  scope: PolicyScope;
  portal: PortalConfig;

  schema = object({
    orgId: string().required('Organization is required'),
    name: string().required('Name is required'),
    publicServices: object({
      enabled: array().of(string()).required('Enabled public service ids cannot be empty'),
      disabled: array().of(string()).required('Disabled public service ids cannot be empty'),
    }),
    contextDataSources: object({
      enabled: array().of(string()).required('Enabled data source ids cannot be empty'),
      disabled: array().of(string()).required('Disabled data source ids cannot be empty'),
    }),
    assistants: object({
      enabled: array().of(string()).required('Enabled assistant ids cannot be empty'),
      disabled: array().of(string()).required('Disabled assistant ids cannot be empty'),
    }),
    sensitiveData: object({
      action: string(),
      detect: boolean(),
      entities: array().of(string()),
    }),
    groups: array().of(string()),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof PolicyModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.description = (data.description as string) || '';
    this.publicServices = (data.publicServices as PolicyAccessList) || {
      enabled: [],
      disabled: [],
      allowPublicServicesByDefault: false,
    };
    this.contextDataSources = (data.contextDataSources as PolicyAccessList) || {
      enabled: [],
      disabled: [],
    };
    this.assistants = (data.assistants as PolicyAccessList) || {
      enabled: [],
      disabled: [],
    };
    this.intents = (data.intents as PolicyAccessList) || {
      enabled: [],
      disabled: [],
    };
    this.sensitiveData = (data.sensitiveData as SensitiveDataConfig) || {
      action: 'block',
      detect: false,
      entities: [],
    };
    this.groups = (data.groups as string[]) || [];
    this.scope = (data.scope as PolicyScope) || 'group';
    this.portal = (data.portal as PortalConfig) || {
      enabled: false,
      defaultModelId: '',
      availablePrivateModelIds: [],
    };

    // legacy schema support
    if (this.portal && !has(this, 'portal.availablePrivateModelIds')) {
      this.portal.availablePrivateModelIds = [];
      if (this.portal.defaultModelId) {
        this.portal.availablePrivateModelIds.push(this.portal.defaultModelId);
      }
    }

    // legacy schema support
    if (this.publicServices && !has(this, 'publicServices.allowPublicServicesByDefault')) {
      this.publicServices.allowPublicServicesByDefault = false;
    }
  }

  get meta(): PolicyMeta {
    return {
      id: this.id || '',
      name: this.name,
      description: this.description,
      scope: this.scope,
      groups: this.groups,
      publicServices: this.publicServices,
      contextDataSources: this.contextDataSources,
      portal: this.portal,
      assistants: this.assistants,
    };
  }

  /*
   * Merge an org policy into this group policy to create a single model which has
   * a composite view of portal settings for the given policy.
   */
  applyOrgPolicy(orgPolicy: PolicyModel) {
    if (
      orgPolicy.scope !== 'org' ||
      this.scope !== 'group' ||
      !Array.isArray(this.portal?.availablePrivateModelIds) ||
      !Array.isArray(orgPolicy?.portal?.availablePrivateModelIds)
    ) {
      return;
    }

    this.portal.availablePrivateModelIds = flatten([
      this.portal.availablePrivateModelIds,
      orgPolicy.portal.availablePrivateModelIds,
    ]);

    if (!this.portal.defaultModelId && orgPolicy.portal.defaultModelId) {
      this.portal.defaultModelId = orgPolicy.portal.defaultModelId;
    }
  }

  /*
   * Inverse of applyOrgPolicy, for saving the group policy back to the database
   */
  stripOrgPolicy(orgPolicy: PolicyModel) {
    if (
      orgPolicy.scope !== 'org' ||
      this.scope !== 'group' ||
      !Array.isArray(this.portal?.availablePrivateModelIds) ||
      !Array.isArray(orgPolicy?.portal?.availablePrivateModelIds)
    ) {
      return;
    }

    // ensure that no model enabled at the org level is enabled at the group level
    this.portal.availablePrivateModelIds = difference(
      this.portal.availablePrivateModelIds,
      orgPolicy.portal.availablePrivateModelIds
    );

    // group default model cannot be the same as the org default model
    if (this.portal.defaultModelId === orgPolicy.portal.defaultModelId) {
      this.portal.defaultModelId = '';
    }
  }
}

export const SensitiveDataActionOptions: UiOption[] = [
  { label: 'Allow', value: 'allow', meta: { header: true } },
  { label: 'Log only', value: 'keep' },
  { label: 'Disallow', value: 'disallow', meta: { header: true } },
  { label: 'Block', value: 'block' },
  { label: 'Redact', value: 'redact', meta: { header: true } },
  { label: 'Tag', value: 'tag' },
  { label: 'Mask', value: 'mask' },
  { label: 'Delete', value: 'delete' },
  { label: 'Synthesize', value: 'synthesize' },
];

export const SensitiveDataTypeOptions: UiOption[] = [
  { label: 'Phone Number', value: 'PHONE_NUMBER' },
  { label: 'Credit Card', value: 'CREDIT_CARD' },
  { label: 'Crypto Address', value: 'CRYPTO' },
  { label: 'Email Address', value: 'EMAIL_ADDRESS' },
  { label: 'IBAN Code', value: 'IBAN_CODE' },
  { label: 'IP Address', value: 'IP_ADDRESS' },
  { label: 'Person', value: 'PERSON' },
  { label: 'Medical License', value: 'MEDICAL_LICENSE' },
  { label: 'URL', value: 'URL' },
  { label: 'US Bank Number', value: 'US_BANK_NUMBER' },
  { label: "US Driver's License Number", value: 'US_DRIVER_LICENSE' },
  { label: 'US Individual Taxpayer Identification Number', value: 'US_ITIN' },
  { label: 'US Passport Number', value: 'US_PASSPORT' },
  { label: 'US Social Security Number', value: 'US_SSN' },
  { label: 'UK NHS Number', value: 'UK_NHS' },
  { label: 'Spanish Tax Identification Number', value: 'ES_NIF' },
  { label: 'Italian Fiscal Code', value: 'IT_FISCAL_CODE' },
  // { label: "Italian Driver's License Number", value: 'IT_DRIVER_LICENSE' },
  { label: 'Italian VAT Code', value: 'IT_VAT_CODE' },
  { label: 'Italian Passport Number', value: 'IT_PASSPORT' },
  { label: 'Italian Identity Card Number', value: 'IT_IDENTITY_CARD' },
  { label: 'Polish National Identification Number', value: 'PL_PESEL' },
  { label: 'Singapore NRIC Number', value: 'SG_NRIC_FIN' },
  { label: 'Singapore Entity Identification Number', value: 'SG_UEN' },
  { label: 'Australian Business Registration Number', value: 'AU_ABN' },
  { label: 'Australian Company Number', value: 'AU_ACN' },
  { label: 'Australian Tax File Number', value: 'AU_TFN' },
  { label: 'Australian Medicare Number', value: 'AU_MEDICARE' },
  { label: 'Indian Permanent Account Number', value: 'IN_PAN' },
  { label: 'Indian Aadhaar Number', value: 'IN_AADHAAR' },
  { label: 'Indian Vehicle Registration Number', value: 'IN_VEHICLE_REGISTRATION' },
];
