import { FC } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import Icon from '../icon';
import useToast from '@/hooks/use-toast.hook';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

interface Props {
  value: string;
  className?: string;
  size?: 'medium' | 'small';
}

const useStyles = createUseStyles({
  icon: {
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
});

const CopyClipButton: FC<Props> = ({ value, className, size = 'medium' }) => {
  const { toast } = useToast();
  const [, copy] = useCopyToClipboard();
  const handleCopy = () => {
    copy(value);
    toast('Copied to clipboard!');
  };
  const styles = useStyles();
  const compClass = classNames(styles.icon, className);

  return <Icon name="copy" size={size} onClick={handleCopy} className={compClass} color="active" />;
};

export default CopyClipButton;
