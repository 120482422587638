import { Alert, AlertColor } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  level?: AlertColor;
  message: string;
  attention?: boolean;
  hideIcon?: boolean;
}

const useStyles = createUseStyles({
  inlineToast: {
    '&.attention': {
      animation: '$nudge 400ms ease-in-out 1',
    },
  },
  '@keyframes nudge': {
    '0%': {
      transform: 'translateX(0)',
    },
    '50%': {
      transform: 'translateX(-10px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
});

const InlineToast: FC<Props> = ({
  level = 'info',
  message,
  attention = false,
  hideIcon = false,
}) => {
  const styles = useStyles();
  const compClass = classNames(styles.inlineToast, { attention });

  return (
    <Alert severity={level} className={compClass} {...(hideIcon ? { icon: false } : {})}>
      {message}
    </Alert>
  );
};

export default InlineToast;
