import { Accordion, AccordionDetails, AccordionSummary, Box, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import Icon from '../icon';
import { createUseStyles } from 'react-jss';

interface Props {
  title: ReactNode;
  children: ReactNode;
}

const useStyles = createUseStyles((theme: Theme) => ({
  button: {
    '& .MuiAccordion-root': {
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px 4px',
      minHeight: 'inherit',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      padding: 8,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: 2,
    },
  },
}));

const AccordionButton: FC<Props> = ({ title, children }) => {
  const styles = useStyles();
  return (
    <Box className={styles.button}>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<Icon name="expand" />}>{title}</AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AccordionButton;
