import { ComponentProps, FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ConversationDrawer from '@/components/drawers/conversation';
import PublicServiceDrawer from '@/components/drawers/public-service';
import PrivateModelDrawer from '../components/drawers/private-model';
import AdminUserDrawer from '../components/drawers/admin-user';
import UserDrawer from '@/components/drawers/end-user';
import usePrior from './use-prior.hook';
import { DRAWER_ANIMATE_DURATION } from '../components/drawers/helpers';
import GroupDrawer from '@/components/drawers/group';
import UserEventDrawer from '@/components/drawers/user-event';
import ConnectorDrawer from '@/components/drawers/connector';
import ContextProviderDrawer from '@/components/drawers/context-provider';

type DrawerType =
  | 'context-provider'
  | 'conversation'
  | 'user-event'
  | 'public-service'
  | 'private-model'
  | 'admin-user'
  | 'end-user'
  | 'group'
  | 'connector';

type DrawerHook = {
  openDrawer: <T>(addlProps?: T) => void;
  closeDrawer: () => void;
  DrawerEl: ReactNode;
};

const drawerMap: Map<DrawerType, FC> = new Map();
drawerMap.set('context-provider', ContextProviderDrawer);
drawerMap.set('conversation', ConversationDrawer);
drawerMap.set('user-event', UserEventDrawer);
drawerMap.set('public-service', PublicServiceDrawer);
drawerMap.set('private-model', PrivateModelDrawer);
drawerMap.set('admin-user', AdminUserDrawer);
drawerMap.set('end-user', UserDrawer);
drawerMap.set('group', GroupDrawer);
drawerMap.set('connector', ConnectorDrawer);

interface HookProps {
  onClose?: () => void;
}

const useDrawer = <T,>(drawerType: DrawerType, props?: T & HookProps): DrawerHook => {
  const DrawerComponent = drawerMap.get(drawerType) || Fragment;
  type DrawerProps = ComponentProps<typeof DrawerComponent>;

  const handleClose = (onClose?: () => void) => {
    setOpen(false);
    setDrawerProps({
      ...(props || {}),
      onClose: () => {
        handleClose(props?.onClose);
      },
    });
    onClose && onClose();
  };

  const [drawerProps, setDrawerProps] = useState<DrawerProps>({
    ...(props || {}),
    onClose: () => {
      handleClose(props?.onClose);
    },
  });

  /*
   * The reason we have separate state values for both open and mounted is because, for render optimization, and handling
   * changing drawer props, we want to mount and unmount the drawer when it opens and closes. But we also need to give the
   * nice open and close animations time to run. So on open, we first mount, wait for the drawer to render as closed,  and
   * then open. And on close, we first close, wait for the close animation to run, and then unmount.
   */

  const [open, setOpen] = useState(false);
  const priorOpen = usePrior(open);

  const [mounted, setMounted] = useState(false);
  const priorMounted = usePrior(mounted);

  useHotkeys(
    'esc',
    () => {
      if (!open) {
        return;
      }
      handleClose();
    },
    [handleClose, open]
  );

  // if the drawer becomes mounted, open it
  useEffect(() => {
    if (mounted && !priorMounted) {
      setOpen(true);
    }
  }, [mounted, priorMounted]);

  // if the drawer is closed, unmount it
  useEffect(() => {
    if (!open && priorOpen) {
      setTimeout(() => {
        setMounted(false);
      }, DRAWER_ANIMATE_DURATION + 200);
    }
  }, [open, priorOpen]);

  const compProps = {
    ...drawerProps,
    open,
  };

  return {
    openDrawer: <T,>(addlProps?: T & HookProps) => {
      setDrawerProps((old) => ({
        ...old,
        ...(addlProps || {}),
        onClose: () => {
          const onClose = addlProps?.onClose || (old as HookProps).onClose;
          handleClose(onClose);
        },
      }));
      setMounted(true);
    },
    closeDrawer: () => {
      handleClose();
    },
    DrawerEl: mounted ? <DrawerComponent {...compProps} /> : null,
  };
};

export default useDrawer;
