import useToast from '@/hooks/use-toast.hook';
import { OrgModel } from '@/lib/models/org.model';
import { patchOrg } from '@/lib/services/org.service';
import { Box, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import ImageUploader from '@/components/image-uploader';
import ImageUploadPreviewCard from '@/components/image-uploader/preview-card';
import { ImageExtension } from '@/components/image-uploader/helpers';

export type FileWithPreview = FileWithPath & { preview: string };

interface Props {
  organization: OrgModel;
  readonly: boolean;
  onUpload?: () => void;
}

const MAX_FILE_SIZE_KB = 300;
const IMAGE_HEIGHT_PX = 40;
const IMAGE_WIDTH_PX = 40;
const ALLOWED_IMAGE_TYPES: ImageExtension[] = ['png', 'jpg'];

const PortalBadgeForm: FC<Props> = ({ organization, readonly, onUpload }) => {
  const { portalBadge = { image: '', title: 'Assistant' } } = organization.branding;

  const { toast, errorToast } = useToast();
  const [image, setImage] = useState(portalBadge.image);

  const handleUploadImage = (dataURL: string) => {
    const updatedOrg = new OrgModel(organization._props);
    updatedOrg.branding.portalBadge.image = dataURL;

    patchOrg(updatedOrg).then(({ patched, error }) => {
      if (patched) {
        onUpload?.();
        setImage(dataURL);
        toast('The badge image was updated');
        return;
      }

      errorToast(error || 'The badge image was not updated');
    });
  };

  const handleRemoveImage = () => {
    const updatedOrg = new OrgModel(organization._props);
    updatedOrg.branding.portalBadge.image = '';

    patchOrg(updatedOrg).then(({ patched, error }) => {
      if (patched) {
        setImage('');
        toast('The badge image was removed');
        return;
      }

      errorToast(error || 'The badge image was not removed');
    });
  };

  return (
    <>
      <Box mb={4}>
        The Portal Assistant Badge appears next to assistant responses in the Portal, along with the
        assistant's title.
      </Box>
      <Stack direction="row" gap={6} width="100%">
        {!readonly && (
          <Box width="50%">
            <ImageUploader
              title="Upload New Badge"
              onUpload={handleUploadImage}
              maxFileSizeKb={MAX_FILE_SIZE_KB}
            />
          </Box>
        )}
        <Box width="50%">
          <ImageUploadPreviewCard
            imageHeight={IMAGE_HEIGHT_PX}
            imageWidth={IMAGE_WIDTH_PX}
            maxFileSize={MAX_FILE_SIZE_KB}
            allowedImageTypes={ALLOWED_IMAGE_TYPES}
            title="Current Badge"
            onRemove={handleRemoveImage}
            readonly={readonly}
            image={image}
          />
        </Box>
      </Stack>
    </>
  );
};

export default PortalBadgeForm;
